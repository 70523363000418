import { ENVIRONMENTS } from "../constants";

type Environments = "development" | "production" | "test";

class GlobalConfig {
  private static instance: GlobalConfig;

  private env: Environments | undefined;

  constructor() {
    if (!GlobalConfig.instance) {
      GlobalConfig.instance = this;
    }
  }

  public get clientId(): string {
    if (this.env) {
      return ENVIRONMENTS[this.env].CLIENT_ID;
    }
    return "";
  }

  public set environment(env: Environments | undefined) {
    this.env = env;
  }

  /**
   * Return the environment which the system has been setup to run in.
   * @returns the environment value identifying the system setup.
   */
  public get environment(): Environments | undefined {
    return this.env;
  }
}

const instance = new GlobalConfig();
export default instance;
