import ActionBar from "../components/home/ActionBar";
import Badge from "@material-ui/core/Badge";
import CalendarService from "../services/CalendarService";
import Container from "@material-ui/core/Container";
import DataBox from "../components/DataBox";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import NextService from "../components/home/NextService";
import OwnerMessage from "../components/home/OwnerMessage";
import PackageMesssage from "../components/home/PackageMessage";
import QuickActions from "../components/QuickActions";
import React, { useEffect, useState } from "react";
import ReferFriendDialog from "../components/home/ReferFriendDialog";
import ServiceDetails from "../components/home/ServiceDetails";
import { BFFThunkDispatch } from "../store/actions/action.types";
import { Boarding } from "../model/Boarding";
import { CalendarItem } from "../model/Calendar";
import { connect, useDispatch } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";
import { EMPTY_PET } from "../model/Pet";
import { EMPTY_PROFILE, Profile } from "../model/Profile";
import { getCalendarItems } from "../selectors";
import { loadCalendar } from "../store/actions/calendar.actions";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Pet } from "../model/Pet";
import { ReferFriendBox } from "../components/home/ReferFriendBox";
import { RootState } from "../store/reducers";
import { Route } from "react-router";
import { selectDate } from "../store/actions/app.actions";
import { useUtils } from "@material-ui/pickers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
    },
    daydot: {
      display: "block",
      width: "50%",
      top: "2px",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      marginBottom: 96,
    },
  })
);

type Props = InjectedStateProps & InjectedDispatchProps;

const OwnerHome: React.FunctionComponent<Props> = ({
  loadCalendar,
  userid,
  calendarItems,
}) => {
  const classes = useStyles();
  const utils = useUtils();
  const dispatch = useDispatch();

  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    loadCalendar(userid, new Date());
  }, [userid, loadCalendar]);

  const onDateChange = (date: MaterialUiPickersDate) => {
    if (date instanceof moment) {
      const nativeDate = date.toDate();
      setSelectedDate(nativeDate);
      dispatch(selectDate(nativeDate));
    }
  };

  const onMonthChange = (date: MaterialUiPickersDate) => {
    if (date instanceof moment) {
      const nativeDate = date.toDate();
      loadCalendar(userid, nativeDate);
      setSelectedDate(nativeDate);
      dispatch(selectDate(nativeDate));
    }
  };

  const renderDay = (
    day,
    selectedDate,
    dayInCurrentMonth: boolean,
    dayComponent: JSX.Element
  ) => {
    if (CalendarService.hasCalendarItem(day, calendarItems)) {
      const requestedItems = CalendarService.getMatchingCalendarItems(
        day,
        calendarItems
      ).filter((item) => {
        if ("requestid" in item) {
          return true;
        }
        if ("boardingid" in item) {
          const boardingitem = item as Boarding;
          return boardingitem.status === "Pending Deposit";
        }
        return false;
      });

      let badgeColor: "primary" | "secondary" = "secondary";
      if (requestedItems.length > 0) {
        badgeColor = "primary";
      }

      // add indicator
      return React.cloneElement(
        dayComponent,
        undefined,
        utils.getDayText(day),
        <Badge color={badgeColor} variant="dot" className={classes.daydot}>
          {" "}
        </Badge>
      );
    } else {
      return dayComponent;
    }
  };

  return (
    <main className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ActionBar />
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <OwnerMessage />
          </Grid>
          <Grid item xs={12}>
            <ReferFriendBox />
          </Grid>
          <Grid item xs={12} md={6}>
            <NextService />
          </Grid>
          <Grid item xs={12} md={6}>
            <PackageMesssage />
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            <DataBox>
              <DatePicker
                value={selectedDate}
                onChange={onDateChange}
                onMonthChange={onMonthChange}
                renderDay={renderDay}
                variant="static"
              />
            </DataBox>
          </Grid>
          <Grid item xs={12} md={7} lg={8}>
            <ServiceDetails date={selectedDate} />
          </Grid>
        </Grid>
      </Container>
      <Route path="/" exact={true} render={() => <QuickActions />} />
      <Route path="/refer" exact={true} render={() => <ReferFriendDialog />} />
    </main>
  );
};

export interface InjectedStateProps {
  userid: number;
  profile: Profile;
  pet: Pet;
  calendarItems: ReadonlyArray<CalendarItem>;
}

const mapStateToProps = (state: RootState): InjectedStateProps => {
  const { app, auth, entities } = state;
  const defaultPet = app.defaultPet || EMPTY_PET;
  return {
    userid: auth.userid,
    profile:
      (entities.profile ? entities.profile[auth.userid] : undefined) ||
      EMPTY_PROFILE,
    pet: defaultPet,
    calendarItems: getCalendarItems(state),
  };
};

export interface InjectedDispatchProps {
  loadCalendar: (userid: number, date: Date) => void;
}

const mapDispatchToProps = (
  dispatch: BFFThunkDispatch
): InjectedDispatchProps => {
  return {
    loadCalendar: (userid: number, date: Date) => {
      dispatch(loadCalendar(userid, date));
    },
  };
};

export default connect<
  InjectedStateProps,
  InjectedDispatchProps,
  {},
  RootState
>(
  mapStateToProps,
  mapDispatchToProps
)(OwnerHome);
