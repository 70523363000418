import CalendarService from "../../services/CalendarService";
import moment from "moment";
import { BFFThunkAction, BFFThunkDispatch } from "./action.types";
import { BoardingPayload } from "../../model/Boarding";
import { createAction } from "redux-actions";
import { DaycarePayload } from "../../model/Daycare";
import { PetExec } from "../../constants";
import { ScheduledServicesPayload } from "../../model/ScheduledService";
import {
  BoardingActions,
  DaycareActions,
  ServicesActions,
  AppActions,
} from "./action.constants";

function loadCalendarThunk(userid: number, date: Date): BFFThunkAction<void> {
  return async (dispatch: BFFThunkDispatch) => {
    const startDate = moment(date)
      .startOf("month")
      .format(PetExec.API_DATE_FORMAT);
    const endDate = moment(date).endOf("month").format(PetExec.API_DATE_FORMAT);

    // load the calendars
    dispatch(calendarLoading(true));
    if (userid) {
      await dispatch(loadDaycares(userid, startDate, endDate));
      await dispatch(loadServices(userid, startDate, endDate));
      await dispatch(loadBoardings(userid, startDate, endDate));
    }
    dispatch(calendarLoading(false));
  };
}

export const loadCalendar = loadCalendarThunk;

export const calendarLoading = createAction<boolean>(
  AppActions.CALENDAR_LOADING
);

function loadDaycareAction(
  userid: number,
  start: string,
  end: string
): Promise<DaycarePayload> {
  const service = new CalendarService();
  return service.loadDaycares(userid, start, end);
}

const loadDaycares = createAction<
  Promise<DaycarePayload>,
  number,
  string,
  string
>(DaycareActions.LOAD_CALENDAR, loadDaycareAction);

function loadServicesAction(
  userid: number,
  start: string,
  end: string
): Promise<ScheduledServicesPayload> {
  const service = new CalendarService();
  return service.loadScheduledServices(userid, start, end);
}

const loadServices = createAction<
  Promise<ScheduledServicesPayload>,
  number,
  string,
  string
>(ServicesActions.LOAD_CALENDAR, loadServicesAction);

function loadBoardingsAction(
  userid: number,
  start: string,
  end: string
): Promise<BoardingPayload> {
  const service = new CalendarService();
  return service.loadBoardings(userid, start, end);
}

const loadBoardings = createAction<
  Promise<BoardingPayload>,
  number,
  string,
  string
>(BoardingActions.LOAD_CALENDAR, loadBoardingsAction);
