import PetService from "../../services/PetService";
import { Action } from "redux-actions";
import { ActionType } from "redux-promise-middleware";
import { AnyAction, combineReducers, Reducer } from "redux";
import { EMPTY_PET, Pet, PetPayload } from "../../model/Pet";
import { OptionsObject } from "notistack";
import {
  AppActions,
  NotificationActions,
  PetActions,
  ServicesActions,
} from "../actions/action.constants";

export interface AppState {
  readonly title: string;
  readonly navDrawerOpen: boolean;
  readonly defaultPet: Pet;
  readonly notifications: NotificationMap;
  readonly selectedDate: string;
  readonly servicesLoading: boolean;
  readonly petsLoading: boolean;
  readonly calendarLoading: boolean;
}

export interface Notification {
  key: string;
  message?: string;
  options?: OptionsObject;
}

export interface NotificationMap {
  [key: string]: Notification;
}

const titleReducer: Reducer<string> = (state: string = "") => {
  return state;
};

const selectedDateReducer: Reducer<string, Action<Date>> = (
  state: string = "",
  action
): string => {
  switch (action.type) {
    case AppActions.SELECTED_DATE:
      return action.payload.toJSON();
    default:
      return state;
  }
};

const navbarReducer: Reducer<boolean, Action<void>> = (
  state = false,
  action
) => {
  switch (action.type) {
    case AppActions.OPEN_NAV:
      return true;
    case AppActions.CLOSE_NAV:
      return false;
    default:
      return state;
  }
};

const petReducer: Reducer<Pet, Action<PetPayload>> = (
  state = EMPTY_PET,
  action
): Pet => {
  switch (action.type) {
    case `${PetActions.LOAD_PETS}_${ActionType.Pending}`: {
      return EMPTY_PET;
    }
    case `${PetActions.LOAD_PETS}_${ActionType.Fulfilled}`: {
      const userPets = action.payload.pets;
      const defaultPet: Pet = PetService.getRandomPet(userPets) || EMPTY_PET;
      return defaultPet;
    }
    default: {
      return state;
    }
  }
};

const petLoadingReducer: Reducer<boolean, Action<PetPayload>> = (
  state = true,
  action
): boolean => {
  switch (action.type) {
    case `${PetActions.LOAD_PETS}_${ActionType.Pending}`: {
      return true;
    }
    case `${PetActions.LOAD_PETS}_${ActionType.Fulfilled}`: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const calendarLoadingReducer: Reducer<boolean, Action<boolean>> = (
  state = true,
  action
): boolean => {
  switch (action.type) {
    case AppActions.CALENDAR_LOADING: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const notificationReducer: Reducer<NotificationMap, Action<Notification>> = (
  state = {},
  action
): NotificationMap => {
  switch (action.type) {
    case NotificationActions.PUSH: {
      return { ...state, ...{ [action.payload.key]: action.payload } };
    }
    case NotificationActions.REMOVE:
      if (action.payload && state[action.payload.key]) {
        const newState = { ...state };
        delete newState[action.payload.key];
        return newState;
      }
      return state;
    default: {
      return state;
    }
  }
};

const servicesLoadingReducer: Reducer<boolean, AnyAction> = (
  state = true,
  action
): boolean => {
  switch (action.type) {
    case `${ServicesActions.LOAD_SERVICES}_${ActionType.Pending}`: {
      return true;
    }
    case `${ServicesActions.LOAD_SERVICES}_${ActionType.Fulfilled}`: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const appReducer = combineReducers<AppState>({
  title: titleReducer,
  navDrawerOpen: navbarReducer,
  defaultPet: petReducer,
  notifications: notificationReducer,
  selectedDate: selectedDateReducer,
  servicesLoading: servicesLoadingReducer,
  petsLoading: petLoadingReducer,
  calendarLoading: calendarLoadingReducer,
});

export { appReducer };
