import moment, { Moment } from "moment";
import { PetExec } from "../constants";
import { TokenReplacer } from "../utils/TokenReplacer";
import {
  Pet,
  PetBreed,
  PetCreation,
  PetPayload,
  PetType,
  Vet,
  Shot,
} from "../model/Pet";
import AbstractPetService, {
  CreationResponse,
  PetExecResponse,
} from "./AbstractPetService";

interface PetsResponse extends PetExecResponse {
  pets: Pet[];
}

interface PetTypesResponse extends PetExecResponse {
  pettypes: PetType[];
}

interface PetBreedsResponse extends PetExecResponse {
  breeds: PetBreed[];
}

interface VetsResponse extends PetExecResponse {
  vets: Vet[];
}

interface ShotsResponse extends PetExecResponse {
  shots: Shot[];
}

export interface PetCreationResponse extends CreationResponse {
  petid: number;
}

class PetService extends AbstractPetService {
  private static PET_PATH = "/pet";

  public static getRandomPet(pets: Pet[]): Pet | undefined {
    if (!pets) {
      return undefined;
    }
    return pets[Math.floor(Math.random() * pets.length)];
  }

  public static getBirthdate(pet: Pet): Moment {
    return moment(pet.birthdate, PetExec.API_DATE_FORMAT);
  }

  public async loadPets(userid: number): Promise<PetPayload> {
    const endpoint = `${PetExec.API_URL}${PetService.PET_PATH}/owner/{userid}`;
    const url = TokenReplacer.replace(endpoint, {
      userid,
    });
    const response = await this.fetchJson<PetsResponse>(url);
    // add cache killer to each pet pic
    const allPets = response.pets.map((pet) => {
      if (pet.pathtopic) {
        const pathtopic = `${pet.pathtopic}?_=${Date.now()}`;
        return { ...pet, pathtopic };
      } else {
        return pet;
      }
    });
    return { userid, pets: allPets || [] } as PetPayload;
  }

  public async loadPetTypes(): Promise<PetType[]> {
    const endpoint = `${PetExec.API_URL}/pet-type`;
    const response = await this.fetchJson<PetTypesResponse>(endpoint);
    return response.pettypes;
  }

  public async loadPetBreeds(ptid: number): Promise<PetBreed[]> {
    const endpoint = `${PetExec.API_URL}/breed/pet-type/{ptid}`;
    const url = TokenReplacer.replace(endpoint, {
      ptid,
    });
    const response = await this.fetchJson<PetBreedsResponse>(url);
    return response.breeds;
  }

  public async loadVets(): Promise<Vet[]> {
    const endpoint = `${PetExec.API_URL}/vet`;
    const response = await this.fetchJson<VetsResponse>(endpoint);
    return response.vets;
  }

  public async loadShots(ptid: number): Promise<Shot[]> {
    const endpoint = `${PetExec.API_URL}/shot/pet-type/{ptid}`;
    const url = TokenReplacer.replace(endpoint, {
      ptid,
    });
    const response = await this.fetchJson<ShotsResponse>(url);
    return response.shots;
  }

  public async uploadPic(pet: Pet, data: string): Promise<CreationResponse> {
    const endpoint = `${PetExec.API_URL}${PetService.PET_PATH}/{petid}/upload`;
    const url = TokenReplacer.replace(endpoint, {
      petid: pet.petid,
    });

    const uploadData = new FormData();

    uploadData.append("base64", data);

    return await this.fetchJson<CreationResponse>(url, {
      method: "POST",
      body: uploadData,
    });
  }

  public async addPet(
    userid: number,
    pet: PetCreation
  ): Promise<PetCreationResponse> {
    const endpoint = `${PetExec.API_URL}${PetService.PET_PATH}/owner/{userid}`;
    const url = TokenReplacer.replace(endpoint, {
      userid,
    });

    const petData = new FormData();

    petData.append("petName", pet.petName);

    if (pet.birthDate && typeof pet.birthDate !== "string") {
      petData.append(
        "birthDate",
        pet.birthDate.format(PetExec.API_DATE_FORMAT)
      );
    }

    petData.append("ptid", pet.ptid.toString());
    petData.append("breedid", pet.breedid.toString());
    petData.append("vetid", pet.vetid.toString());

    petData.append("gender", pet.gender);
    petData.append("neuteredState", pet.neuteredState);
    petData.append("authorizedToPickup", pet.authorizedToPickup);

    Object.entries(pet.shots).forEach(([shotid, date]) => {
      petData.append(`shot_${shotid}`, date.format(PetExec.API_DATE_FORMAT));
    });

    return await this.fetchJson<PetCreationResponse>(url, {
      method: "POST",
      body: petData,
    });
  }
}

export default PetService;
