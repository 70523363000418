export function reportError(errors: any | any[]): void {
  if (!Array.isArray(errors)) {
    errors = [errors];
  }
  errors
    .forEach((e) => {
      if (e instanceof Error) {
        console.error(e.message);
      }
    });
}
