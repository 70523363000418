import AddCircleIcon from "@material-ui/icons/AddCircle";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import ReactGA from "react-ga";
import SaveIcon from "@material-ui/icons/Save";
import { CircularProgress, LinearProgress } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import { Profile } from "../../model/Profile";
import { ProfileFormFields } from "./ProfileFormFields";
import { saveProfile } from "../../store/actions/profile.actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      padding: theme.spacing(3, 0),
    },
    logoutButton: {
      margin: theme.spacing(2, 1),
    },
  })
);

interface Props {
  profile: Profile;
}

export const ProfileForm: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { profile } = props;

  const [editMode, setEditMode] = useState<boolean>(false);
  const [allowEdit] = useState(true);

  const handleCancel = (resetForm) => {
    resetForm();
    setEditMode(false);
  };

  const handleAddPet = () => {
    history.push("/add/pet");
  };

  if (!profile || profile.userid === 0) {
    return (
      <div className="bff-loading">
        <CircularProgress />
      </div>
    );
  }

  return (
    <Box>
      <Formik<Profile>
        enableReinitialize={true}
        initialValues={{ ...profile }}
        onSubmit={(values: Profile, { setSubmitting }) => {
          setEditMode(false);
          ReactGA.event({
            category: "Profile",
            action: "Save Profile",
          });
          dispatch(saveProfile(values));
          setTimeout(() => {
            setSubmitting(false);
          }, 500);
        }}
      >
        {(formProps) => {
          const { submitForm, isSubmitting, isValid, resetForm } = formProps;

          return (
            <Form>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                className={classes.grid}
                spacing={3}
              >
                <Grid item>
                  {editMode && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                        endIcon={<SaveIcon />}
                      >
                        Save
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => handleCancel(resetForm)}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                  {allowEdit && !editMode && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setEditMode(true)}
                      endIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    endIcon={<AddCircleIcon />}
                    onClick={() => handleAddPet()}
                  >
                    Add Pet
                  </Button>
                </Grid>
                {isSubmitting && (
                  <Grid item xs={12}>
                    <LinearProgress />
                  </Grid>
                )}
              </Grid>
              {!isValid && (
                <span>Please fix errors in the form before submitting</span>
              )}
              <ProfileFormFields editMode={editMode} />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
