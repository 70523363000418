import CalendarService, { DATE_FORMAT } from "../services/CalendarService";
import moment from "moment";
import { isScheduledWalk } from "./CalendarUtils";
import { ServiceItem } from "../model/Calendar";

export const isServiceScheduled = (item: ServiceItem) => {
  if (CalendarService.isServicesItem(item.schedule)) {
    return item.schedule.status === "Scheduled";
  }
  return false;
};

export const getStatusText = (item: ServiceItem) => {
  let status: string = "";
  if (CalendarService.isServicesItem(item.schedule)) {
    status = item.schedule.status;
  }
  return status.trim() || "";
};

export const getLocationText = (item: ServiceItem) => {
  let location: string = "";
  if (CalendarService.isDaycareItem(item.schedule)) {
    if (item.schedule.playarea !== "Unassigned") {
      location = item.schedule.playarea;
    } else {
      location = item.schedule.pickuplocation;
    }
  }
  if (CalendarService.isServicesItem(item.schedule)) {
    location = item.schedule.pickuplocation;
  }
  if (CalendarService.isBoardingItem(item.schedule)) {
    if (item.schedule.services) {
      location = item.schedule.services[0].servicename;
    }
  }
  location = location || "";
  location = location.trim();
  location = location.split(":")[0];
  return location;
};

export const getPackageText = (item: ServiceItem) => {
  let packageName: string = "";
  if (item.schedule.services && item.schedule.services.length > 0) {
    packageName = item.schedule.services[0].servicename;
  }
  return packageName.trim() || "";
};

export const getAppointmentText = (item: ServiceItem) => {
  let time: string = "";
  if (
    CalendarService.isServicesItem(item.schedule) &&
    isScheduledWalk(item.schedule)
  ) {
    if (item.schedule.start) {
      const startHour = moment(item.schedule.start, DATE_FORMAT).hour();
      if (startHour < 13) {
        time = "Morning";
      } else if (startHour >= 13 && startHour < 17) {
        time = "Afternoon";
      } else if (startHour >= 17) {
        time = "Evening";
      }
    }
  }
  return time;
};

export const isShuttleRequired = (item: ServiceItem) => {
  return item.schedule.dropofflocation.startsWith("Owner's Residence");
};
