export const validateEmail = (value: string): string | undefined => {
  let error;
  if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Invalid email address";
  }
  return error;
};

export const validatePhone = (value: string): string | undefined => {
  let error;
  if (value && !/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/i.test(value)) {
    error = "Invalid phone number";
  }
  return error;
};

export const validateRequired = (value: string): string | undefined => {
  let error;
  if (!value) {
    error = "Field is required";
  }
  return error;
};

export const validateAll = (
  value: string,
  validationFuncs: Array<(string) => string | undefined> = []
): string | undefined => {
  let error;
  validationFuncs.some((func) => {
    error = func(value);
    return error !== undefined;
  });
  return error;
};
