import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { RootState } from "../../store/reducers";

interface PrivateRouteProps {
  isBeta?: boolean;
}
type Props = PrivateRouteProps & RouteProps & InjectedStateProps;

const PrivateRoute: React.FunctionComponent<Props> = ({
  isBeta = false,
  component: Component,
  auth,
  isBetaUser,
  ...rest
}) => {
  let canVisit = auth;
  if (isBeta) {
    canVisit = canVisit && isBetaUser;
  }

  const renderRoute = (props) => {
    if (canVisit && Component) {
      return <Component {...props} />;
    } else {
      if (auth) {
        return (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        );
      } else {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }
    }
  };
  return <Route {...rest} render={renderRoute} />;
};

interface InjectedStateProps {
  auth: boolean;
  isBetaUser: boolean;
}
const mapStateToProps = ({ auth }: RootState): InjectedStateProps => ({
  auth: auth.authenticated,
  isBetaUser: auth.isBetaUser
});

export default connect<InjectedStateProps, null, RouteProps, RootState>(
  mapStateToProps
)(PrivateRoute);
