import reduceReducers from "reduce-reducers";
import { Action } from "redux-actions";
import { ActionType } from "redux-promise-middleware";
import { Daycare, DaycarePayload } from "../../model/Daycare";
import { DaycareActions } from "../actions/action.constants";
import { EntityMap } from "./entities.reducer";
import { mapKeys } from "lodash";
import { Reducer } from "react";

export type DaycareState = EntityMap<Daycare> | undefined;

const initialState: DaycareState = {};

const handleLoadAction: Reducer<DaycareState, Action<DaycarePayload>> = (
  state = initialState,
  action
): DaycareState => {
  switch (action.type) {
    case `${DaycareActions.LOAD_CALENDAR}_${ActionType.Fulfilled}`: {
      return {
        ...state,
        ...mapKeys(action.payload.daycares, "daycareid")
      };
    }
    default: {
      return state;
    }
  }
};

const handleLoadSingleAction: Reducer<DaycareState, Action<Daycare>> = (
  state = initialState,
  action
): DaycareState => {
  switch (action.type) {
    case `${DaycareActions.LOAD}_${ActionType.Fulfilled}`: {
      const daycare = action.payload;
      if (daycare) {
        return {
          ...state,
          ...{ [daycare.daycareid]: daycare }
        };
      } else {
        return state;
      }
    }
    default: {
      return state;
    }
  }
};

const handleDeleteAction: Reducer<DaycareState, Action<Daycare>> = (
  state = initialState,
  action
): DaycareState => {
  switch (action.type) {
    case DaycareActions.DELETE: {
      const daycare = action.payload;
      if (daycare) {
        const newState = { ...state };
        if (newState[daycare.daycareid]) {
          delete newState[daycare.daycareid];
        }
        return newState;
      } else {
        return state;
      }
    }
    default: {
      return state;
    }
  }
};

export const daycareReducer = reduceReducers(
  // @ts-ignore
  handleLoadAction,
  handleLoadSingleAction,
  handleDeleteAction
);
