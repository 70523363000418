import { Action } from "redux-actions";
import { ActionType } from "redux-promise-middleware";
import { AuthActions, ProfileActions } from "../actions/action.constants";
import { BETA_USER_IDS } from "../../constants";
import { combineReducers, Reducer } from "redux";
import { Profile } from "../../model/Profile";

export interface AuthState {
  authenticated: boolean;
  userid: number;
  isBetaUser: boolean;
}

const userReducer: Reducer<number, Action<Profile>> = (
  state = 0,
  action
): number => {
  switch (action.type) {
    case `${ProfileActions.LOAD}_${ActionType.Fulfilled}`:
      return action.payload.userid;
    case AuthActions.LOGOUT:
      return 0;
    default:
      return state;
  }
};

const betaReducer: Reducer<boolean, Action<Profile>> = (
  state = false,
  action
): boolean => {
  switch (action.type) {
    case `${ProfileActions.LOAD}_${ActionType.Fulfilled}`:
      const userid = action.payload.userid;
      return BETA_USER_IDS.includes(userid);
    case AuthActions.LOGOUT:
      return false;
    default:
      return state;
  }
};

const authenticatedReducer: Reducer<boolean, Action<void>> = (
  state = false,
  action
): boolean => {
  switch (action.type) {
    case AuthActions.LOGOUT:
      return false;
    default:
      return state;
  }
};

const authReducer = combineReducers<AuthState>({
  authenticated: authenticatedReducer,
  userid: userReducer,
  isBetaUser: betaReducer
});

export { authReducer };
