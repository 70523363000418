/*
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import isPromise from "is-promise";
import { ActionMeta } from "redux-actions";
import { PromiseActionMeta } from "../store/actions/action.types";
import { reportError } from "./ReportError";

export default function errorMiddleware() {
  return (next) => (action: ActionMeta<any, PromiseActionMeta>) => {
    // If not a promise, continue on
    if (!isPromise(action.payload)) {
      return next(action);
    }

    // Dispatch initial pending promise, but catch any errors
    return next(action).catch((error: Error) => {
      if (action.meta && action.meta.showErrorMessage) {
        reportError(error);
      }
      return error;
    });
  };
}
