import { CompanyInstructionsResponse } from "./CompanyService";
import { Daycare, DaycareCreation } from "../model/Daycare";
import { PetExec } from "../constants";
import { ServiceType } from "../model/ScheduledService";
import { TokenReplacer } from "../utils/TokenReplacer";
import AbstractPetService, {
  CreationResponse,
  PetExecResponse,
} from "./AbstractPetService";

interface DaycareResponse extends PetExecResponse {
  daycare: Daycare;
}

interface DaycareServicesResponse extends PetExecResponse {
  daycareservices: ServiceType[];
  lunchservices: ServiceType[];
}

class DaycareService extends AbstractPetService {
  private static DAYCARE_PATH = "/daycare";

  public async loadDaycare(daycareid: number): Promise<Daycare> {
    const endpoint = `${PetExec.API_URL}${DaycareService.DAYCARE_PATH}/{daycareid}`;
    const url = TokenReplacer.replace(endpoint, {
      daycareid,
    });
    const response = await this.fetchJson<DaycareResponse>(url);
    return response.daycare;
  }

  public async loadDaycareServices(): Promise<ServiceType[]> {
    const endpoint = `${PetExec.API_URL}${DaycareService.DAYCARE_PATH}/services`;
    const response = await this.fetchJson<DaycareServicesResponse>(endpoint);
    return response.daycareservices;
  }

  public async loadInstructions(): Promise<string> {
    const endpoint = `${PetExec.API_URL}${DaycareService.DAYCARE_PATH}/company/instructions`;
    const response = await this.fetchJson<CompanyInstructionsResponse>(
      endpoint
    );
    return response.instructions;
  }

  public async addDaycare(daycare: DaycareCreation): Promise<CreationResponse> {
    const url = `${PetExec.API_URL}${DaycareService.DAYCARE_PATH}`;
    const daycareData = new FormData();
    if (daycare.startDate && typeof daycare.startDate !== "string") {
      daycareData.append(
        "startDate",
        daycare.startDate.format(PetExec.API_DATE_FORMAT)
      );
    }
    if (daycare.endDate && typeof daycare.endDate !== "string") {
      daycareData.append(
        "endDate",
        daycare.endDate.format(PetExec.API_DATE_FORMAT)
      );
    }
    daycareData.append("petids", daycare.petids.join(","));
    daycareData.append("dropOffTime", daycare.dropOffTime);
    daycareData.append("pickUpTime", daycare.pickUpTime);
    daycareData.append("daysOfWeek", daycare.daysOfWeek.join(","));
    daycareData.append("dropOffLocation", daycare.dropOffLocation.toString());
    daycareData.append("pickUpLocation", daycare.pickUpLocation.toString());
    daycare.petids.forEach((id) => {
      daycareData.append(`daycare_${id}`, daycare.serviceid.toString());
      daycareData.append(`note_${id}`, daycare.notes);
    });

    return await this.fetchJson<CreationResponse>(url, {
      method: "POST",
      body: daycareData,
    });
  }

  public async deleteDaycare(daycare: Daycare): Promise<CreationResponse> {
    const endpoint = `${PetExec.API_URL}${DaycareService.DAYCARE_PATH}/{daycareid}`;
    const url = TokenReplacer.replace(endpoint, {
      daycareid: daycare.daycareid,
    });

    return await this.fetchJson<CreationResponse>(url, {
      method: "DELETE",
    });
  }
}

export default DaycareService;
