import ProfileService from "../../services/ProfileService";
import { BFFThunkAction, BFFThunkDispatch } from "./action.types";
import { createAction } from "redux-actions";
import { loadDaycareServices } from "./daycare.actions";
import { loadEmployees, loadLocations, loadSignedAgreements } from "./company.actions";
import { loadPackages } from "./package.actions";
import { loadPets } from "./pet.actions";
import { Profile } from "../../model/Profile";
import { ProfileActions } from "./action.constants";
import { showNotification } from "./app.actions";

const NOTIFICATION_KEY = "profileModification";

function profileThunk(): BFFThunkAction<void> {
  return async (dispatch: BFFThunkDispatch, getState) => {
    await dispatch(loadProfile());
    const currentUser = getState().auth.userid;
    if (currentUser) {
      dispatch(loadPets(currentUser));
      await dispatch(loadEmployees());
      const allEmployees = getState().entities.employees;
      if (allEmployees && allEmployees[currentUser]) {
        // current user is an employee!
        dispatch(makeEmployee(currentUser));
      }
      dispatch(loadPackages(currentUser));
      dispatch(loadDaycareServices());
      dispatch(loadLocations());
      dispatch(loadSignedAgreements());
    }
  };
}

export const loadFullProfile = profileThunk;

function loadProfileAction(): Promise<Profile> {
  const service = new ProfileService();
  return service.loadProfile();
}

export const loadProfile = createAction<Promise<Profile>>(
  ProfileActions.LOAD,
  loadProfileAction
);

export const makeEmployee = createAction<number>(ProfileActions.MAKE_EMPLOYEE);

function saveProfileThunk(profile: Profile): BFFThunkAction<void> {
  return async (dispatch: BFFThunkDispatch, getState) => {
    const service = new ProfileService();
    const response = await service.modifyOwner(profile);

    if (response.success && response.message) {
      dispatch(
        showNotification({
          key: NOTIFICATION_KEY,
          message: response.message
        })
      );
    }

    if (!response.success && response.errors && response.errors.length > 0) {
      dispatch(
        showNotification({
          key: NOTIFICATION_KEY,
          message: response.errors[0],
          options: { variant: "error" }
        })
      );
    }
  };
}

export const saveProfile = saveProfileThunk;
