import SchedulingService from "../../services/SchedulingService";
import { ActionType } from "redux-promise-middleware";
import { BFFThunkAction, BFFThunkDispatch } from "./action.types";
import { CalendarServiceType } from "../../model/Calendar";
import { createAction } from "redux-actions";
import {
  ScheduledService,
  ScheduledServiceCreation,
  ScheduledServiceType,
  ServiceTypePayload
  } from "../../model/ScheduledService";
import { ServicesActions } from "./action.constants";
import { SERVICETYPEID_SHUTTLE } from "../../constants";
import { showNotification } from "./app.actions";

const NOTIFICATION_KEY = "walkCreation";

const addServiceTypes = createAction<ScheduledServiceType[]>(
  ServicesActions.ADD_SERVICE_TYPES
);

const addScheduledServices = createAction<ServiceTypePayload>(
  ServicesActions.ADD_SERVICES
);

const servicesPending = createAction(
  `${ServicesActions.LOAD_SERVICES}_${ActionType.Pending}`
);

const servicesFulfilled = createAction(
  `${ServicesActions.LOAD_SERVICES}_${ActionType.Fulfilled}`
);

function loadScheduledServiceAction(
  scheduledserviceid: number
): Promise<ScheduledService> {
  const service = new SchedulingService();
  return service.loadScheduledService(scheduledserviceid);
}

export const loadScheduledService = createAction<
  Promise<ScheduledService>,
  number
>(ServicesActions.LOAD, loadScheduledServiceAction);

function loadAllServicesThunk(): BFFThunkAction<void> {
  return async (dispatch: BFFThunkDispatch) => {
    dispatch(servicesPending());
    const service = new SchedulingService();
    const allServiceTypes = await service.loadServiceTypes();
    const filteredTypes = allServiceTypes.filter(
      (type) => type.servicetypeid !== SERVICETYPEID_SHUTTLE
    );

    const allServices = await Promise.all(
      filteredTypes.map((type) => service.loadServicesByType(type.stid))
    );

    allServices.forEach((service) => dispatch(addScheduledServices(service)));

    dispatch(addServiceTypes(filteredTypes));

    dispatch(servicesFulfilled());
  };
}

export const loadAllServices = loadAllServicesThunk;

function requestScheduledServiceThunk(
  formValues: Partial<ScheduledServiceCreation>,
  type: CalendarServiceType,
  usePet?: boolean
): BFFThunkAction<void> {
  return async (dispatch: BFFThunkDispatch, getState) => {
    const completedValues: ScheduledServiceCreation = {
      ...{
        appointmentTime: "morning",
        daysOfWeek: [],
        petids: [],
        serviceid: "0",
        serviceRef: {},
        notes: "",
        startDate: "",
        endDate: "",
        dropOffTime: "07:00 AM",
        pickUpTime: "07:00 PM"
      },
      ...formValues
    };

    const service = new SchedulingService();
    const response = await service.requestScheduledService(completedValues);

    const allPets = getState().entities.pets || {};
    const petNames = completedValues.petids
      .map((id) => {
        return allPets[id] ? allPets[id].petname : undefined;
      })
      .filter((name) => name !== undefined);

    if (response.success && response.message) {
      if (petNames.length > 0) {
        petNames.forEach((name) => {
          let message = `${type} successfully requested`;
          if (usePet) {
            message += ` for ${name}`;
          }
          dispatch(
            showNotification({
              key: NOTIFICATION_KEY,
              message
            })
          );
        });
      }
    }

    if (!response.success && response.errors && response.errors.length > 0) {
      let message = response.errors[0];
      if (!usePet) {
        message = message.replace(" for the selected pet", "");
      }
      dispatch(
        showNotification({
          key: NOTIFICATION_KEY,
          message,
          options: { variant: "error" }
        })
      );
    }
  };
}

export const requestScheduledService = requestScheduledServiceThunk;
