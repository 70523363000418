import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

export interface Column {
  key: string;
  label?: string;
  align?: "left" | "center" | "right";
}

interface Props {
  columns: Column[];
  data: any[];
  renderCell: (item: any, key: string) => React.ReactChild;
  headerClass?: string;
}

const TableView: React.FunctionComponent<Props> = ({
  columns,
  data,
  renderCell,
  headerClass = ""
}) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {columns.map((column, index) => {
            const alignment = column.align || "left";
            return (
              <TableCell
                key={`head${index}`}
                align={alignment}
                className={headerClass}
              >
                {column.label}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item, rowIndex) => (
          <TableRow key={`row${rowIndex}`}>
            {columns.map((column, cellIndex) => {
              const alignment = column.align || "left";
              return (
                <TableCell
                  key={`cell${cellIndex}`}
                  component="th"
                  scope="row"
                  align={alignment}
                >
                  {renderCell(item, column.key)}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TableView;
