import AbstractPetService, { PetExecResponse } from "./AbstractPetService";
import { bffDataFactory } from "./BFFDataService";
import { EntityMap } from "../store/reducers/entities.reducer";
import { PetExec } from "../constants";
import { values } from "lodash";
import {
  Agreement,
  AppMessage,
  Employee,
  Location,
  OwnerMessage,
} from "../model/Company";

interface AppImagesResponse extends PetExecResponse {
  images: any[];
}

interface LocationsResponse extends PetExecResponse {
  locations: Location[];
}

interface AgreementsResponse extends PetExecResponse {
  agreements: Agreement[];
}

interface EmployeeResponse extends PetExecResponse {
  employees: Employee[];
}

export interface CompanyInstructionsResponse extends PetExecResponse {
  instructions: string;
}

class CompanyService extends AbstractPetService {
  public static findEmployee(
    name: string,
    employees: ReadonlyArray<Employee>
  ): Employee | undefined {
    return employees.find((emp) => name === `${emp.firstname} ${emp.lastname}`);
  }

  public async loadOwnerAppMessage(): Promise<AppMessage> {
    const url = `${PetExec.API_URL}/company/owner-app-message`;
    const messageObj = await this.fetchJson<AppMessage>(url);
    return messageObj;
  }

  public async loadOwnerMessage(): Promise<OwnerMessage> {
    const url = `${PetExec.API_URL}/company/owner-message`;
    const messageObj = await this.fetchJson<OwnerMessage>(url);
    return messageObj;
  }

  public async loadAppImages(): Promise<any[]> {
    const url = `${PetExec.API_URL}/company/app-images`;
    const appImages = await this.fetchJson<AppImagesResponse>(url);
    return appImages.images;
  }

  public async loadLocations(): Promise<Location[]> {
    const url = `${PetExec.API_URL}/company/locations`;
    const resp = await this.fetchJson<LocationsResponse>(url);
    return resp.locations;
  }

  public async loadSignedAgreements(): Promise<Agreement[]> {
    const url = `${PetExec.API_URL}/company/agreements/signed`;
    const resp = await this.fetchJson<AgreementsResponse>(url);
    return resp.agreements;
  }

  public async loadEmployees(): Promise<Employee[]> {
    const url = `${PetExec.API_URL}/company/employees`;
    const employeeObj = await this.fetchJson<EmployeeResponse>(url);
    // load additional employee data
    const bffDataLoader = bffDataFactory("/data");
    const bffData = await bffDataLoader.fetchBFFData();
    const rawEmployees = [...employeeObj.employees, ...bffData.employees];
    const employeeMap: EntityMap<Employee> = rawEmployees.reduce(
      (map, employee) => {
        if (map[employee.employeeid]) {
          //merge
          map[employee.employeeid] = {
            ...map[employee.employeeid],
            ...employee,
          };
        } else {
          //new entry
          map[employee.employeeid] = employee;
        }
        return map;
      },
      {}
    );
    return values(employeeMap);
  }

  public async sendEmail(subject: string, message: string): Promise<void> {
    const url = `${PetExec.API_URL}/company/email`;
    const emailData = new FormData();
    emailData.append("subject", `[Dashboard Request] ${subject}`);
    emailData.append("content", message);
    await this.fetchJson(url, {
      method: "POST",
      body: emailData,
    });
  }
}

export default CompanyService;
