import React, { useEffect } from "react";
import { NotificationMap } from "../../store/reducers/app.reducer";
import { removeNotification } from "../../store/actions/app.actions";
import { RootState } from "../../store/reducers";
import { useDispatch, useSelector } from "react-redux";
import { withSnackbar, WithSnackbarProps } from "notistack";

let displayed: string[] = [];

const Notifier: React.FunctionComponent<WithSnackbarProps> = ({
  enqueueSnackbar
}) => {
  const dispatch = useDispatch();
  const notifications = useSelector<RootState, NotificationMap>(({ app }) => {
    return app.notifications;
  });

  const storeDisplayed = (key) => {
    displayed = [...displayed, key];
  };

  const removeDisplayed = (key) => {
    displayed = [...displayed.filter((displayedKey) => key !== displayedKey)];
  };

  useEffect(() => {
    const handleExit = (key: string) => {
      dispatch(removeNotification({ key }));
      removeDisplayed(key);
    };

    Object.keys(notifications).forEach((key) => {
      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      const { message = "", options } = notifications[key];
      var doc = new DOMParser().parseFromString(message, "text/html");
      const parsedMessage = doc.body.textContent || "";
      if (parsedMessage) {
        enqueueSnackbar(parsedMessage, {
          ...options,
          preventDuplicate: true,
          onExited: () => handleExit(key)
        });
      }

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [notifications, enqueueSnackbar, dispatch]);

  return null;
};

export default withSnackbar(Notifier);
