import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import CalendarService from "../../services/CalendarService";
import DataBox from "../DataBox";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import ServiceItemDialog from "./ServiceItemDialog";
import TableView, { Column } from "../TableView";
import Typography from "@material-ui/core/Typography";
import { Boarding } from "../../model/Boarding";
import { buildServiceItem } from "../../utils/CalendarUtils";
import { CalendarItem, ServiceItem } from "../../model/Calendar";
import { connect, useDispatch } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { DATE_FRIENDLY_FORMAT } from "../../constants";
import { Daycare } from "../../model/Daycare";
import { difference } from "lodash";
import { getCalendarItems } from "../../selectors";
import { getEmployeeImage, getEmployeeName } from "../../utils/EmployeeUtils";
import { Profile } from "../../model/Profile";
import { RootState } from "../../store/reducers";
import { Route, RouteComponentProps, withRouter } from "react-router";
import { ScheduledService } from "../../model/ScheduledService";
import { selectService } from "../../store/actions/app.actions";
import { useMediaQuery, useTheme } from "@material-ui/core";
import {
  ServiceItemEntities,
  useServiceItemEntities,
} from "../../hooks/useServiceItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bigAvatar: {
      margin: 0,
      width: 60,
      height: 60,
    },
    tableCell: {
      color: "#fff",
    },
  })
);

type Props = InjectedStateProps & RouteComponentProps;

const NextService: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));

  const columns: Column[] = [
    { key: "date", label: "Date" },
    { key: "serviceType", label: "Service" },
    { key: "employee", label: "With", align: "center" },
  ];

  if (isLarge) {
    columns.push({ key: "petid", label: "For" });
  }

  const [serviceItems, setServiceItems] = useState([] as ServiceItem[]);
  const [selectedService, setSelectedService] = useState(
    undefined as ServiceItem | undefined
  );

  const serviceItemEntities = useServiceItemEntities();

  useEffect(() => {
    const filterCalendarItems = (
      items: ReadonlyArray<CalendarItem>,
      date: Date,
      entities: ServiceItemEntities
    ) => {
      if (!date || !items) {
        return undefined;
      }
      const filteredItems: ServiceItem[] = items
        .filter((item) => CalendarService.isCalendarItemAfter(date, item))
        .filter((item) => item["requestid"] === undefined)
        .sort(
          (a, b) =>
            CalendarService.getCalendarItemDate(a).valueOf() -
            CalendarService.getCalendarItemDate(b).valueOf()
        )
        .map((item) => {
          return buildServiceItem(
            item,
            entities.pets,
            entities.employees,
            entities.services,
            entities.locations
          );
        });
      // reduce filtered items down to one item per pet
      return filteredItems.reduce<ServiceItem[]>((accumulator, item) => {
        const hasPet = accumulator.filter((accItem) => {
          if (!accItem.pets || !item.pets) return false;
          const accIds = accItem.pets.map((p) =>
            p !== undefined ? p.petid : 0
          );
          const itemIds = item.pets.map((p) => (p !== undefined ? p.petid : 0));
          const differentIds = difference(itemIds, accIds);
          return differentIds.length === 0;
        });
        if (hasPet.length === 0) {
          accumulator.push(item);
        }
        return accumulator;
      }, []);
    };

    if (serviceItemEntities) {
      const yesterday = ((d) => new Date(d.setDate(d.getDate() - 1)))(
        new Date()
      );
      const filteredCalendarItems = filterCalendarItems(
        props.calendarItems,
        yesterday,
        serviceItemEntities
      );
      if (filteredCalendarItems) {
        setServiceItems(filteredCalendarItems);
      }
    }
  }, [props.calendarItems, serviceItemEntities]);

  const renderCell = (item: ServiceItem, key: string): React.ReactChild => {
    let cellValue: string = item[key];

    if (key === "date") {
      if (item.date.isSame(moment(), "day")) {
        cellValue = "Today";
      } else if (item.date.isSame(moment().add(1, "day"), "day")) {
        cellValue = "Tomorrow";
      } else {
        cellValue = item.date.format(DATE_FRIENDLY_FORMAT);
      }
    }
    if (key === "petid") {
      cellValue = item.pets.map((p) => p.petname).join(", ");
    }

    let cell: React.ReactChild = cellValue;

    if (key === "employee") {
      const avatarPath: string = getEmployeeImage(item, true);
      cell = (
        <IconButton color="inherit" onClick={() => showDetails(item)}>
          <Avatar
            alt={getEmployeeName(item)}
            src={avatarPath}
            className={classes.bigAvatar}
          />
        </IconButton>
      );
    }

    return <Box className={classes.tableCell}>{cell}</Box>;
  };

  const showDetails = (item: ServiceItem) => {
    ReactGA.event({
      category: "Next Service",
      action: "Show Details",
    });
    setSelectedService(item);
    dispatch(selectService(item.schedule));
    let serviceid: number;
    const schedule = item.schedule;
    if ("daycareid" in schedule) {
      serviceid = (schedule as Daycare).daycareid;
      props.history.push(`/daycare/${serviceid}`);
    } else if ("scheduledserviceid" in schedule) {
      const scheduledService = schedule as ScheduledService;
      serviceid =
        scheduledService.requestid || scheduledService.scheduledserviceid;
      props.history.push(`/service/${serviceid}`);
    } else if ("boardingid" in schedule) {
      serviceid = (schedule as Boarding).boardingid;
      props.history.push(`/boarding/${serviceid}`);
    }
  };

  return (
    <>
      <DataBox title="Coming Up" color="secondary">
        {serviceItems.length === 0 && (
          <Typography component="p">
            There are no upcoming services scheduled this month.
          </Typography>
        )}
        {serviceItems.length > 0 && (
          <TableView
            data={serviceItems}
            columns={columns}
            renderCell={renderCell}
            headerClass={classes.tableCell}
          />
        )}
      </DataBox>
      <Route
        path="/:service/:id"
        render={() => <ServiceItemDialog item={selectedService} />}
      />
    </>
  );
};

export interface InjectedStateProps {
  profile: Profile | undefined;
  calendarItems: ReadonlyArray<CalendarItem>;
}

const mapStateToProps = (state: RootState): InjectedStateProps => {
  const { auth, entities } = state;
  const stateProps: InjectedStateProps = {
    profile: undefined,
    calendarItems: getCalendarItems(state),
  };
  if (entities) {
    if (entities.profile) {
      stateProps.profile = entities.profile[auth.userid];
    }
  }

  return stateProps;
};

export default connect<InjectedStateProps, null, {}, RootState>(
  mapStateToProps
)(withRouter(NextService));
