export const formatPhone = (phone: string | number | undefined) => {
  if (!phone) return "";
  var match = phone.toString().match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return phone.toString();
};

export const numberToString = (value: number | undefined): string => {
  return value ? value.toString() : "";
};

export const unformatPhone = (phone: string | undefined) => {
  if (!phone) return "";
  return phone.replace(/\D+/g, "");
};
