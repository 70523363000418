import { Action } from "redux-actions";
import { ActionType } from "redux-promise-middleware";
import { AppMessage, OwnerMessage } from "../../model/Company";
import { combineReducers, Reducer } from "redux";
import {
  CompanyActions,
  DaycareActions,
  BoardingActions,
} from "../actions/action.constants";

export type NullMessage = string | null;

export interface MessageState {
  readonly appmessage: NullMessage;
  readonly ownermessage: NullMessage;
  readonly daycareinstructions: string;
  readonly boardinginstructions: string;
}

const handleAppMessageAction: Reducer<NullMessage, Action<AppMessage>> = (
  state: NullMessage = null,
  action
): NullMessage => {
  switch (action.type) {
    case `${CompanyActions.LOAD_APP_MESSAGE}_${ActionType.Fulfilled}`: {
      return action.payload.mobileappmessage || "";
    }
    default: {
      return state;
    }
  }
};

const handleOwnerMessageAction: Reducer<NullMessage, Action<OwnerMessage>> = (
  state: NullMessage = null,
  action
): NullMessage => {
  switch (action.type) {
    case `${CompanyActions.LOAD_OWNER_MESSAGE}_${ActionType.Fulfilled}`: {
      return action.payload.ownermessage || "";
    }
    default: {
      return state;
    }
  }
};

const handleDaycareInstructionsAction: Reducer<string, Action<string>> = (
  state: string = "",
  action
): string => {
  switch (action.type) {
    case `${DaycareActions.LOAD_INSTRUCTIONS}_${ActionType.Fulfilled}`: {
      return action.payload || "";
    }
    default: {
      return state;
    }
  }
};

const handleBoardingInstructionsAction: Reducer<string, Action<string>> = (
  state: string = "",
  action
): string => {
  switch (action.type) {
    case `${BoardingActions.LOAD_INSTRUCTIONS}_${ActionType.Fulfilled}`: {
      return action.payload || "";
    }
    default: {
      return state;
    }
  }
};

const messsageReducer = combineReducers<MessageState>({
  appmessage: handleAppMessageAction,
  ownermessage: handleOwnerMessageAction,
  daycareinstructions: handleDaycareInstructionsAction,
  boardinginstructions: handleBoardingInstructionsAction,
});

export { messsageReducer };
