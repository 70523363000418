import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import PetService from "../../services/PetService";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import { capitalize } from "lodash";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { DATE_LONG_FORMAT } from "../../constants";
import { Pet } from "../../model/Pet";
import { uploadPetPic } from "../../store/actions/pet.actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    card: {
      height: "100%",
      maxWidth: 345,
    },
    addpet: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    media: {
      height: 225,
    },
    progress: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: 225,
    },
    input: {
      display: "none",
    },
  })
);

interface Props {
  pets: ReadonlyArray<Pet>;
}

const PetCardList: React.FunctionComponent<Props> = ({ pets = [] }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const petPicUpload = (event, pet) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    const file = event.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        if (typeof reader.result === "string") {
          const dataURL = reader.result;
          const rawData = dataURL.split(";base64,");
          if (rawData.length > 1) {
            dispatch(uploadPetPic(pet, rawData[1]));
          }
        }
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const renderPet = (pet: Pet) => (
    <Card className={classes.card}>
      {!pet.isUploading && (
        <CardMedia
          className={classes.media}
          image={pet.pathtopic}
          title={pet.petname}
        />
      )}
      {pet.isUploading && (
        <div className={classes.progress}>
          <LinearProgress />
        </div>
      )}
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {pet.petname}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {pet.description}
        </Typography>
        <List component="div" dense={true}>
          <ListItem>
            <ListItemText primary="Breed" secondary={pet.breedname} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Gender" secondary={capitalize(pet.gender)} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Birthdate"
              secondary={PetService.getBirthdate(pet).format(DATE_LONG_FORMAT)}
            />
          </ListItem>
        </List>
      </CardContent>
      <CardActions disableSpacing>
        <input
          accept="image/*"
          className={classes.input}
          id={`petpic-file-${pet.petid}`}
          type="file"
          onChange={(e) => petPicUpload(e, pet)}
        />
        <label htmlFor={`petpic-file-${pet.petid}`}>
          <IconButton component="span" aria-label="add pet photo">
            <AddAPhotoIcon />
          </IconButton>
        </label>
      </CardActions>
    </Card>
  );

  const addPet = () => {
    history.push("/add/pet");
  };

  return (
    <div className={classes.root}>
      {pets.length === 0 && (
        <Typography component="p">
          There are no pets associated with this account.
        </Typography>
      )}
      <Grid container spacing={3}>
        {pets.map((pet, index) => {
          return (
            <Grid key={`PetCard${index}`} item xs={12} sm={6} lg={3}>
              {renderPet(pet)}
            </Grid>
          );
        })}
        <Grid item xs={12} sm={6} lg={3}>
          <Card className={classes.card}>
            <CardContent className={classes.addpet}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<AddCircleIcon />}
                onClick={addPet}
              >
                Add Pet
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default PetCardList;
