import BFFBreadcrumb from "../components/app/BFFBreadcrumb";
import DOMPurify from "dompurify";
import FormLabel from "@material-ui/core/FormLabel";
import moment, { Moment } from "moment";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import SubmitButton from "../components/form/SubmitButton";
import Typography from "@material-ui/core/Typography";
import { CircularProgress, Container } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { DateRange } from "../components/form/DateRange";
import { DaycareCreation } from "../model/Daycare";
import { DaysOfWeek } from "../components/form/DaysOfWeek";
import { EntityMap } from "../store/reducers/entities.reducer";
import { ErrorBox, FormBox } from "../components/form/FormBox";
import {
  Field,
  Form,
  Formik,
  FormikErrors
  } from "formik";
import { FormControlLabel, LinearProgress, Radio } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { isWeekend } from "../utils/CalendarUtils";
import { Link as RouterLink } from "react-router-dom";
import { Pet } from "../model/Pet";
import { PetSelect } from "../components/form/PetSelect";
import { RadioGroup } from "formik-material-ui";
import { RootState } from "../store/reducers";
import { ScheduleType } from "../components/form/ScheduleType";
import { ServiceNotes } from "../components/form/ServiceNotes";
import { ServicesSelect } from "../components/form/ServicesSelect";
import { useDispatch, useSelector } from "react-redux";
import { values } from "lodash";
import {
  DateRangeType,
  ServiceReference,
  ServiceType,
} from "../model/ScheduledService";
import {
  loadDaycareInstructions,
  scheduleDaycare,
} from "../store/actions/daycare.actions";
import {
  validateDaysOfWeek,
  validatePets,
  validateServiceCreation,
} from "../utils/ValidationUtil";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
      margin: theme.spacing(2, 0),
      backgroundColor: theme.palette.grey[100],
    },
    grid: {
      padding: theme.spacing(3, 0),
    },
    submit: {
      paddingTop: 8,
    },
    select: {
      minWidth: 120,
    },
    fullWidth: {
      width: "100%",
    },
    link: {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  })
);

type DaycareCreationForm = Partial<DaycareCreation> & DateRangeType;

const ScheduleDaycarePage: React.FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [pets, setPets] = useState([] as Pet[]);

  const daycareInstructions = useSelector<RootState, string>(({ entities }) => {
    if (entities.messsges) {
      return DOMPurify.sanitize(entities.messsges.daycareinstructions);
    }
    return "";
  });

  const initialDate = useSelector<RootState, Moment>(({ app }) => {
    // Default to tomorrow unless date was previously selected
    let selectedDate = moment().add(1, "day");
    if (app.selectedDate) {
      const appDate = moment(app.selectedDate);
      if (appDate.isAfter(moment(), "day")) {
        selectedDate = appDate;
      }
    }
    // is it a weekend?
    while (isWeekend(selectedDate)) {
      selectedDate.add(1, "day");
    }
    return selectedDate;
  });

  const daycareServices = useSelector<RootState, ServiceType[]>(
    ({ entities }) => {
      if (entities && entities.daycareServices) {
        return values(entities.daycareServices);
      }
      return [];
    }
  );

  const activePackages = useSelector<RootState, ServiceReference[]>(
    ({ auth, entities }) => {
      if (entities) {
        if (entities.packages && entities.packages.daycare) {
          const daycarePackages = entities.packages.daycare[auth.userid];
          if (daycarePackages) {
            return daycarePackages
              .filter((p) => p.isactive === 1 && p.numberleft > 0)
              .map((p) => {
                return {
                  serviceid: p.serviceid,
                  servicename: p.servicename,
                  packagename: p.servicename,
                  showtoowner: 1,
                  packageid: p.packageid,
                } as ServiceReference;
              });
          }
        }
      }
      return [];
    }
  );

  const petMap = useSelector<RootState, EntityMap<Pet> | null>(
    ({ entities }) => {
      if (entities && entities.pets) {
        return entities.pets;
      }
      return null;
    }
  );

  useEffect(() => {
    if (petMap) {
      setPets(values(petMap));
    }
  }, [petMap]);

  useEffect(() => {
    dispatch(loadDaycareInstructions());
  }, [dispatch]);

  if (daycareServices.length === 0 || pets.length === 0) {
    return (
      <div className="bff-loading">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <BFFBreadcrumb pageName="Schedule Daycare" />
      <Container maxWidth="lg">
        <Paper className={classes.root}>
          <Typography variant="h4" gutterBottom>
            Schedule Daycare
          </Typography>
          {daycareInstructions && (
            <FormBox>
              <Typography
                component="span"
                color="inherit"
                dangerouslySetInnerHTML={{ __html: daycareInstructions }}
              />
            </FormBox>
          )}
          {activePackages.length === 0 && (
            <ErrorBox>
              You do not have any active daycare packages. Please{" "}
              <RouterLink className={classes.link} to="/contact">
                contact BFF
              </RouterLink>{" "}
              to activate a new package.
            </ErrorBox>
          )}
          {activePackages.length > 0 && (
            <Formik<DaycareCreationForm>
              initialValues={{
                type: "single",
                startDate: initialDate,
                endDate: initialDate,
                serviceid: daycareServices[0]
                  ? daycareServices[0].serviceid.toString()
                  : "0",
                packageid: activePackages[0]
                  ? activePackages[0].serviceid.toString()
                  : "0",
                petids: pets[0] ? [pets[0].petid] : [],
              }}
              validate={(values: DaycareCreationForm) => {
                const errors: FormikErrors<DaycareCreation> = {};
                validateServiceCreation(values, errors);
                validateDaysOfWeek(values, errors);
                validatePets(values, errors);
                return errors;
              }}
              onSubmit={(values: DaycareCreationForm, { setSubmitting }) => {
                if (values.type === "single") {
                  values.endDate = values.startDate;
                }
                //dipatch daycare creation action
                ReactGA.event({
                  category: "Daycare",
                  action: "Submit Schedule",
                });
                dispatch(scheduleDaycare(values));
                setTimeout(() => {
                  setSubmitting(false);
                }, 500);
              }}
            >
              {(formProps) => {
                const { submitForm, isSubmitting, values } = formProps;
                return (
                  <Form>
                    <FormBox>
                      <Grid container spacing={3} className={classes.grid}>
                        <ScheduleType {...formProps} />
                        <DateRange {...formProps} />
                      </Grid>
                    </FormBox>
                    {values.type === "multiple" && (
                      <FormBox>
                        <Grid container spacing={3} className={classes.grid}>
                          <DaysOfWeek {...formProps} />
                        </Grid>
                      </FormBox>
                    )}
                    <FormBox>
                      <Grid container spacing={3} className={classes.grid}>
                        {daycareServices.length > 1 && (
                          <Grid item xs={12}>
                            <FormLabel component="legend">Type</FormLabel>
                            <Field
                              value={values.serviceid}
                              name="serviceid"
                              component={RadioGroup}
                            >
                              {daycareServices.map((type) => (
                                <FormControlLabel
                                  key={type.serviceid}
                                  value={type.serviceid}
                                  control={<Radio />}
                                  label={type.servicename}
                                />
                              ))}
                            </Field>
                          </Grid>
                        )}
                        <ServicesSelect
                          {...formProps}
                          name="packageid"
                          disabled={true}
                          serviceRefs={activePackages}
                        />
                        <PetSelect {...formProps} pets={pets} />
                        <ServiceNotes />
                        <Grid item xs={12}>
                          {isSubmitting && <LinearProgress />}
                        </Grid>
                      </Grid>
                    </FormBox>
                    <SubmitButton
                      isSubmitting={isSubmitting}
                      onSubmit={submitForm}
                    />
                  </Form>
                );
              }}
            </Formik>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default ScheduleDaycarePage;
