import CalendarService from "../services/CalendarService";
import CompanyService from "../services/CompanyService";
import moment from "moment";
import { Employee, Location } from "../model/Company";
import { EMPTY_PET, Pet } from "../model/Pet";
import { EntityMap } from "../store/reducers/entities.reducer";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { ScheduledService, ServiceType } from "../model/ScheduledService";
import { Service } from "../model/Package";
import {
  BFF_LOCATION,
  PRIVATE_SHOPPING_ID,
  PUPPY_POWER_HOUR_ID,
} from "../constants";
import {
  CalendarItem,
  CalendarServiceType,
  ServiceItem,
} from "../model/Calendar";

export const getDefaultService = (
  calendarItem: CalendarItem
): ServiceType | undefined => {
  if (calendarItem.services && calendarItem.services.length > 0) {
    return calendarItem.services[0];
  }
  return undefined;
};

export const isScheduledWalk = (service: ScheduledService): boolean => {
  const defaultService = getDefaultService(service);
  if (defaultService) {
    return (
      defaultService.serviceid !== PUPPY_POWER_HOUR_ID &&
      defaultService.serviceid !== PRIVATE_SHOPPING_ID
    );
  }
  return false;
};

export const isScheduledPowerHour = (service: ScheduledService): boolean => {
  const defaultService = getDefaultService(service);
  if (defaultService) {
    return defaultService.serviceid === PUPPY_POWER_HOUR_ID;
  }
  return false;
};

export const isScheduledShopping = (service: ScheduledService): boolean => {
  const defaultService = getDefaultService(service);
  if (defaultService) {
    return defaultService.serviceid === PRIVATE_SHOPPING_ID;
  }
  return false;
};

const getServiceType = (
  item: CalendarItem
): CalendarServiceType | undefined => {
  if (CalendarService.isDaycareItem(item)) {
    return "Daycare";
  }
  if (CalendarService.isServicesItem(item)) {
    if (isScheduledWalk(item)) {
      return "Walk";
    }
    if (isScheduledPowerHour(item)) {
      return "Puppy Power Hour";
    }
    if (isScheduledShopping(item)) {
      return "Private Shopping";
    }
  }
  if (CalendarService.isBoardingItem(item)) {
    return "Boarding";
  }
  return undefined;
};

export const buildServiceItem = (
  item: CalendarItem,
  allPets: EntityMap<Pet>,
  employees: ReadonlyArray<Employee>,
  services: EntityMap<Service>,
  locations: EntityMap<Location>
): ServiceItem => {
  let pets: Pet[] = [];
  let petids: number[] = [];
  if (item.petid) {
    petids.push(item.petid);
  }
  let extraPets = item.petids;
  if (CalendarService.isBoardingItem(item)) {
    extraPets = item.pets;
  }
  if (extraPets) {
    extraPets.forEach((p) => petids.push(p.petid));
  }
  pets = petids.map((id) => allPets[id]);
  if (pets.length === 0) {
    pets.push(EMPTY_PET);
  }
  let employee: Employee | undefined;
  if (CalendarService.isServicesItem(item)) {
    if (item.employeeid) {
      employee = employees.find((emp) => emp.employeeid === item.employeeid);
    } else {
      employee = CompanyService.findEmployee(item.employee, employees);
    }
    if (isScheduledPowerHour(item)) {
      item.pickuplocation =
        locations[BFF_LOCATION].locationname || item.pickuplocation;
    }
  }
  const serviceRef = getDefaultService(item);
  let service: Service | undefined;
  if (serviceRef) {
    service = services[serviceRef.serviceid];
  }
  return {
    date: CalendarService.getCalendarItemDate(item),
    schedule: item,
    serviceType: getServiceType(item),
    service,
    pets,
    employee,
  };
};

export const isWeekend = (date: MaterialUiPickersDate): boolean => {
  if (date) {
    return moment(date).isoWeekday() === 0 || moment(date).isoWeekday() === 6;
  }
  return false;
};

export const isMatchingDay = (
  date: MaterialUiPickersDate,
  days: number[]
): boolean => {
  if (date) {
    return days.includes(moment(date).isoWeekday());
  }
  return false;
};

export const parseTime = (time: string): string => {
  if (time.startsWith("0")) {
    return time.substr(1);
  }
  return time;
};
