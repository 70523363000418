export interface UserReference {
  userid: number;
}

export interface Profile extends UserReference {
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  webcamurl?: string;
  addr1: string;
  addr2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  homephone?: string;
  workphone?: string;
  cellphone?: string;
  emergencycontact?: string;
  emergencyemail?: string;
  emergencyphone?: string;
  isEmployee: boolean;
  pawpointcapable?: number;
  pawpoints?: number;
}

export const EMPTY_PROFILE: Profile = {
  username: "",
  userid: 0,
  isEmployee: false,
  firstname: "",
  lastname: "",
  addr1: "",
  addr2: "",
  city: "",
  state: "",
  zip: "",
  country: "",
  email: ""
};
