import GlobalConfig from "../utils/GlobalConfig";
import { PetExec } from "../constants";

const sessionKey = "bff-session";

export interface AuthProvider<T> {
  buildAuthorizeUrl(): string;
  getSignOutUrl(redirectUrl: string): string;
  extractSession(redirectUrl: string): T | undefined;
  validateSession(session: T): boolean;
  getAccessToken(session: T): string;
}

export interface PetExecSession {
  accessToken: string;
  expireDurationSeconds: number;
  issuedAt: number;
}

export const AuthenticationService = {
  getAuthorizeUrl<T>(provider: AuthProvider<T>) {
    return provider.buildAuthorizeUrl();
  },
  acquireToken<T>(
    url: string,
    provider: AuthProvider<T>,
    storage: Storage = window.sessionStorage
  ): T | undefined {
    const session = provider.extractSession(url);
    if (session) {
      storage.setItem(sessionKey, JSON.stringify(session));
    }
    return session;
  },
  restoreSession<T>(
    provider: AuthProvider<T>,
    storage: Storage = window.sessionStorage
  ): T | undefined {
    const sessionString = storage.getItem(sessionKey);
    if (typeof sessionString !== "string" || sessionString.length === 0) {
      return undefined;
    }
    const session: T = JSON.parse(sessionString);

    if (!provider.validateSession(session)) {
      storage.removeItem(sessionKey);
      return undefined;
    }
    return session;
  },
  invalidateSession(storage: Storage = window.sessionStorage): void {
    storage.removeItem(sessionKey);
  },
  getAccessToken<T>(
    provider: AuthProvider<T>,
    storage: Storage = window.sessionStorage
  ): string | undefined {
    const sessionString = storage.getItem(sessionKey);
    if (typeof sessionString !== "string" || sessionString.length === 0) {
      return undefined;
    }

    const session: T = JSON.parse(sessionString);
    return provider.getAccessToken(session);
  }
};

export const petExecProvider: AuthProvider<PetExecSession> = {
  buildAuthorizeUrl(): string {
    let authUrl = `${PetExec.API_URL}/authorize?`;
    authUrl += "response_type=token&";
    authUrl += `client_id=${GlobalConfig.clientId}&`;
    authUrl += `redirect_uri=${window.location.protocol}//${window.location.hostname}`;
    if (window.location.port) {
      authUrl += `:${window.location.port}`;
    }
    authUrl += "&";
    authUrl += `scope=${PetExec.APP_SCOPES.join(" ")}&`;
    authUrl += `state=${PetExec.APP_NAME}`;
    return authUrl;
  },
  getSignOutUrl(redirectUrl: string): string {
    throw new Error("Method not implemented.");
  },
  extractSession(redirectUrl: string): PetExecSession | undefined {
    if (!redirectUrl || redirectUrl.indexOf("access_token") === -1) {
      return undefined;
    }

    let accessToken: string = "";
    const accessTokenMatch = redirectUrl.match(/access_token=([^&]+)/);
    if (accessTokenMatch) {
      accessToken = accessTokenMatch[1];
    }
    let expireDurationSeconds: number = 3600;
    const expireDurationSecondsMatch = redirectUrl.match(/expires_in=([^&]+)/);
    if (expireDurationSecondsMatch) {
      expireDurationSeconds = parseInt(expireDurationSecondsMatch[1]);
    }

    return {
      accessToken,
      expireDurationSeconds,
      issuedAt: new Date().getTime() / 1000
    };
  },
  validateSession(session: PetExecSession): boolean {
    const now = new Date().getTime() / 1000;
    const expiration = session.issuedAt + session.expireDurationSeconds;
    const minimumDuration = 60 * 15;
    return expiration - now > minimumDuration;
  },
  getAccessToken(session: PetExecSession): string {
    return session.accessToken;
  }
};
