import BFFBreadcrumb from "../components/app/BFFBreadcrumb";
import Paper from "@material-ui/core/Paper";
import PetCardList from "../components/profile/PetCardList";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { BFFThunkDispatch } from "../store/actions/action.types";
import { connect } from "react-redux";
import { Container } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { EMPTY_PROFILE, Profile } from "../model/Profile";
import { logout } from "../store/actions/auth.actions";
import { Pet } from "../model/Pet";
import { ProfileForm } from "../components/profile/ProfileForm";
import { RootState } from "../store/reducers";
import { values } from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
      margin: theme.spacing(2, 0),
    },
    form: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "100%",
    },
    logoutButton: {
      margin: theme.spacing(2, 1),
      width: "100%",
    },
  })
);

type Props = InjectedStateProps & InjectedDispatchProps;

const ProfilePage: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const { profile } = props;

  return (
    <>
      <BFFBreadcrumb pageName="Profile" />
      <Container maxWidth="lg">
        <Paper className={classes.root}>
          <Typography variant="h4">Your Profile</Typography>
          <ProfileForm profile={profile} />
        </Paper>
        <Paper className={classes.root}>
          <Typography variant="h4" gutterBottom>
            Your Pets
          </Typography>
          <PetCardList pets={props.pets} />
        </Paper>
      </Container>
    </>
  );
};

export interface InjectedStateProps {
  profile: Profile;
  pets: Pet[];
}

export interface InjectedDispatchProps {
  logout: () => void;
}

const mapStateToProps = ({ auth, entities }: RootState): InjectedStateProps => {
  const profile =
    (entities.profile ? entities.profile[auth.userid] : undefined) ||
    EMPTY_PROFILE;
  const pets = entities.pets ? values(entities.pets) : [];
  return {
    profile,
    pets,
  };
};

const mapDispatchToProps = (
  dispatch: BFFThunkDispatch
): InjectedDispatchProps => {
  return {
    logout: () => {
      dispatch(logout());
    },
  };
};

export default connect<
  InjectedStateProps,
  InjectedDispatchProps,
  {},
  RootState
>(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePage);
