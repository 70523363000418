import BFFBreadcrumb from "../components/app/BFFBreadcrumb";
import React, { useEffect, useState } from "react";
import { Boarding } from "../model/Boarding";
import { RootState } from "../store/reducers";
import { useHistory, useRouteMatch } from "react-router";
import { useSelector } from "react-redux";
import {
  Paper,
  Typography,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
      margin: theme.spacing(2, 0),
      backgroundColor: theme.palette.grey[100],
    },
    grid: {
      padding: theme.spacing(3, 0),
    },
    select: {
      minWidth: 120,
    },
    fullWidth: {
      width: "100%",
    },
  })
);

const EditBoardingPage: React.FunctionComponent = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const history = useHistory();

  const [serviceId, setServiceId] = useState<number | null>(null);

  const boardingItem = useSelector<RootState, Boarding | null>(
    ({ entities }) => {
      if (serviceId) {
        if (entities && entities.calendar.boardings) {
          return entities.calendar.boardings[serviceId];
        }
      }
      return null;
    }
  );

  useEffect(() => {
    if (match.params && match.params["id"]) {
      const serviceId: number = match.params["id"];
      setServiceId(serviceId);
    } else {
      history.replace("/");
    }
  }, [match, history]);

  useEffect(() => {
    if (serviceId && !boardingItem) {
      history.replace("/");
    }
  }, [history, serviceId, boardingItem]);

  return (
    <>
      <BFFBreadcrumb pageName="Edit Boarding" />
      <Paper className={classes.root}>
        <Typography variant="h4" gutterBottom>
          Edit Boarding
        </Typography>
        {boardingItem && <div>{boardingItem.start}</div>}
      </Paper>
    </>
  );
};

export default EditBoardingPage;
