import { Action } from "redux-actions";
import { ActionType } from "redux-promise-middleware";
import { EntityMap } from "./entities.reducer";
import { mapKeys } from "lodash";
import { PetActions } from "../actions/action.constants";
import { Reducer } from "redux";
import { Vet } from "../../model/Pet";

export type VetState = EntityMap<Vet> | undefined;

const initialState: VetState = {};

const handleLoadAction: Reducer<VetState, Action<Vet[]>> = (
  state = initialState,
  action
): VetState => {
  switch (action.type) {
    case `${PetActions.LOAD_VETS}_${ActionType.Fulfilled}`: {
      return {
        ...state,
        ...mapKeys(action.payload, "vetid"),
      };
    }
    default: {
      return state;
    }
  }
};

export { handleLoadAction as vetReducer };
