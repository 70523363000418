import BFFBreadcrumb from "../components/app/BFFBreadcrumb";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import HomeIcon from "@material-ui/icons/Home";
import Link, { LinkProps } from "@material-ui/core/Link";
import LinkIcon from "@material-ui/icons/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import Paper from "@material-ui/core/Paper";
import PaymentIcon from "@material-ui/icons/Payment";
import PhoneIcon from "@material-ui/icons/Phone";
import React from "react";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import Typography from "@material-ui/core/Typography";
import VideocamIcon from "@material-ui/icons/Videocam";
import { BFF_HREF } from "../constants";
import { Copyright, CopyrightContainer } from "../components/Copyright";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { RootState } from "../store/reducers";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
      margin: theme.spacing(2, 0),
    },
    footer: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  })
);

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  secondary?: string;
  href: string;
  target?: string;
}

const ListItemLink = (props: ListItemLinkProps) => {
  const { icon, primary, secondary, href, target = "_blank" } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<
        HTMLAnchorElement,
        Omit<LinkProps, "innerRef" | "href" | "rel" | "target">
      >((itemProps, ref) => (
        <Link
          href={href}
          target={target}
          rel="noopener"
          {...itemProps}
          innerRef={ref}
        />
      )),
    [href, target]
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} secondary={secondary} />
      </ListItem>
    </li>
  );
};

const AboutPage: React.FunctionComponent = () => {
  const classes = useStyles();

  const webcamURL = useSelector<RootState, string>(({ auth, entities }) => {
    if (entities.profile) {
      return entities.profile[auth.userid].webcamurl || "";
    }
    return "";
  });

  return (
    <>
      <BFFBreadcrumb pageName="About Us" />
      <Container maxWidth="lg">
        <Paper className={classes.root}>
          <Typography variant="h4" gutterBottom>
            About Us
          </Typography>
          <List aria-label="bff contact links">
            <ListItemLink
              primary="Main Site"
              icon={<HomeIcon />}
              secondary="https://www.bffplaycare.com"
              href="https://www.bffplaycare.com"
            />
            <ListItemLink
              primary="Email Us"
              icon={<MailIcon />}
              secondary="info@bffplaycare.com"
              href="mailto:info@bffplaycare.com"
            />
            <ListItemLink
              primary="Invoices &amp; eTransfers"
              icon={<PaymentIcon />}
              secondary="payments@bffplaycare.com"
              href="mailto:payments@bffplaycare.com"
            />
            <ListItemLink
              primary="Call Us"
              icon={<PhoneIcon />}
              secondary="(416) 551-CARE"
              href="tel:4165512273"
              target="_self"
            />
            <ListItemLink
              primary="Shop"
              icon={<ShoppingBasketIcon />}
              secondary={BFF_HREF.shop}
              href={BFF_HREF.shop}
            />
            <ListItemLink
              primary="Live Cams"
              icon={<VideocamIcon />}
              secondary={webcamURL}
              href={webcamURL}
            />
          </List>
          <Divider />
          <List>
            <ListItemLink
              primary="Refund Policy"
              icon={<LinkIcon />}
              secondary="https://www.bffplaycare.com/payment-protection"
              href="https://www.bffplaycare.com/payment-protection"
            />
            <ListItemLink
              primary="Terms &amp; Conditions"
              icon={<LinkIcon />}
              secondary="https://www.bffplaycare.com/terms-conditions"
              href="https://www.bffplaycare.com/terms-conditions"
            />
          </List>
        </Paper>
        <Container maxWidth="md" component="footer" className={classes.footer}>
          <Box mt={5}>
            <CopyrightContainer>
              <Copyright />
            </CopyrightContainer>
          </Box>
        </Container>
      </Container>
    </>
  );
};

export default AboutPage;
