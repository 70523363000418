import moment from "moment";
import { DATE_LONG_FORMAT } from "../constants";
import { Package } from "../model/Package";
import { Profile } from "../model/Profile";

export const buildReloadPackageEmail = (
  owner: Profile,
  servicePackage: Package
) => {
  let message: string = "<p>Reload Package Request</p>";
  message += `<p><strong>Owner:</strong> ${owner.firstname} ${owner.lastname}</p>`;
  message += `<p><strong>Service:</strong> ${servicePackage.servicename}</p>`;
  message += `<p><strong>Date:</strong> ${moment().format(
    DATE_LONG_FORMAT
  )}</p>`;
  return message;
};
