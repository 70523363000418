import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Field } from "formik";
import { Grid, Theme } from "@material-ui/core";
import { TextField } from "formik-material-ui";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullWidth: {
      width: "100%",
    },
  })
);

interface Props {
  label?: string;
  rows?: number;
  placeholder?: string;
  name?: string;
}

export const ServiceNotes: React.FunctionComponent<Props> = ({
  label = "Notes",
  rows = 4,
  placeholder = "Food instructions, etc",
  name = "notes",
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6}>
      <Field
        name={name}
        type="text"
        label={label}
        placeholder={placeholder}
        className={classes.fullWidth}
        multiline={true}
        rows={rows}
        component={TextField}
      />
    </Grid>
  );
};
