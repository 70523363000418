import { Action } from "redux-actions";
import { EntityMap } from "./entities.reducer";
import { mapKeys } from "lodash";
import { Reducer } from "react";
import { ScheduledServiceType } from "../../model/ScheduledService";
import { ServicesActions } from "../actions/action.constants";

export type ScheduledServiceTypesState =
  | EntityMap<ScheduledServiceType>
  | undefined;

const handleLoadAction: Reducer<
  ScheduledServiceTypesState,
  Action<ScheduledServiceType[]>
> = (state = {}, action): ScheduledServiceTypesState => {
  switch (action.type) {
    case ServicesActions.ADD_SERVICE_TYPES: {
      return {
        ...state,
        ...mapKeys(action.payload, "servicetypeid")
      };
    }
    default: {
      return state;
    }
  }
};

export { handleLoadAction as scheduledServiceTypesReducer };
