import React from "react";
import { Field, FormikProps } from "formik";
import {
  FormControlLabel,
  FormLabel,
  Grid,
  Radio
  } from "@material-ui/core";
import { PetCreation } from "../../model/Pet";
import { RadioGroup } from "formik-material-ui";
import { validateRequired } from "./validations";

type Props = FormikProps<Partial<PetCreation>>;

export const GenderSelect: React.FunctionComponent<Props> = ({ values }) => {
  const name = "gender";
  return (
    <Grid item xs={12} sm={6}>
      <FormLabel component="legend">Gender</FormLabel>
      <Field
        value={values[name]}
        name={name}
        component={RadioGroup}
        validate={validateRequired}
      >
        <FormControlLabel value="Male" control={<Radio />} label="Male" />
        <FormControlLabel value="Female" control={<Radio />} label="Female" />
      </Field>
    </Grid>
  );
};
