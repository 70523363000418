import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Grid from "@material-ui/core/Grid";
import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { filter, keys, values } from "lodash";
import { Link } from "react-router-dom";
import { Pet } from "../../model/Pet";
import { RootState } from "../../store/reducers";
import { useSelector } from "react-redux";
import {
  isScheduledWalk,
  isScheduledPowerHour,
} from "../../utils/CalendarUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      paddingTop: theme.spacing(2),
    },
  })
);

const ActionBar: React.FunctionComponent = () => {
  const classes = useStyles();

  const [pets, petsLoading] = useSelector<RootState, [Pet[], boolean]>(
    ({ app, entities }) => {
      if (entities && entities.pets) {
        return [values(entities.pets), app.petsLoading];
      }
      return [[], true];
    }
  );

  const calendarLoading = useSelector<RootState, boolean>(({ app }) => {
    return app.calendarLoading;
  });

  const [hasDaycare, hasWalk, hasBoarding] = useSelector<
    RootState,
    [boolean, boolean, boolean, boolean]
  >(({ entities }) => {
    if (entities && entities.calendar) {
      const daycares = keys(entities.calendar.daycares) || [];
      const boardings = keys(entities.calendar.boardings) || [];
      const walks = filter(entities.calendar.scheduledServices, (s) => {
        return isScheduledWalk(s);
      });
      const powerHour = filter(entities.calendar.scheduledServices, (s) => {
        return isScheduledPowerHour(s);
      });

      return [
        daycares.length > 0,
        walks.length > 0,
        boardings.length > 0,
        powerHour.length > 0,
      ];
    }
    return [false, false, false, false];
  });

  return (
    <div className={classes.root}>
      <Grid container spacing={4} justify="flex-end">
        {calendarLoading && (
          <Grid item>
            <Skeleton width={400} />
          </Grid>
        )}
        {!calendarLoading && !petsLoading && pets.length > 0 && (
          <>
            <Grid item>
              <ButtonGroup variant="text">
                {!hasDaycare && (
                  <Button
                    component={Link}
                    to="/schedule/daycare"
                    color="secondary"
                  >
                    Daycare
                  </Button>
                )}
                {!hasWalk && (
                  <Button
                    component={Link}
                    to="/schedule/walk"
                    color="secondary"
                  >
                    Walk
                  </Button>
                )}
                {!hasBoarding && (
                  <Button
                    component={Link}
                    to="/schedule/boarding"
                    color="secondary"
                  >
                    Boarding
                  </Button>
                )}
              </ButtonGroup>
            </Grid>
          </>
        )}
        {!petsLoading && pets.length === 0 && (
          <Grid item>
            <ButtonGroup variant="outlined">
              <Button component={Link} to="/add/pet" color="secondary">
                Add Pet
              </Button>
            </ButtonGroup>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default ActionBar;
