import cn from "classnames";
import Paper from "@material-ui/core/Paper";
import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
  primary: {
    color: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
  error: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
    color: "#b4004e",
    fontSize: theme.typography.h6.fontSize,
  },
}));

interface FormBoxProps {
  variant?: "primary" | "secondary";
}

export const FormBox: React.FunctionComponent<FormBoxProps> = ({
  variant,
  children,
}) => {
  const classes = useStyles();
  const classNames = cn(classes.root, {
    [classes.primary]: variant === "primary",
    [classes.secondary]: variant === "secondary",
  });
  return (
    <Paper className={classNames}>
      {children != null ? (
        children
      ) : (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
    </Paper>
  );
};

export const ErrorBox: React.FunctionComponent = ({ children }) => {
  const classes = useStyles();
  return <Paper className={classes.error}>{children}</Paper>;
};
