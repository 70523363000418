import { Action } from "redux-actions";
import { ActionType } from "redux-promise-middleware";
import { CompanyActions } from "../actions/action.constants";
import { EntityMap } from "./entities.reducer";
import { mapKeys } from "lodash";
import { Reducer } from "react";
import { Service } from "../../model/Package";

export type CompanyServiceState = EntityMap<Service> | undefined;

const handleLoadCompanyServicesAction: Reducer<
  CompanyServiceState,
  Action<Service[]>
> = (state = {}, action): CompanyServiceState => {
  switch (action.type) {
    case `${CompanyActions.LOAD_PACKAGES}_${ActionType.Fulfilled}`: {
      return {
        ...state,
        ...mapKeys(action.payload, "serviceid")
      };
    }
    default: {
      return state;
    }
  }
};

export { handleLoadCompanyServicesAction as companyServicesReducer };
