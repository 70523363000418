import { boardingReducer, BoardingState } from "./boarding.reducer";
import { combineReducers } from "redux";
import { daycareReducer, DaycareState } from "./daycare.reducer";
import { scheduledServicesReducer, ScheduledServicesState } from "./sceduledService.reducer";

export interface CalendarState {
  readonly daycares: DaycareState;
  readonly scheduledServices: ScheduledServicesState;
  readonly boardings: BoardingState;
}

const calendarReducer = combineReducers<CalendarState>({
  daycares: daycareReducer,
  scheduledServices: scheduledServicesReducer,
  boardings: boardingReducer
});

export { calendarReducer };
