import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { BFFThunkDispatch } from "../store/actions/action.types";
import { connect } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { EMPTY_PROFILE, Profile } from "../model/Profile";
import { logout } from "../store/actions/auth.actions";
import { ProfileForm } from "../components/profile/ProfileForm";
import { RootState } from "../store/reducers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
      margin: theme.spacing(2, 0)
    },
    form: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap"
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "100%"
    },
    logoutButton: {
      margin: theme.spacing(2, 1)
    }
  })
);

type Props = InjectedStateProps & InjectedDispatchProps;

const EmployeeHome: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const { profile } = props;

  const handleClick = () => {
    props.logout();
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h4" gutterBottom>
        About You
      </Typography>
      <ProfileForm profile={profile} />
      <Button
        variant="contained"
        color="primary"
        className={classes.logoutButton}
        onClick={handleClick}
      >
        Logout
      </Button>
    </Paper>
  );
};

export interface InjectedStateProps {
  profile: Profile;
}

export interface InjectedDispatchProps {
  logout: () => void;
}

const mapStateToProps = ({
  auth,
  entities
}: RootState): InjectedStateProps => ({
  profile:
    (entities.profile ? entities.profile[auth.userid] : undefined) ||
    EMPTY_PROFILE
});

const mapDispatchToProps = (
  dispatch: BFFThunkDispatch
): InjectedDispatchProps => {
  return {
    logout: () => {
      dispatch(logout());
    }
  };
};

export default connect<
  InjectedStateProps,
  InjectedDispatchProps,
  {},
  RootState
>(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeHome);
