import moment from "moment";
import PetService from "../services/PetService";
import { Pet } from "../model/Pet";

export const isPuppy = (pet: Pet) => {
  const birthdate = PetService.getBirthdate(pet);
  if (birthdate) {
    const lastYear = moment().subtract(1, "year");
    return birthdate.isSameOrAfter(lastYear);
  }
  return false;
};
