import AbstractPetService, { CreationResponse, PetExecResponse } from "./AbstractPetService";
import { PetExec } from "../constants";
import {
  ScheduledService,
  ScheduledServiceCreation,
  ScheduledServiceType,
  ServiceType,
  ServiceTypePayload
  } from "../model/ScheduledService";
import { TokenReplacer } from "../utils/TokenReplacer";

interface ScheduledServiceResponse extends PetExecResponse {
  scheduledservice: ScheduledService;
}

interface ServiceTypesResponse extends PetExecResponse {
  servicetypes: ScheduledServiceType[];
}

interface ServicesByTypeResponse extends PetExecResponse {
  services: ServiceType[];
}

class SchedulingService extends AbstractPetService {
  private static SCHEDULED_SERVICE_PATH = "/scheduled-service";

  public async loadScheduledService(
    scheduledserviceid: number
  ): Promise<ScheduledService> {
    const endpoint = `${PetExec.API_URL}${SchedulingService.SCHEDULED_SERVICE_PATH}/{scheduledserviceid}`;
    const url = TokenReplacer.replace(endpoint, {
      scheduledserviceid
    });
    const response = await this.fetchJson<ScheduledServiceResponse>(url);
    return { ...{ status: "Requested" }, ...response.scheduledservice };
  }

  public async loadServiceTypes(): Promise<ScheduledServiceType[]> {
    const endpoint = `${PetExec.API_URL}${SchedulingService.SCHEDULED_SERVICE_PATH}/service-types`;
    const response = await this.fetchJson<ServiceTypesResponse>(endpoint);
    return response.servicetypes;
  }

  public async loadServicesByType(stid: number): Promise<ServiceTypePayload> {
    const endpoint = `${PetExec.API_URL}${SchedulingService.SCHEDULED_SERVICE_PATH}/services/{stid}`;
    const url = TokenReplacer.replace(endpoint, {
      stid
    });
    const response = await this.fetchJson<ServicesByTypeResponse>(url);
    return { stid, services: response.services };
  }

  public async requestScheduledService(
    requestValues: ScheduledServiceCreation
  ): Promise<CreationResponse> {
    if (!requestValues.serviceRef) {
      throw Error("No service ref included with Walk request");
    }

    const url = `${PetExec.API_URL}${SchedulingService.SCHEDULED_SERVICE_PATH}/request`;

    const walkData = new FormData();

    if (
      requestValues.startDate &&
      typeof requestValues.startDate !== "string"
    ) {
      walkData.append(
        "startDate",
        requestValues.startDate.format(PetExec.API_DATE_FORMAT)
      );
    }
    if (requestValues.endDate && typeof requestValues.endDate !== "string") {
      walkData.append(
        "endDate",
        requestValues.endDate.format(PetExec.API_DATE_FORMAT)
      );
    }
    if (requestValues.appointmentWindow) {
      if (requestValues.appointmentWindow === "morning") {
        walkData.append("appointmentTime", "10:00 AM");
      } else if (requestValues.appointmentWindow === "afternoon") {
        walkData.append("appointmentTime", "01:00 PM");
      } else if (requestValues.appointmentWindow === "evening") {
        walkData.append("appointmentTime", "05:00 PM");
      }
    } else {
      if (requestValues.appointmentTime) {
        walkData.append("appointmentTime", requestValues.appointmentTime);
      }
    }
    walkData.append("petids", requestValues.petids.join(","));
    walkData.append("dropOffTime", requestValues.dropOffTime);
    walkData.append("pickUpTime", requestValues.pickUpTime);
    walkData.append("daysOfWeek", requestValues.daysOfWeek.join(","));
    if (requestValues.serviceRef.stid) {
      walkData.append("stid", requestValues.serviceRef.stid.toString());
    }
    walkData.append("services", requestValues.serviceid.toString());
    walkData.append("note", `${requestValues.notes}
    
    (${requestValues.daysOfWeek.join(",")})`);

    return await this.fetchJson<CreationResponse>(url, {
      method: "POST",
      body: walkData
    });
  }
}

export default SchedulingService;
