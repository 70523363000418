import DaycareService from "../../services/DaycareService";
import { BFF_LOCATION } from "../../constants";
import { BFFThunkAction, BFFThunkDispatch } from "./action.types";
import { createAction } from "redux-actions";
import { Daycare, DaycareCreation } from "../../model/Daycare";
import { DaycareActions } from "./action.constants";
import { ServiceType } from "../../model/ScheduledService";
import { showNotification } from "./app.actions";

const NOTIFICATION_KEY = "daycareCreation";

function loadDaycareAction(daycareid: number): Promise<Daycare> {
  const service = new DaycareService();
  return service.loadDaycare(daycareid);
}

export const loadDaycare = createAction<Promise<Daycare>, number>(
  DaycareActions.LOAD,
  loadDaycareAction
);

function loadDaycareServicesAction(): Promise<ServiceType[]> {
  const service = new DaycareService();
  return service.loadDaycareServices();
}

export const loadDaycareServices = createAction<Promise<ServiceType[]>>(
  DaycareActions.LOAD_SERVICES,
  loadDaycareServicesAction
);

function loadDaycareMessageAction(): Promise<string> {
  const service = new DaycareService();
  return service.loadInstructions();
}

export const loadDaycareInstructions = createAction<Promise<string>>(
  DaycareActions.LOAD_INSTRUCTIONS,
  loadDaycareMessageAction
);

function scheduleDaycareThunk(
  daycare: Partial<DaycareCreation>
): BFFThunkAction<void> {
  return async (dispatch: BFFThunkDispatch) => {
    const completedDaycare: DaycareCreation = {
      petids: daycare.petids || [],
      startDate: daycare.startDate || "",
      endDate: daycare.endDate || "",
      daysOfWeek: daycare.daysOfWeek || [],
      dropOffTime: "07:00 AM",
      pickUpTime: "07:00 PM",
      dropOffLocation: BFF_LOCATION,
      pickUpLocation: BFF_LOCATION,
      serviceid: daycare.serviceid || "0",
      packageid: daycare.packageid || "0",
      notes: daycare.notes || ""
    };

    const service = new DaycareService();
    const response = await service.addDaycare(completedDaycare);

    if (response.success && response.message) {
      dispatch(
        showNotification({
          key: NOTIFICATION_KEY,
          message: response.message
        })
      );
    }

    if (!response.success && response.errors && response.errors.length > 0) {
      dispatch(
        showNotification({
          key: NOTIFICATION_KEY,
          message: response.errors[0],
          options: { variant: "error" }
        })
      );
    }
  };
}

export const scheduleDaycare = scheduleDaycareThunk;

function deleteDaycareThunk(daycare: Daycare): BFFThunkAction<void> {
  return async (dispatch: BFFThunkDispatch, getState) => {
    const service = new DaycareService();
    const response = await service.deleteDaycare(daycare);

    if (response.success && response.message) {
      dispatch(_deleteDaycareAction(daycare));
      dispatch(
        showNotification({
          key: NOTIFICATION_KEY,
          message: "Daycare successfully scheduled"
        })
      );
    }

    if (!response.success && response.message) {
      dispatch(
        showNotification({
          key: NOTIFICATION_KEY,
          message: response.message,
          options: { variant: "error" }
        })
      );
    }
  };
}

export const deleteDaycare = deleteDaycareThunk;

export const _deleteDaycareAction = createAction<Daycare>(
  DaycareActions.DELETE
);
