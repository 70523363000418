import React from "react";
import { DateRangeType, ScheduledServiceCreation, ServiceReference } from "../../model/ScheduledService";
import { Field, FormikProps } from "formik";
import {
  FormControlLabel,
  FormLabel,
  Grid,
  Radio
  } from "@material-ui/core";
import { RadioGroup } from "formik-material-ui";

interface ComponentProps {
  name?: string;
  disabled?: boolean;
  serviceRefs: ServiceReference[];
}

type Props = ComponentProps &
  FormikProps<DateRangeType & Partial<ScheduledServiceCreation>>;

export const ServicesSelect: React.FunctionComponent<Props> = ({
  name = "serviceid",
  disabled = false,
  values,
  serviceRefs = []
}) => {
  return (
    <Grid item xs={12}>
      <FormLabel component="legend">Package</FormLabel>
      <Field value={values[name]} name={name} component={RadioGroup}>
        {serviceRefs.map((ref) => {
          return <FormControlLabel
            key={ref.packageid}
            value={ref.serviceid.toString()}
            control={<Radio />}
            disabled={disabled}
            label={ref.packagename}
          />
        })}
      </Field>
    </Grid>
  );
};
