import { EntityMap } from "./entities.reducer";
import { Reducer } from "react";
import { Action } from "redux-actions";
import { ProfileActions } from "../actions/action.constants";
import { ActionType } from "redux-promise-middleware";
import { Profile } from "../../model/Profile";

export type ProfileState = EntityMap<Profile> | undefined;

const initialState: ProfileState = {};

const handleLoadAction: Reducer<ProfileState, Action<Profile | number>> = (
  state = initialState,
  action
): ProfileState => {
  switch (action.type) {
    case `${ProfileActions.LOAD}_${ActionType.Fulfilled}`: {
      if (typeof action.payload !== "number") {
        return {
          ...state,
          ...{ [action.payload.userid]: action.payload }
        };
      }
      return state;
    }
    case ProfileActions.MAKE_EMPLOYEE: {
      if (typeof action.payload === "number") {
        const newState = { ...state };
        newState[action.payload].isEmployee = true;
        return newState;
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

export { handleLoadAction as profileReducer };
