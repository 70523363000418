import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import React from "react";
import Switch from "@material-ui/core/Switch";
import { DateRangeType, ServiceCreation } from "../../model/ScheduledService";
import { Field, FormikProps } from "formik";
import { isPuppy } from "../../utils/PetUtils";
import { Pet } from "../../model/Pet";
import { uniq } from "lodash";

interface ComponentProps {
  pets: Pet[];
  onlyPuppies?: boolean;
}

type Props = ComponentProps &
  FormikProps<DateRangeType & Partial<ServiceCreation>>;

export const PetSelect: React.FunctionComponent<Props> = ({
  pets,
  onlyPuppies = false,
  values,
  errors,
  setFieldValue
}) => {
  const handleChange = (event: any) => {
    const value: number = parseInt(event.target.value);
    const selected: boolean = event.target.checked;
    let currentSelection = values.petids || [];
    if (selected) {
      // add value to petids
      currentSelection.push(value);
      currentSelection = uniq(currentSelection);
    } else {
      // remove value from petids
      currentSelection = currentSelection.filter((id) => id !== value);
    }
    setFieldValue("petids", currentSelection, true);
  };

  const isSelected = (value: number) => {
    return values.petids ? values.petids.includes(value) : false;
  };

  const currentError = errors["petids"];

  return (
    <Grid item xs={12}>
      <FormLabel component="legend">Pets</FormLabel>
      <Field value={values.petids}>
        {() => (
          <FormControl component="fieldset">
            <FormGroup>
              {pets.map((pet) => {
                let isDisabled = false;
                if (onlyPuppies) {
                  isDisabled = !isPuppy(pet);
                }
                return (
                  <FormControlLabel
                    key={pet.petid}
                    control={
                      <Switch
                        checked={isSelected(pet.petid)}
                        value={pet.petid}
                        onChange={handleChange}
                        disabled={isDisabled}
                      />
                    }
                    label={pet.petname}
                  />
                );
              })}
            </FormGroup>
            <FormHelperText error={Boolean(currentError)}>
              {currentError}
            </FormHelperText>
          </FormControl>
        )}
      </Field>
    </Grid>
  );
};
