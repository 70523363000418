import autobind from "autobind-decorator";
import BFFProvider from "./BFFProvider";
import Contact from "./pages/AboutPage";
import EditBoardingPage from "./pages/EditBoardingPage";
import GlobalConfig from "./utils/GlobalConfig";
import history from "./history";
import Home from "./pages/Home";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Notifier from "./components/app/Notifier";
import PetPage from "./pages/PetPage";
import PrivateRoute from "./components/app/PrivateRoute";
import Profile from "./pages/ProfilePage";
import React from "react";
import ReactGA from "react-ga";
import ScheduleBoardingPage from "./pages/ScheduleBoardingPage";
import ScheduleDaycarePage from "./pages/ScheduleDaycarePage";
import SchedulePowerHourPage from "./pages/SchedulePowerHourPage";
import ScheduleWalkPage from "./pages/ScheduleWalkPage";
import { CircularProgress } from "@material-ui/core";
import { configureStore, getStore } from "./store";
import { GA_TRACKING_ID } from "./constants";
import { loadFullProfile } from "./store/actions/profile.actions";
import { Route, Router, Switch } from "react-router-dom";
import {
  AuthenticationService,
  petExecProvider,
  PetExecSession,
} from "./services/AuthenticationService";

import "./App.css";

interface State {
  isReady: boolean;
}

@autobind
class App extends React.Component<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      isReady: false,
    };
  }

  public async componentDidMount() {
    GlobalConfig.environment = process.env.NODE_ENV;

    this.initializeAnalytics();

    let session:
      | PetExecSession
      | undefined = AuthenticationService.acquireToken<PetExecSession>(
      window.location.href,
      petExecProvider
    );

    if (session) {
      window.location.replace("/");
      return;
    }

    session = AuthenticationService.restoreSession(petExecProvider);

    configureStore();

    if (session) {
      // Pre-fill store
      await getStore().dispatch<any>(loadFullProfile());
    }

    this.setState({
      isReady: true,
    });
  }

  public shouldComponentUpdate(nextProps, nextState: State) {
    return nextState.isReady;
  }

  private initializeAnalytics() {
    ReactGA.initialize(GA_TRACKING_ID);
    ReactGA.pageview(history.location.pathname);
  }

  private renderFullApp() {
    return (
      <Router history={history}>
        <BFFProvider>
          <Switch>
            <PrivateRoute exact={true} path="/profile" component={Profile} />
            <PrivateRoute exact={true} path="/contact" component={Contact} />
            <PrivateRoute exact={true} path="/add/pet" component={PetPage} />
            <PrivateRoute
              exact={true}
              path="/schedule/daycare"
              component={ScheduleDaycarePage}
            />
            <PrivateRoute
              exact={true}
              path="/schedule/walk"
              component={ScheduleWalkPage}
            />
            <PrivateRoute
              exact={true}
              path="/schedule/powerhour"
              component={SchedulePowerHourPage}
            />
            <PrivateRoute
              exact={true}
              path="/schedule/boarding"
              component={ScheduleBoardingPage}
            />
            <PrivateRoute
              exact={true}
              path="/boarding/edit/:id"
              component={EditBoardingPage}
            />
            <PrivateRoute
              exact={true}
              path={["/", "/:service/:id", "/refer"]}
              component={Home}
            />
            <Route exact={true} path="/login" component={Login} />
            <Route path="*" component={NotFound} />
          </Switch>
          <Notifier />
        </BFFProvider>
      </Router>
    );
  }

  render() {
    if (!this.state.isReady) {
      return (
        <div className="bff-loading">
          <CircularProgress />
        </div>
      );
    }
    return this.renderFullApp();
  }
}

export default App;
