import React, { useContext } from "react";
import { BFFDataResponse } from "../services/BFFDataService";

//
// BFF Data Context
//
const BFFContext = React.createContext<BFFDataResponse>({} as BFFDataResponse);
export const BFFContextProvider = BFFContext.Provider;

//
// BFF Data Hook
//
export const useBFFData = <T = BFFDataResponse>(name: string): T | null => {
  const data = useContext(BFFContext);
  if (name) {
    return data[name] as T | null;
  }
  return null;
};
