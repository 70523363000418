import reduceReducers from "reduce-reducers";
import { Action } from "redux-actions";
import { ActionType } from "redux-promise-middleware";
import { EntityMap } from "./entities.reducer";
import { mapKeys } from "lodash";
import { Reducer } from "react";
import { ScheduledService, ScheduledServicesPayload } from "../../model/ScheduledService";
import { ServicesActions } from "../actions/action.constants";

export type ScheduledServicesState = EntityMap<ScheduledService> | undefined;

const initialState: ScheduledServicesState = {};

const handleLoadAction: Reducer<
  ScheduledServicesState,
  Action<ScheduledServicesPayload>
> = (state = initialState, action): ScheduledServicesState => {
  switch (action.type) {
    case `${ServicesActions.LOAD_CALENDAR}_${ActionType.Fulfilled}`: {
      const scheduled = action.payload.scheduledServices.filter(
        (s) => "scheduledserviceid" in s
      );
      const requested = action.payload.scheduledServices.filter(
        (s) => "requestid" in s
      );
      return {
        ...state,
        ...mapKeys(scheduled, "scheduledserviceid"),
        ...mapKeys(requested, "requestid")
      };
    }
    default: {
      return state;
    }
  }
};

const handleLoadSingleAction: Reducer<
  ScheduledServicesState,
  Action<ScheduledService>
> = (state = initialState, action): ScheduledServicesState => {
  switch (action.type) {
    case `${ServicesActions.LOAD}_${ActionType.Fulfilled}`: {
      const scheduledService = action.payload;
      if (scheduledService) {
        return {
          ...state,
          ...{ [scheduledService.scheduledserviceid]: scheduledService }
        };
      } else {
        return state;
      }
    }
    default: {
      return state;
    }
  }
};

export const scheduledServicesReducer = reduceReducers(
  // @ts-ignore
  handleLoadAction,
  handleLoadSingleAction
);
