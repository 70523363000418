import BFFBreadcrumb from "../components/app/BFFBreadcrumb";
import Link from "@material-ui/core/Link";
import moment, { Moment } from "moment";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import SubmitButton from "../components/form/SubmitButton";
import Typography from "@material-ui/core/Typography";
import { AppointmentTime } from "../components/form/AppointmentTime";
import { BFF_HREF } from "../constants";
import { CircularProgress, Container, LinearProgress } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { DateRange } from "../components/form/DateRange";
import { DaysOfWeek } from "../components/form/DaysOfWeek";
import { EntityMap } from "../store/reducers/entities.reducer";
import { ErrorBox, FormBox } from "../components/form/FormBox";
import { flatMap, values } from "lodash";
import { Form, Formik, FormikErrors } from "formik";
import { Grid } from "@material-ui/core";
import { Pet } from "../model/Pet";
import { PetSelect } from "../components/form/PetSelect";
import { RootState } from "../store/reducers";
import { ScheduleType } from "../components/form/ScheduleType";
import { ServiceNotes } from "../components/form/ServiceNotes";
import { ServicesSelect } from "../components/form/ServicesSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  DateRangeType,
  ScheduledServiceCreation,
  ScheduledServiceReference,
  ServiceType,
} from "../model/ScheduledService";
import {
  loadAllServices,
  requestScheduledService,
} from "../store/actions/secheduledService.action";
import {
  validateDaysOfWeek,
  validatePets,
  validateServiceCreation,
} from "../utils/ValidationUtil";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
      margin: theme.spacing(2, 0),
      backgroundColor: theme.palette.grey[100],
    },
    grid: {
      padding: theme.spacing(3, 0),
    },
    select: {
      minWidth: 120,
    },
    fullWidth: {
      width: "100%",
    },
    link: {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  })
);

type WalkCreationForm = Partial<ScheduledServiceCreation> & DateRangeType;

const ScheduleWalkPage: React.FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [pets, setPets] = useState([] as Pet[]);

  const initialDate = useSelector<RootState, Moment>(({ app }) => {
    // Default to tomorrow unless date was previously selected
    let selectedDate = moment().add(1, "day");
    if (app.selectedDate) {
      const appDate = moment(app.selectedDate);
      if (appDate.isAfter(moment(), "day")) {
        selectedDate = appDate;
      }
    }
    return selectedDate;
  });

  const servicesLoading = useSelector<RootState, boolean>(({ app }) => {
    return app.servicesLoading;
  });

  const walkServices = useSelector<RootState, ScheduledServiceReference[]>(
    ({ auth, entities }) => {
      if (entities.scheduledServiceTypes && entities.serviceTypes) {
        const flattenedServiceTypes = flatMap<
          EntityMap<ServiceType[]>,
          ServiceType & { stid: number }
        >(entities.serviceTypes, (services = [], stid) => {
          return services.map((service) => ({
            ...service,
            stid: parseInt(stid),
          }));
        });
        // compare service types against active walk packages
        if (entities.packages.other) {
          const otherPackages = entities.packages.other[auth.userid];
          if (otherPackages) {
            return otherPackages.reduce<ScheduledServiceReference[]>(
              (accumulator, other) => {
                const associatedService = flattenedServiceTypes.find(
                  (s) => s.serviceid === other.associatedserviceid
                );
                if (associatedService) {
                  accumulator.push({
                    ...associatedService,
                    packagename: other.servicename,
                    packageid: other.packageid,
                    serviceid: other.serviceid,
                  });
                }
                return accumulator;
              },
              []
            );
          }
        }
      }
      return [];
    }
  );

  const petMap = useSelector<RootState, EntityMap<Pet> | null>(
    ({ entities }) => {
      if (entities && entities.pets) {
        return entities.pets;
      }
      return null;
    }
  );

  useEffect(() => {
    dispatch(loadAllServices());
  }, [dispatch]);

  useEffect(() => {
    if (petMap) {
      setPets(values(petMap));
    }
  }, [petMap]);

  if (servicesLoading || pets.length === 0) {
    return (
      <div className="bff-loading">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <BFFBreadcrumb pageName="Request Walk" />
      <Container maxWidth="lg">
        <Paper className={classes.root}>
          <Typography variant="h4" gutterBottom>
            Request Walk
          </Typography>
          {walkServices.length === 0 && (
            <ErrorBox>
              Oops, it doesn't look like you have any have any passes left.
              Please{" "}
              <Link
                className={classes.link}
                href={BFF_HREF.shop}
                target="_blank"
              >
                visit the BFF Shop
              </Link>{" "}
              to choose your package.
            </ErrorBox>
          )}
          {walkServices.length > 0 && (
            <Formik<WalkCreationForm>
              initialValues={{
                type: "single",
                startDate: initialDate,
                endDate: initialDate,
                serviceid: walkServices.length
                  ? walkServices[0].serviceid.toString()
                  : undefined,
                appointmentWindow: "morning",
                petids: pets[0] ? [pets[0].petid] : [],
              }}
              validate={(values: WalkCreationForm) => {
                const errors: FormikErrors<WalkCreationForm> = {};
                validateServiceCreation(values, errors);
                validateDaysOfWeek(values, errors);
                validatePets(values, errors);
                return errors;
              }}
              onSubmit={(values: WalkCreationForm, { setSubmitting }) => {
                if (values.type === "single") {
                  values.endDate = values.startDate;
                }
                values.serviceRef = walkServices.find(
                  (service) =>
                    service.serviceid === parseInt(values.serviceid || "0")
                );
                ReactGA.event({
                  category: "Walk",
                  action: "Submit Request",
                });
                //dispatch walk creation action
                dispatch(requestScheduledService(values, "Walk"));
                setTimeout(() => {
                  setSubmitting(false);
                }, 500);
              }}
            >
              {(formProps) => {
                const { submitForm, isSubmitting } = formProps;
                return (
                  <Form>
                    <FormBox>
                      <Grid container spacing={3} className={classes.grid}>
                        <ScheduleType {...formProps} />
                        <DateRange {...formProps} allowWeekend={true} />
                      </Grid>
                    </FormBox>
                    <FormBox>
                      <Grid container spacing={3} className={classes.grid}>
                        <AppointmentTime />
                        <DaysOfWeek {...formProps} showWeekend={true} />
                      </Grid>
                    </FormBox>
                    <FormBox>
                      <Grid container spacing={3} className={classes.grid}>
                        <ServicesSelect
                          {...formProps}
                          serviceRefs={walkServices}
                        />
                        <PetSelect {...formProps} pets={pets} />
                        <ServiceNotes />
                        <Grid item xs={12}>
                          {isSubmitting && <LinearProgress />}
                        </Grid>
                      </Grid>
                    </FormBox>
                    <SubmitButton
                      isSubmitting={isSubmitting}
                      onSubmit={submitForm}
                    />
                  </Form>
                );
              }}
            </Formik>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default ScheduleWalkPage;
