import AbstractPetService, { PetExecResponse } from "./AbstractPetService";
import { PetExec } from "../constants";
import { TokenReplacer } from "../utils/TokenReplacer";
import { PackagePayload, Package, Service } from "../model/Package";

interface PackageResponse extends PetExecResponse {
  packages: Package[];
}

interface ServiceResponse extends PetExecResponse {
  packages: Service[];
}

class PackageService extends AbstractPetService {
  private async loadPackage(
    userid: number,
    type: string
  ): Promise<PackagePayload> {
    const endpoint = `${PetExec.API_URL}/package/{type}/owner/{userid}`;
    const url = TokenReplacer.replace(endpoint, { type, userid });
    const response = await this.fetchJson<PackageResponse>(url);
    if (response && response.success) {
      return { userid, packages: response.packages || [] } as PackagePayload;
    }
    return { userid, packages: [] };
  }

  public async loadCompanyPackages(): Promise<Service[]> {
    const endpoint = `${PetExec.API_URL}/package`;
    const response = await this.fetchJson<ServiceResponse>(endpoint);
    if (response && response.success) {
      return (response.packages || []) as Service[];
    }
    return [];
  }

  public async loadDaycare(userid: number): Promise<PackagePayload> {
    return this.loadPackage(userid, "daycare");
  }

  public async loadOther(userid: number): Promise<PackagePayload> {
    return this.loadPackage(userid, "other");
  }
}

export default PackageService;
