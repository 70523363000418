import BFFBreadcrumb from "../components/app/BFFBreadcrumb";
import moment, { Moment } from "moment";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import SubmitButton from "../components/form/SubmitButton";
import Typography from "@material-ui/core/Typography";
import { CircularProgress, Container, LinearProgress } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { DateRange } from "../components/form/DateRange";
import { EntityMap } from "../store/reducers/entities.reducer";
import { ErrorBox, FormBox } from "../components/form/FormBox";
import { flatMap, isEmpty, values } from "lodash";
import { Form, Formik, FormikErrors } from "formik";
import { Grid } from "@material-ui/core";
import { isPuppy } from "../utils/PetUtils";
import { Link as RouterLink } from "react-router-dom";
import { Pet } from "../model/Pet";
import { PetSelect } from "../components/form/PetSelect";
import { PowerHour } from "../model/Company";
import { PUPPY_POWER_HOUR_ID } from "../constants";
import { RootState } from "../store/reducers";
import { ServiceNotes } from "../components/form/ServiceNotes";
import { useBFFData } from "../hooks/useBFFData";
import { useDispatch, useSelector } from "react-redux";
import {
  DateRangeType,
  ScheduledServiceCreation,
  ServiceType,
} from "../model/ScheduledService";
import {
  loadAllServices,
  requestScheduledService,
} from "../store/actions/secheduledService.action";
import {
  validateDaysOfWeek,
  validatePets,
  validateServiceCreation,
} from "../utils/ValidationUtil";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
      margin: theme.spacing(2, 0),
      backgroundColor: theme.palette.grey[100],
    },
    grid: {
      padding: theme.spacing(3, 0),
    },
    select: {
      minWidth: 120,
    },
    fullWidth: {
      width: "100%",
    },
    link: {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  })
);

type PowerHourCreationForm = Partial<ScheduledServiceCreation> & DateRangeType;

const SchedulePowerHourPage: React.FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const powerhour = useBFFData<PowerHour>("powerhour");
  const [pets, setPets] = useState([] as Pet[]);
  const [hasValidPet, setHasValidPet] = useState<boolean>(true);

  const TUESDAY = 2;
  const THURSDAY = 4;

  const initialDate = useSelector<RootState, Moment>(({ app }) => {
    // Default to next Thursday after today or selected date
    let desiredDate = moment();
    if (app.selectedDate) {
      const appDate = moment(app.selectedDate);
      if (appDate.isAfter(moment(), "day")) {
        desiredDate = appDate;
      }
    }
    // if we haven't yet passed the day of the week that I need:
    if (desiredDate.isoWeekday() <= TUESDAY) {
      // use this week's TUESDAY
      return desiredDate.isoWeekday(TUESDAY);
    } else if (desiredDate.isoWeekday() <= THURSDAY) {
      // use this week's THURSDAY
      return desiredDate.isoWeekday(THURSDAY);
    } else {
      // otherwise, give me next week's instance of that same day
      return desiredDate.add(1, "weeks").isoWeekday(TUESDAY);
    }
  });

  const servicesLoading = useSelector<RootState, boolean>(({ app }) => {
    return app.servicesLoading;
  });

  const powerHourServices = useSelector<
    RootState,
    Array<ServiceType & { stid: number }>
  >(({ entities }) => {
    if (
      !isEmpty(entities.scheduledServiceTypes) &&
      !isEmpty(entities.serviceTypes)
    ) {
      const flattenedServiceTypes = flatMap<
        EntityMap<ServiceType[]>,
        ServiceType & { stid: number }
      >(entities.serviceTypes, (services = [], stid) => {
        return services.map((service) => ({
          ...service,
          stid: parseInt(stid),
        }));
      });
      return flattenedServiceTypes.filter(
        (service) => service.serviceid === PUPPY_POWER_HOUR_ID
      );
    }

    return [];
  });

  const petMap = useSelector<RootState, EntityMap<Pet> | null>(
    ({ entities }) => {
      if (entities && entities.pets) {
        return entities.pets;
      }
      return null;
    }
  );

  useEffect(() => {
    dispatch(loadAllServices());
  }, [dispatch]);

  useEffect(() => {
    if (petMap) {
      const allPets = values(petMap);
      setHasValidPet(allPets.some((pet) => isPuppy(pet)));
      setPets(allPets);
    }
  }, [petMap]);

  if (servicesLoading || pets.length === 0) {
    return (
      <div className="bff-loading">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <BFFBreadcrumb pageName="Puppy Power Hour" />
      <Container maxWidth="lg">
        <Paper className={classes.root}>
          <Typography variant="h4" gutterBottom>
            Puppy Power Hour
          </Typography>
          <FormBox variant="secondary">
            {powerhour != null && (
              <>
                <Typography variant="h5" gutterBottom>
                  {powerhour.title}
                </Typography>
                <Typography variant="body1" component="span" gutterBottom>
                  {powerhour.subtitle}
                  <ul>
                    {powerhour.details.map((d, index) => (
                      <li key={index}>{d}</li>
                    ))}
                  </ul>
                </Typography>
              </>
            )}
          </FormBox>
          {powerHourServices.length === 0 && (
            <ErrorBox>
              Puppy Power Hour is not currently available. Please{" "}
              <RouterLink className={classes.link} to="/contact">
                contact BFF
              </RouterLink>{" "}
              for further details.
            </ErrorBox>
          )}
          {!hasValidPet && (
            <ErrorBox>
              Sorry, you do not have any pups that are eligible for puppy power
              hour.
            </ErrorBox>
          )}
          {hasValidPet && powerHourServices.length > 0 && (
            <Formik<PowerHourCreationForm>
              initialValues={{
                type: "single",
                startDate: initialDate,
                endDate: initialDate,
                serviceid: powerHourServices.length
                  ? powerHourServices[0].serviceid.toString()
                  : undefined,
                petids: pets[0] ? [pets[0].petid] : [],
              }}
              validate={(values: PowerHourCreationForm) => {
                const errors: FormikErrors<PowerHourCreationForm> = {};
                validateServiceCreation(values, errors);
                validateDaysOfWeek(values, errors);
                validatePets(values, errors);
                return errors;
              }}
              onSubmit={(values: PowerHourCreationForm, { setSubmitting }) => {
                if (values.type === "single") {
                  values.endDate = values.startDate;
                }
                values.serviceRef = powerHourServices.find(
                  (service) =>
                    service.serviceid === parseInt(values.serviceid || "0")
                );
                values.appointmentTime = "7:30 PM";
                ReactGA.event({
                  category: "Puppy Power Hour",
                  action: "Submit Request",
                });
                //dispatch walk creation action
                dispatch(requestScheduledService(values, "Puppy Power Hour"));
                setTimeout(() => {
                  setSubmitting(false);
                }, 500);
              }}
            >
              {(formProps) => {
                const { submitForm, isSubmitting } = formProps;
                return (
                  <Form>
                    <FormBox>
                      <Grid container spacing={3} className={classes.grid}>
                        <DateRange {...formProps} allowedDays={[2, 4]} />
                        <PetSelect
                          {...formProps}
                          pets={pets}
                          onlyPuppies={true}
                        />
                        <ServiceNotes />
                        <Grid item xs={12}>
                          {isSubmitting && <LinearProgress />}
                        </Grid>
                      </Grid>
                    </FormBox>
                    <SubmitButton
                      isSubmitting={isSubmitting}
                      onSubmit={submitForm}
                    />
                  </Form>
                );
              }}
            </Formik>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default SchedulePowerHourPage;
