import React from "react";
import TodayIcon from "@material-ui/icons/Today";
import { DATE_LONG_FORMAT } from "../../constants";
import { DatePicker } from "@material-ui/pickers";
import { FieldProps } from "formik";
import { Icon, InputAdornment } from "@material-ui/core";
import { isMatchingDay, isWeekend } from "../../utils/CalendarUtils";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

interface ComponentProps {
  allowWeekend?: boolean;
  allowedDays?: number[];
  allowPast?: boolean;
}

type Props = ComponentProps & FieldProps;

export const DatePickerField: React.FunctionComponent<Props> = ({
  field,
  form,
  allowWeekend = false,
  allowedDays = [],
  allowPast = false,
  ...other
}) => {
  const currentError = form.errors[field.name];

  const handleDateDisable = (date: MaterialUiPickersDate) => {
    if (allowedDays.length > 0) {
      return !isMatchingDay(date, allowedDays);
    }
    if (allowWeekend) {
      return false;
    } else {
      return isWeekend(date);
    }
  };

  return (
    <DatePicker
      clearable={false}
      disablePast={!allowPast}
      autoOk
      name={field.name}
      value={field.value}
      format={DATE_LONG_FORMAT}
      helperText={currentError}
      error={Boolean(currentError)}
      shouldDisableDate={handleDateDisable}
      onChange={(date) => form.setFieldValue(field.name, date, false)}
      InputProps={{
        endAdornment: (
          <InputAdornment position={"end"}>
            <Icon>
              <TodayIcon />
            </Icon>
          </InputAdornment>
        ),
      }}
      {...other}
    />
  );
};
