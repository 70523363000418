import { Moment } from "moment";
import { UserReference } from "./Profile";

export interface PetReference {
  petid: number;
}

export interface Pet extends UserReference, PetReference {
  petname?: string;
  pathtopic?: string;
  encodedpic?: string;
  breedname?: string;
  description?: string;
  gender?: string;
  birthdate?: string;
  isUploading?: boolean;
}

export interface PetPayload extends UserReference {
  pets: Pet[];
}

export const EMPTY_PET: Pet = {
  userid: 0,
  petid: 0,
  petname: "",
};

export interface PetType {
  ptid: number;
  pettype: string;
  defaulttype: 0 | 1;
}

export interface PetBreed {
  breedid: number;
  ptid: number;
  breedname: string;
}

export interface Vet {
  vetid: number;
  vetname: string;
  companyname: string;
}

export interface PetCreation {
  petName: string;
  ptid: number;
  breedid: number;
  birthDate: string | Moment;
  authorizedToPickup: string;
  gender: "Male" | "Female";
  neuteredState: "Unknown" | "Not fixed" | "Neutered" | "Spayed";
  vetid: number;
  shots: { [key: number]: Moment };
}

export interface Shot {
  shotid: number;
  shotname: string;
  frequency: string;
  isRequired: "0" | "1";
  ptid: number;
}
