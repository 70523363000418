import { EntityMap } from "./entities.reducer";
import { Reducer } from "react";
import { Action } from "redux-actions";
import { CompanyActions } from "../actions/action.constants";
import { ActionType } from "redux-promise-middleware";
import { mapKeys } from "lodash";
import { Employee } from "../../model/Company";

export type EmployeeState = EntityMap<Employee> | undefined;

const handleLoadEmployeesAction: Reducer<
  EmployeeState,
  Action<ReadonlyArray<Employee>>
> = (state = {}, action): EmployeeState => {
  switch (action.type) {
    case `${CompanyActions.LOAD_EMPLOYEES}_${ActionType.Fulfilled}`: {
      return {
        ...state,
        ...mapKeys(action.payload, "employeeid")
      };
    }
    default: {
      return state;
    }
  }
};

export { handleLoadEmployeesAction as employeeReducer };
