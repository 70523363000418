import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import HomeIcon from "@material-ui/icons/Home";
import Link, { LinkProps } from "@material-ui/core/Link";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1, 0),
    },
    link: {
      display: "flex",
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
  })
);

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}
const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
);

export interface PathSpec {
  name: string;
  path: string;
}

interface Props {
  pageName: string;
  paths?: PathSpec[];
}

const BFFBreadcrumb: React.FunctionComponent<Props> = ({
  pageName,
  paths = [],
}) => {
  const classes = useStyles({});

  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.root}>
      <LinkRouter color="inherit" to="/" className={classes.link}>
        <HomeIcon className={classes.icon} />
        Home
      </LinkRouter>
      {paths.map((spec) => (
        <LinkRouter
          key={spec.path}
          color="inherit"
          to={spec.path}
          className={classes.link}
        >
          {spec.name}
        </LinkRouter>
      ))}
      <Typography color="textPrimary" className={classes.link}>
        {pageName}
      </Typography>
    </Breadcrumbs>
  );
};

export default BFFBreadcrumb;
