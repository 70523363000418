import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { DatePickerField } from "./DatePickerField";
import { DateRangeType, ServiceCreation } from "../../model/ScheduledService";
import { Field, FormikProps } from "formik";
import { FormLabel, Theme } from "@material-ui/core";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dateField: {
      paddingRight: 8
    }
  })
);

interface ComponentProps {
  allowWeekend?: boolean;
  allowedDays?: number[];
}

type Props = ComponentProps &
  FormikProps<DateRangeType & Partial<ServiceCreation>>;

export const DateRange: React.FunctionComponent<Props> = ({
  values,
  allowWeekend,
  allowedDays,
  setFieldValue
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} sm={4} md={3}>
        <FormLabel component="legend">
          {values.type === "multiple" ? "Start Date" : "Date"}
        </FormLabel>
        <Field
          name="startDate"
          className={classes.dateField}
          allowWeekend={allowWeekend}
          allowedDays={allowedDays}
          component={DatePickerField}
          onChange={(value) => {
            setFieldValue("startDate", value, true);
            if (values.endDate && typeof values.endDate !== "string") {
              if (values.endDate.isBefore(value)) {
                setFieldValue("endDate", value, true);
              }
            }
          }}
        />
      </Grid>
      {values.type === "multiple" && (
        <Grid item xs={12} sm={4} md={3}>
          <FormLabel component="legend">End Date</FormLabel>
          <Field
            name="endDate"
            className={classes.dateField}
            allowWeekend={allowWeekend}
            component={DatePickerField}
            onChange={(value) => {
              setFieldValue("endDate", value, true);
            }}
          />
        </Grid>
      )}
    </>
  );
};
