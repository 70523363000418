import { Boarding } from "../model/Boarding";
import { createSelector } from "reselect";
import { Daycare } from "../model/Daycare";
import { RootState } from "../store/reducers";
import { ScheduledService } from "../model/ScheduledService";
import { values } from "lodash";

const getDaycares = (state: RootState): ReadonlyArray<Daycare> =>
  (state.entities.calendar.daycares
    ? values(state.entities.calendar.daycares)
    : undefined) || [];

const getScheduledServices = (
  state: RootState
): ReadonlyArray<ScheduledService> =>
  (state.entities.calendar.scheduledServices
    ? values(state.entities.calendar.scheduledServices)
    : undefined) || [];

const getBoardings = (state: RootState): ReadonlyArray<Boarding> =>
  (state.entities.calendar.boardings
    ? values(state.entities.calendar.boardings)
    : undefined) || [];

export const getCalendarItems = createSelector(
  [getDaycares, getScheduledServices, getBoardings],
  (daycares, services, boardings) => {
    return [...daycares, ...services, ...boardings];
  }
);
