import { Action } from "redux-actions";
import { ActionType } from "redux-promise-middleware";
import { DaycareActions } from "../actions/action.constants";
import { EntityMap } from "./entities.reducer";
import { mapKeys } from "lodash";
import { Reducer } from "react";
import { ServiceType } from "../../model/ScheduledService";

export type DaycareServiceState = EntityMap<ServiceType> | undefined;

const handleLoadDaycareServicesAction: Reducer<
  DaycareServiceState,
  Action<ServiceType[]>
> = (state = {}, action): DaycareServiceState => {
  switch (action.type) {
    case `${DaycareActions.LOAD_SERVICES}_${ActionType.Fulfilled}`: {
      return {
        ...state,
        ...mapKeys(action.payload, "serviceid")
      };
    }
    default: {
      return state;
    }
  }
};

export { handleLoadDaycareServicesAction as daycareServicesReducer };
