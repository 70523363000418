import { Action } from "redux-actions";
import { ActionType } from "redux-promise-middleware";
import { CompanyActions } from "../actions/action.constants";
import { EntityMap } from "./entities.reducer";
import { Location } from "../../model/Company";
import { mapKeys } from "lodash";
import { Reducer } from "react";

export type CompanyLocationState = EntityMap<Location> | undefined;

const handleLoadLocationsAction: Reducer<
  CompanyLocationState,
  Action<ReadonlyArray<Location>>
> = (state = {}, action): CompanyLocationState => {
  switch (action.type) {
    case `${CompanyActions.LOAD_LOCATIONS}_${ActionType.Fulfilled}`: {
      return {
        ...state,
        ...mapKeys(action.payload, "locationid")
      };
    }
    default: {
      return state;
    }
  }
};

export { handleLoadLocationsAction as companyLocationReducer };
