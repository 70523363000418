import { Boarding, BoardingAddOn, BoardingCreation } from "../model/Boarding";
import { CompanyInstructionsResponse } from "./CompanyService";
import { PetExec } from "../constants";
import { ServiceType } from "../model/ScheduledService";
import { TokenReplacer } from "../utils/TokenReplacer";
import AbstractPetService, {
  CreationResponse,
  PetExecResponse,
} from "./AbstractPetService";

interface BoardingResponse extends PetExecResponse {
  boarding: Boarding;
}

interface BoardingServicesResponse extends PetExecResponse {
  boardingservices: ServiceType[];
  lunchservices: ServiceType[];
}

interface BoardingAddonsResponse extends PetExecResponse {
  addons: BoardingAddOn[];
}

class BoardingService extends AbstractPetService {
  private static BOARDING_PATH = "/boarding";

  public async loadInstructions(): Promise<string> {
    const endpoint = `${PetExec.API_URL}${BoardingService.BOARDING_PATH}/company/instructions`;
    const response = await this.fetchJson<CompanyInstructionsResponse>(
      endpoint
    );
    return response.instructions;
  }

  public async loadBoarding(boardingid: number): Promise<Boarding> {
    const endpoint = `${PetExec.API_URL}${BoardingService.BOARDING_PATH}/{boardingid}`;
    const url = TokenReplacer.replace(endpoint, {
      boardingid,
    });
    const response = await this.fetchJson<BoardingResponse>(url);
    return response.boarding;
  }

  public async loadBoardingServices(): Promise<ServiceType[]> {
    const endpoint = `${PetExec.API_URL}${BoardingService.BOARDING_PATH}/services`;
    const response = await this.fetchJson<BoardingServicesResponse>(endpoint);
    return response.boardingservices;
  }

  public async loadBoardingAddons(): Promise<BoardingAddOn[]> {
    const endpoint = `${PetExec.API_URL}${BoardingService.BOARDING_PATH}/add-ons`;
    const response = await this.fetchJson<BoardingAddonsResponse>(endpoint);
    return response.addons;
  }

  public async addBoarding(
    boarding: BoardingCreation
  ): Promise<CreationResponse> {
    const url = `${PetExec.API_URL}${BoardingService.BOARDING_PATH}/schedule`;

    const boardingData = new FormData();

    if (boarding.startDate && typeof boarding.startDate !== "string") {
      boardingData.append(
        "startDate",
        boarding.startDate.format(PetExec.API_DATE_FORMAT)
      );
    }
    if (boarding.endDate && typeof boarding.endDate !== "string") {
      boardingData.append(
        "endDate",
        boarding.endDate.format(PetExec.API_DATE_FORMAT)
      );
    }
    boardingData.append("petids", boarding.petids.join(","));
    boardingData.append("services", boarding.serviceid);

    boardingData.append("numKennels", "1");
    boardingData.append(`kennels_${boarding.serviceid}`, "1");

    boardingData.append("signInTime", boarding.signInTime);
    boardingData.append("signOutTime", boarding.signOutTime);

    boardingData.append("addons", boarding.addonids.join(","));
    boarding.addonids.forEach((id) => {
      boardingData.append(`addon_qty_${id}`, "1");
    });
    boardingData.append("addonNotes", boarding.addonNotes);

    boardingData.append("emergencyContact", boarding.emergencyContact);
    boardingData.append("emergencyNumber", boarding.emergencyNumber);
    boardingData.append("notes", boarding.notes);

    return await this.fetchJson<CreationResponse>(url, {
      method: "POST",
      body: boardingData,
    });
  }
}

export default BoardingService;
