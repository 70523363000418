import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import TextField from "@material-ui/core/TextField";
import { FieldProps } from "formik";
import { isEmpty } from "lodash";

interface ComponentProps {
  items: any[];
  labelName: string;
}

type Props = ComponentProps & FieldProps;

export const ComboBoxField: React.FunctionComponent<Props> = ({
  field,
  form,
  items = [],
  labelName,
  ...other
}) => {
  const getOptionsLabel = (option) => {
    if (typeof option === "number") {
      option = items.find((item) => item[field.name] === option);
    }
    if (typeof option === "object" && labelName in option) {
      return option[labelName];
    }
    return "";
  };

  const getOptionSelected = (option, value) => {
    if (typeof value === "number") {
      return option[field.name] === value;
    }
    return option === value;
  };

  const handleChange = (event, option) => {
    if (!isEmpty(option)) {
      form.setFieldValue(field.name, option[field.name], false);
    }
  };

  return (
    <Autocomplete
      {...other}
      options={items}
      getOptionLabel={getOptionsLabel}
      getOptionSelected={getOptionSelected}
      renderInput={(params) => <TextField {...params} />}
      onChange={handleChange}
      blurOnSelect={true}
    />
  );
};
