import AbstractPetService, { PetExecResponse } from "./AbstractPetService";
import moment, { Moment } from "moment";
import { Boarding, BoardingPayload } from "../model/Boarding";
import { CalendarItem } from "../model/Calendar";
import { Daycare, DaycarePayload } from "../model/Daycare";
import { PetExec } from "../constants";
import { ScheduledService, ScheduledServicesPayload } from "../model/ScheduledService";
import { TokenReplacer } from "../utils/TokenReplacer";

interface CalendarResponse<T> extends PetExecResponse {
  calendar: T[];
}

export const DATE_FORMAT: string = "MM/DD/YYYY hh:mm a";

class CalendarService extends AbstractPetService {
  public static isDaycareItem(item: CalendarItem): item is Daycare {
    return (item as Daycare).daycareid !== undefined;
  }

  public static isServicesItem(item: CalendarItem): item is ScheduledService {
    const service = item as ScheduledService;
    return (
      service.scheduledserviceid !== undefined ||
      service.requestid !== undefined
    );
  }

  public static isBoardingItem(item: CalendarItem): item is Boarding {
    return (item as Boarding).boardingid !== undefined;
  }

  public static hasCalendarItem(
    date: Date,
    items: ReadonlyArray<CalendarItem>
  ) {
    if (items === undefined) {
      return false;
    }
    return items.some((item) =>
      CalendarService.matchesCalendarItem(date, item)
    );
  }

  public static getCalendarItemDate(
    item: CalendarItem,
    useEnd?: boolean
  ): Moment {
    if (useEnd) {
      return moment(item.end, DATE_FORMAT).startOf("day");
    } else {
      return moment(item.start, DATE_FORMAT).startOf("day");
    }
  }

  public static matchesCalendarItem(date: Date, item: CalendarItem) {
    // does item date range include date
    const startdate = CalendarService.getCalendarItemDate(item);
    return moment(date).isSame(startdate, "day");
  }

  public static getMatchingCalendarItems(
    date: Date,
    items: ReadonlyArray<CalendarItem>
  ): ReadonlyArray<CalendarItem> {
    if (!items) {
      return [];
    }
    return items.filter((item) =>
      CalendarService.matchesCalendarItem(date, item)
    );
  }

  public static isCalendarItemAfter(date: Date, item: CalendarItem) {
    // is item date range after date
    const startdate = moment(item.start, DATE_FORMAT).startOf("day");
    return startdate.isAfter(moment(date), "day");
  }

  public async loadDaycares(
    userid: number,
    startdate: string,
    enddate: string
  ): Promise<DaycarePayload> {
    const endpoint = `${PetExec.API_URL}/calendar/daycare/owner/{userid}/start/{startdate}/end/{enddate}`;
    const url = TokenReplacer.replace(endpoint, {
      userid,
      startdate,
      enddate
    });
    const response = await this.fetchJson<CalendarResponse<Daycare>>(url);
    return { userid, daycares: response.calendar || [] } as DaycarePayload;
  }

  public async loadScheduledServices(
    userid: number,
    startdate: string,
    enddate: string
  ): Promise<ScheduledServicesPayload> {
    const endpoint = `${PetExec.API_URL}/calendar/scheduled-service/owner/{userid}/start/{startdate}/end/{enddate}`;
    const url = TokenReplacer.replace(endpoint, {
      userid,
      startdate,
      enddate
    });
    const response = await this.fetchJson<CalendarResponse<ScheduledService>>(
      url
    );
    return {
      userid,
      scheduledServices: response.calendar || []
    } as ScheduledServicesPayload;
  }

  public async loadBoardings(
    userid: number,
    startdate: string,
    enddate: string
  ): Promise<BoardingPayload> {
    const endpoint = `${PetExec.API_URL}/calendar/boarding/owner/{userid}/start/{startdate}/end/{enddate}`;
    const url = TokenReplacer.replace(endpoint, {
      userid,
      startdate,
      enddate
    });
    const response = await this.fetchJson<CalendarResponse<Boarding>>(url);
    return {
      userid,
      boardings: response.calendar || []
    } as BoardingPayload;
  }
}

export default CalendarService;
