import CompanyService from "../../services/CompanyService";
import {
  Agreement,
  AppMessage,
  Employee,
  Location,
  OwnerMessage
  } from "../../model/Company";
import { CompanyActions } from "./action.constants";
import { createAction } from "redux-actions";

function loadAppMessageAction(): Promise<AppMessage> {
  const service = new CompanyService();
  return service.loadOwnerAppMessage();
}

export const loadAppMessage = createAction<Promise<AppMessage>>(
  CompanyActions.LOAD_APP_MESSAGE,
  loadAppMessageAction
);

function loadOwnerMessageAction(): Promise<OwnerMessage> {
  const service = new CompanyService();
  return service.loadOwnerMessage();
}

export const loadOwnerMessage = createAction<Promise<OwnerMessage>>(
  CompanyActions.LOAD_OWNER_MESSAGE,
  loadOwnerMessageAction
);

function loadAppImagesAction(): Promise<any[]> {
  const service = new CompanyService();
  return service.loadAppImages();
}

export const loadAppImages = createAction<Promise<any[]>>(
  CompanyActions.LOAD_APP_IMAGES,
  loadAppImagesAction
);

function loadEmployeesAction(): Promise<Employee[]> {
  const service = new CompanyService();
  return service.loadEmployees();
}

export const loadEmployees = createAction<Promise<Employee[]>>(
  CompanyActions.LOAD_EMPLOYEES,
  loadEmployeesAction
);

function loadLocationsAction(): Promise<Location[]> {
  const service = new CompanyService();
  return service.loadLocations();
}

export const loadLocations = createAction<Promise<Location[]>>(
  CompanyActions.LOAD_LOCATIONS,
  loadLocationsAction
);

function loadSignedAgreementsAction(): Promise<Agreement[]> {
  const service = new CompanyService();
  return service.loadSignedAgreements();
}

export const loadSignedAgreements = createAction<Promise<Agreement[]>>(
  CompanyActions.LOAD_SIGNED_AGREEMENTS,
  loadSignedAgreementsAction
);

function sendEmailAction(subject: string, message: string): Promise<void> {
  const service = new CompanyService();
  return service.sendEmail(subject, message);
}

export const sendEmail = createAction<Promise<void>, string, string>(
  CompanyActions.SEND_EMAIL,
  sendEmailAction
);
