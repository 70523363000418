import React from "react";
import { Field } from "formik";
import { FormControlLabel, Radio } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { RadioGroup } from "formik-material-ui";

interface Props {
  isSubmitting: boolean;
}

export const ScheduleType: React.FunctionComponent<Props> = ({
  isSubmitting
}) => {
  return (
    <Grid item xs={12}>
      <Field name="type" component={RadioGroup}>
        <FormControlLabel
          value="single"
          control={<Radio disabled={isSubmitting} />}
          label="Single day"
          disabled={isSubmitting}
        />
        <FormControlLabel
          value="multiple"
          control={<Radio disabled={isSubmitting} />}
          label="Multiple days"
          disabled={isSubmitting}
        />
      </Field>
    </Grid>
  );
};
