/*
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2019 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface ComponentProps {
  errorElement: React.ReactChild;
}

interface ComponentState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ComponentProps & RouteComponentProps,
  ComponentState
> {
  private historyUnlisten;

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidMount(): void {
    // reset error state on history change
    const { history } = this.props;
    this.historyUnlisten = history.listen((location, action) => {
      if (this.state.hasError) {
        this.setState({
          hasError: false
        });
      }
    });
  }

  componentWillUnmount(): void {
    this.historyUnlisten();
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (console) {
      console.warn("ErrorBoundary Caught error: \n", error, "\n", errorInfo);
    }
  }

  render() {
    if (this.state.hasError) {
      return this.props.errorElement;
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
