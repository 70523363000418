import { Action } from "redux-actions";
import { EntityMap } from "./entities.reducer";
import { mapKeys } from "lodash";
import { PetActions } from "../actions/action.constants";
import { PetType } from "../../model/Pet";
import { Reducer } from "redux";

export type PetTypeState = EntityMap<PetType> | undefined;

const initialState: PetTypeState = {};

const handleAddAction: Reducer<PetTypeState, Action<PetType[]>> = (
  state = initialState,
  action
): PetTypeState => {
  switch (action.type) {
    case PetActions.ADD_TYPES: {
      return {
        ...state,
        ...mapKeys(action.payload, "ptid"),
      };
    }
    default: {
      return state;
    }
  }
};

export { handleAddAction as petTypeReducer };
