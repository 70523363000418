import { Action } from "redux-actions";
import { ActionType } from "redux-promise-middleware";
import { BoardingActions } from "../actions/action.constants";
import { EntityMap } from "./entities.reducer";
import { mapKeys } from "lodash";
import { Reducer } from "react";
import { ServiceType } from "../../model/ScheduledService";

export type BoardingServiceState = EntityMap<ServiceType> | undefined;

const handleLoadBoardingServicesAction: Reducer<
  BoardingServiceState,
  Action<ServiceType[]>
> = (state = {}, action): BoardingServiceState => {
  switch (action.type) {
    case `${BoardingActions.LOAD_SERVICES}_${ActionType.Fulfilled}`: {
      return {
        ...state,
        ...mapKeys(action.payload, "serviceid"),
      };
    }
    default: {
      return state;
    }
  }
};

export { handleLoadBoardingServicesAction as boardingServicesReducer };
