import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  logo: {
    margin: theme.spacing(0, 0, 8)
  }
}));

const NotFound: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <img
          src="/images/bff-logo-t.png"
          alt="BFF Logo"
          className={classes.logo}
        />
        <Typography variant="h4" color="textSecondary" align="center">
          Something doesn't look right. You probably should head back to the{" "}
          <Link to="/">dashboard</Link>.
        </Typography>
      </div>
    </Container>
  );
};

export default NotFound;
