import BFFPage from "./BFFPage";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import MomentUtils from "@date-io/moment";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { BFFContextProvider } from "./hooks/useBFFData";
import { bffDataFactory, BFFDataResponse } from "./services/BFFDataService";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { getStore } from "./store";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Provider as ReduxProvider } from "react-redux";
import { ScrollToTop } from "./components/ScrollToTop";
import { SnackbarProvider } from "notistack";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    close: {
      padding: theme.spacing(0.5),
    },
  })
);

const BFFProvider: React.FunctionComponent = ({ children }) => {
  const classes = useStyles();

  const history = useHistory();
  const [bffData, setBFFData] = useState<BFFDataResponse>(
    {} as BFFDataResponse
  );

  const notistackRef = React.createRef<SnackbarProvider>();

  useEffect(() => {
    const bffDataLoader = bffDataFactory("/data");
    bffDataLoader.fetchBFFData().then((response) => {
      setBFFData(response);
    });
  }, []);

  useEffect(() => {
    const unlisten = history.listen((location) => {
      ReactGA.pageview(location.pathname);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  const onClickDismiss = (key) => () => {
    if (notistackRef.current) {
      notistackRef.current.closeSnackbar(key);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ReduxProvider store={getStore()}>
        <BFFContextProvider value={bffData}>
          <SnackbarProvider
            maxSnack={1}
            ref={notistackRef}
            action={(key) => (
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                className={classes.close}
                onClick={onClickDismiss(key)}
              >
                <CloseIcon />
              </IconButton>
            )}
          >
            <ScrollToTop />
            <BFFPage>{children}</BFFPage>
          </SnackbarProvider>
        </BFFContextProvider>
      </ReduxProvider>
    </MuiPickersUtilsProvider>
  );
};

export default BFFProvider;
