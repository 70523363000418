import Grid from "@material-ui/core/Grid";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { validateEmail, validatePhone, validateRequired } from "../form/validations";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      padding: theme.spacing(3, 0)
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "100%"
    }
  })
);

interface FormFieldsProps {
  editMode: boolean;
}

export const ProfileFormFields: React.FunctionComponent<FormFieldsProps> = ({
  editMode
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Field
            name="username"
            type="text"
            label="User Name"
            className={classes.textField}
            disabled={true}
            component={TextField}
            validate={validateRequired}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.grid}>
        <Grid item xs={12} sm={6}>
          <Field
            name="firstname"
            type="text"
            label="First Name"
            className={classes.textField}
            disabled={!editMode}
            component={TextField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="lastname"
            type="text"
            label="Last Name"
            className={classes.textField}
            disabled={!editMode}
            component={TextField}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="addr1"
            type="text"
            label="Address"
            className={classes.textField}
            disabled={!editMode}
            component={TextField}
          />
          <Field
            name="addr2"
            type="text"
            className={classes.textField}
            disabled={!editMode}
            component={TextField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="city"
            type="text"
            label="City"
            className={classes.textField}
            disabled={!editMode}
            component={TextField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="state"
            type="text"
            label="Province"
            className={classes.textField}
            disabled={!editMode}
            component={TextField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="zip"
            type="text"
            label="Postal Code"
            className={classes.textField}
            disabled={!editMode}
            component={TextField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="country"
            type="text"
            label="Country"
            className={classes.textField}
            disabled={!editMode}
            component={TextField}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="homephone"
            type="text"
            label="Home Phone"
            className={classes.textField}
            disabled={!editMode}
            component={TextField}
            validate={validatePhone}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="workphone"
            type="text"
            label="Work Phone"
            className={classes.textField}
            disabled={!editMode}
            component={TextField}
            validate={validatePhone}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="cellphone"
            type="text"
            label="Cell Phone"
            className={classes.textField}
            disabled={!editMode}
            component={TextField}
            validate={validatePhone}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="email"
            type="text"
            label="Email"
            className={classes.textField}
            disabled={!editMode}
            component={TextField}
            validate={validateEmail}
          />
        </Grid>
      </Grid>
      <Typography variant="h6">Emergency Contact</Typography>
      <Grid container spacing={3} className={classes.grid}>
        <Grid item xs={12} sm={6}>
          <Field
            name="emergencycontact"
            type="text"
            label="Name"
            className={classes.textField}
            disabled={!editMode}
            component={TextField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="emergencyphone"
            type="text"
            label="Phone"
            className={classes.textField}
            disabled={!editMode}
            component={TextField}
            validate={validatePhone}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="emergencyemail"
            type="text"
            label="Email"
            className={classes.textField}
            disabled={!editMode}
            component={TextField}
            validate={validateEmail}
          />
        </Grid>
      </Grid>
    </>
  );
};
