import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import React, { useState } from "react";
import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { PaperProps, useMediaQuery, useTheme } from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router";
import { TransitionProps } from "@material-ui/core/transitions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
      marginBottom: theme.spacing(4),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface BFFDialogProps {
  title: string;
  content: React.ReactChild;
  actions?: React.ReactElement;
  actionColor?: "primary" | "secondary" | "default" | undefined;
  PaperProps?: Partial<PaperProps>;
}
type Props = BFFDialogProps & RouteComponentProps;

const BFFDialog: React.FunctionComponent<Props> = ({
  history,
  title,
  content,
  actions,
  actionColor = "primary",
  staticContext,
  ...otherProps
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    history.goBack();
  };

  return (
    <>
      {fullScreen && (
        <Dialog
          fullScreen={true}
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          {...otherProps}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {title}
              </Typography>
              {actions}
            </Toolbar>
          </AppBar>
          <DialogContent>{content}</DialogContent>
        </Dialog>
      )}
      {!fullScreen && (
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={open}
          onClose={handleClose}
          aria-labelledby="bff-dialog-title"
          TransitionComponent={Transition}
          {...otherProps}
        >
          <DialogTitle id="bff-dialog-title">{title}</DialogTitle>
          <DialogContent dividers={true}>{content}</DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color={actionColor}>
              {"Close"}
            </Button>
            {actions && React.cloneElement(actions, { color: actionColor })}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default withRouter(BFFDialog);
