import BFFDialog from "../BFFDialog";
import List from "@material-ui/core/List";
import React, { useEffect, useState } from "react";
import ServiceItemCard from "./ServiceItemCard";
import { Boarding } from "../../model/Boarding";
import { CircularProgress } from "@material-ui/core";
import { Daycare } from "../../model/Daycare";
import { loadBoarding } from "../../store/actions/boarding.actions";
import { loadDaycare } from "../../store/actions/daycare.actions";
import { loadScheduledService } from "../../store/actions/secheduledService.action";
import { RootState } from "../../store/reducers";
import { RouteComponentProps, StaticContext, withRouter } from "react-router";
import { ScheduledService } from "../../model/ScheduledService";
import { ServiceItem } from "../../model/Calendar";
import { useDispatch, useSelector } from "react-redux";
import { useServiceItem } from "../../hooks/useServiceItem";

interface ServiceItemDialogProps {
  item: ServiceItem | undefined;
}

type LocationState = {
  item: ServiceItem;
};

type Props = ServiceItemDialogProps &
  RouteComponentProps<{}, StaticContext, LocationState>;

const ServiceItemDialog: React.FunctionComponent<Props> = ({
  item,
  location,
  history,
  match,
}) => {
  const dispatch = useDispatch();

  const [selectedService, setSelectedService] = useState(item);
  const [serviceType, setServiceType] = useState<string | null>(null);
  const [serviceId, setServiceId] = useState<number | null>(null);

  const daycareItem = useSelector<RootState, Daycare | null>(({ entities }) => {
    if (serviceType === "daycare" && serviceId) {
      if (entities && entities.calendar.daycares) {
        return entities.calendar.daycares[serviceId];
      }
    }
    return null;
  });

  const scheduledItem = useSelector<RootState, ScheduledService | null>(
    ({ entities }) => {
      if (serviceType === "service" && serviceId) {
        if (entities && entities.calendar.scheduledServices) {
          return entities.calendar.scheduledServices[serviceId];
        }
      }
      return null;
    }
  );

  const boardingItem = useSelector<RootState, Boarding | null>(
    ({ entities }) => {
      if (serviceType === "boarding" && serviceId) {
        if (entities && entities.calendar.boardings) {
          return entities.calendar.boardings[serviceId];
        }
      }
      return null;
    }
  );

  const serviceItem = useServiceItem(
    daycareItem || scheduledItem || boardingItem
  );

  useEffect(() => {
    if (location.state && location.state.item) {
      setSelectedService(location.state.item);
    } else if (match.params && match.params["id"]) {
      const serviceId: number = match.params["id"];
      const serviceType: string = match.params["service"];
      setServiceType(serviceType);
      setServiceId(serviceId);
    }
  }, [location, match, dispatch]);

  useEffect(() => {
    if (serviceType && serviceId) {
      if (serviceType === "service") {
        // always fetch scheduled service
        dispatch(loadScheduledService(serviceId));
      } else {
        if (!selectedService) {
          if (serviceType === "daycare") {
            dispatch(loadDaycare(serviceId));
          } else if (serviceType === "boarding") {
            dispatch(loadBoarding(serviceId));
          } else {
            history.push("/");
          }
        }
      }
    }
  }, [history, selectedService, dispatch, serviceType, serviceId]);

  useEffect(() => {
    if (!selectedService && serviceItem) {
      setSelectedService(serviceItem);
    }
  }, [selectedService, serviceItem]);

  let dialogContent: React.ReactElement;
  if (selectedService) {
    dialogContent = (
      <List>
        <ServiceItemCard service={selectedService} />
      </List>
    );
  } else {
    dialogContent = (
      <div className="bff-loading">
        <CircularProgress />
      </div>
    );
  }

  return <BFFDialog title="Service Details" content={dialogContent} />;
};

export default withRouter(ServiceItemDialog);
