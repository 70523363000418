export class TokenReplacer {
  /**
   * Replace tokens in the provided string with the contents of the `tokens` object. If no replacement
   * is found, the token will be left as-is.
   * @param {string} inputWithTokens String with tokens in curly braces (example `{id}`: `/url/with/id/{id}`)
   * @param {object} tokens Object where the property names represent the token(s) to replace, and the
   * value of each property name is the value to replace that token with.
   * @returns {string} The original string with tokens replaced by their corresponding values.
   */
  public static replace(inputWithTokens: string, tokens: any = {}): string {
    if (inputWithTokens == null) {
      return inputWithTokens;
    }

    let url = inputWithTokens;
    Object.keys(tokens).forEach((tokenKey) => {
      const regExp = new RegExp(`{${tokenKey}}`, "g");
      url = url.replace(regExp, tokens[tokenKey]);
    });

    return url;
  }
}
