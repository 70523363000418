import { calendarReducer, CalendarState } from "./calendar.reducer";
import { combineReducers } from "redux";
import { employeeReducer, EmployeeState } from "./employee.reducer";
import { MessageState, messsageReducer } from "./message.reducer";
import { packagesReducer, PackageState } from "./package.reducer";
import { petBreedsReducer, PetBreedsState } from "./petBreed.reducer";
import { petReducer, PetState } from "./pet.reducer";
import { petTypeReducer, PetTypeState } from "./petType.reducer";
import { profileReducer, ProfileState } from "./profile.reducer";
import { serviceTypeReducer, ServiceTypeState } from "./serviceType.reducer";
import { shotsReducer, ShotsState } from "./shots.reducer";
import { vetReducer, VetState } from "./vet.reducer";
import {
  companyLocationReducer,
  CompanyLocationState,
} from "./location.reducer.ts";
import {
  companyServicesReducer,
  CompanyServiceState,
} from "./companyServices.reducer";
import {
  daycareServicesReducer,
  DaycareServiceState,
} from "./daycareServices.reducer";
import {
  scheduledServiceTypesReducer,
  ScheduledServiceTypesState,
} from "./scheduledServiceTypes.reducer";
import {
  signedAgreementsReducer,
  SignedAgreementsState,
} from "./signedAgreements.reducer.";
import {
  BoardingServiceState,
  boardingServicesReducer,
} from "./boardingServices.reducer";
import {
  BoardingAddOnsState,
  boardingAddOnsReducer,
} from "./boardingAddOns.reducer";

export interface EntityMap<T> {
  [key: number]: T;
}

export interface EntityState {
  readonly profile: ProfileState;
  readonly calendar: CalendarState;
  readonly pets: PetState;
  readonly messsges: MessageState;
  readonly packages: PackageState;
  readonly companyServices: CompanyServiceState;
  readonly companyLocations: CompanyLocationState;
  readonly employees: EmployeeState;
  readonly daycareServices: DaycareServiceState;
  readonly scheduledServiceTypes: ScheduledServiceTypesState;
  readonly serviceTypes: ServiceTypeState;
  readonly signedAgreements: SignedAgreementsState;
  readonly boardingServices: BoardingServiceState;
  readonly boardingAddOns: BoardingAddOnsState;
  readonly petTypes: PetTypeState;
  readonly petBreeds: PetBreedsState;
  readonly vets: VetState;
  readonly shots: ShotsState;
}

const entitiesReducer = combineReducers<EntityState>({
  profile: profileReducer,
  calendar: calendarReducer,
  pets: petReducer,
  messsges: messsageReducer,
  packages: packagesReducer,
  companyServices: companyServicesReducer,
  companyLocations: companyLocationReducer,
  employees: employeeReducer,
  daycareServices: daycareServicesReducer,
  scheduledServiceTypes: scheduledServiceTypesReducer,
  serviceTypes: serviceTypeReducer,
  signedAgreements: signedAgreementsReducer,
  boardingServices: boardingServicesReducer,
  boardingAddOns: boardingAddOnsReducer,
  petTypes: petTypeReducer,
  petBreeds: petBreedsReducer,
  vets: vetReducer,
  shots: shotsReducer,
});

export { entitiesReducer };
