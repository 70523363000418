import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { Copyright, CopyrightContainer, Logo } from "../components/Copyright";
import { login } from "../store/actions/auth.actions";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(6, 0, 0, 0),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.secondary.main,
  },
  copyright: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    margin: theme.spacing(0, 0, 8),
  },
}));

export default function Login() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleLogin = () => {
    dispatch(login());
  };

  return (
    <Container component="main" maxWidth="sm">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleLogin}
        >
          Sign In
        </Button>
      </div>
      <Grid container direction="column" alignContent="space-around">
        <Grid item>
          <Link
            href="https://secure.petexec.net/newOwner.php?x=aDpLGdxrHnE="
            target="_blank"
            variant="body2"
          >
            {"Don't have an account? Sign Up"}
          </Link>
        </Grid>
      </Grid>
      <Box mt={8}>
        <Typography variant="body2" align="center" color="textSecondary">
          Set-up your on-line account to start requesting and enjoying BFF
          services.
        </Typography>
        <CopyrightContainer>
          <Logo />
          <Typography variant="body1" align="center" color="secondary">
            YOUR TRUSTED PET CARE PROFESSIONALS
          </Typography>
          <Copyright />
        </CopyrightContainer>
      </Box>
    </Container>
  );
}
