import { buildServiceItem } from "../utils/CalendarUtils";
import { CalendarItem, ServiceItem } from "../model/Calendar";
import { Employee, Location } from "../model/Company";
import { EntityMap } from "../store/reducers/entities.reducer";
import { Pet } from "../model/Pet";
import { RootState } from "../store/reducers";
import { Service } from "../model/Package";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { values } from "lodash";

export interface ServiceItemEntities {
  pets: EntityMap<Pet>;
  employees: Employee[];
  services: EntityMap<Service>;
  locations: EntityMap<Location>;
}

export const useServiceItemEntities = (): ServiceItemEntities => {
  const pets = useSelector<RootState, EntityMap<Pet> | null>(({ entities }) => {
    if (entities && entities.pets) {
      return entities.pets;
    }
    return null;
  });

  const employees = useSelector<RootState, EntityMap<Employee> | null>(
    ({ entities }) => {
      if (entities && entities.employees) {
        return entities.employees;
      }
      return null;
    }
  );

  const services = useSelector<RootState, EntityMap<Service> | null>(
    ({ entities }) => {
      if (entities && entities.companyServices) {
        return entities.companyServices;
      }
      return null;
    }
  );

  const locations = useSelector<RootState, EntityMap<Location> | null>(
    ({ entities }) => {
      if (entities && entities.companyLocations) {
        return entities.companyLocations;
      }
      return null;
    }
  );

  const serviceItemEntities = useMemo<ServiceItemEntities>(() => {
    return {
      pets: pets || {},
      employees: values(employees) || [],
      services: services || {},
      locations: locations || {}
    };
  }, [pets, employees, services, locations]);

  return serviceItemEntities;
};

export const useServiceItem = (
  item: CalendarItem | null
): ServiceItem | null => {
  const serviceItemEntities = useServiceItemEntities();

  if (serviceItemEntities) {
    const { pets, employees, services, locations } = serviceItemEntities;
    if (pets && employees && services && locations) {
      if (item) {
        return buildServiceItem(item, pets, employees, services, locations);
      }
    }
  }

  return null;
};
