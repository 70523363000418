import { Action } from "redux-actions";
import { EntityMap } from "./entities.reducer";
import { groupBy } from "lodash";
import { PetActions } from "../actions/action.constants";
import { PetBreed } from "../../model/Pet";
import { Reducer } from "redux";

export type PetBreedsState = EntityMap<PetBreed[]> | undefined;

const initialState: PetBreedsState = {};

const handleAddAction: Reducer<PetBreedsState, Action<PetBreed[]>> = (
  state = initialState,
  action
): PetBreedsState => {
  switch (action.type) {
    case PetActions.ADD_BREEDS: {
      if (action.payload) {
        const breeds = groupBy(action.payload, "ptid");
        return {
          ...state,
          ...breeds,
        };
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

export { handleAddAction as petBreedsReducer };
