export enum AppActions {
  OPEN_NAV = "NAV_OPEN",
  CLOSE_NAV = "NAV_CLOSE",
  SELECTED_SERVICE = "SERVICE_SELECT",
  SELECTED_DATE = "DATE_SELECT",
  CALENDAR_LOADING = "CALENDAR_LOADING",
}

export enum AuthActions {
  LOGOUT = "AUTH_LOGOUT",
}

export enum ProfileActions {
  LOAD = "PROFILE_LOAD",
  LOAD_OWNER = "OWNER_LOAD",
  MAKE_EMPLOYEE = "MAKE_EMPLOYEE",
}

export enum DaycareActions {
  LOAD_CALENDAR = "DAYCARE_LOAD_CALENDAR",
  LOAD_PACKAGES = "DAYCARE_LOAD_PACKAGES",
  LOAD = "DAYCARE_LOAD",
  SCHEDULE = "DAYCARE_SCHEDULE",
  LOAD_SERVICES = "DAYCARE_LOAD_SERVICES",
  LOAD_INSTRUCTIONS = "DAYCARE_LOAD_INSTRUCTIONS",
  DELETE = "DAYCARE_DELETE",
}

export enum ServicesActions {
  LOAD_CALENDAR = "SERVICES_LOAD_CALENDAR",
  LOAD_PACKAGES = "SERVICES_LOAD_PACKAGES",
  REQUEST_SCHEDULED_SERVICE = "SERVICES_REQUEST",
  ADD_SERVICE_TYPES = "SERVICES_ADD_SERVICE_TYPES",
  ADD_SERVICES = "SERVICES_ADD_SERVICES",
  LOAD_SERVICES = "SERVICES_LOAD_SERVICES",
  LOAD_INSTRUCTIONS = "SERVICES_LOAD_INSTRUCTIONS",
  LOAD = "SCHEDULEDSERVICE_LOAD",
}

export enum BoardingActions {
  LOAD_CALENDAR = "BOARDING_LOAD_CALENDAR",
  LOAD = "BOARDING_LOAD",
  LOAD_SERVICES = "BOARDING_LOAD_SERVICES",
  LOAD_INSTRUCTIONS = "BOARDING_LOAD_INSTRUCTIONS",
  LOAD_ADDONS = "BOARDING_LOAD_ADDONS",
}

export enum PetActions {
  LOAD_PETS = "PETS_LOAD",
  UPLOAD_PIC = "PET_PIC_UPLOAD",
  ADD_TYPES = "PET_ADD_TYPES",
  ADD_BREEDS = "PET_ADD_BREEDS",
  LOAD_VETS = "PET_LOAD_VETS",
  ADD_SHOTS = "PET_ADD_SHOTS",
}

export enum CompanyActions {
  LOAD_APP_MESSAGE = "APP_MESSAGE_LOAD",
  LOAD_OWNER_MESSAGE = "OWNER_MESSAGE_LOAD",
  LOAD_PACKAGES = "COMPANY_LOAD_PACKAGES",
  LOAD_APP_IMAGES = "COMPANY_LOAD_IMAGES",
  LOAD_EMPLOYEES = "COMPANY_LOAD_EMPLOYEES",
  SEND_EMAIL = "COMPANY_SEND_EMAIL",
  LOAD_LOCATIONS = "COMPANY_LOAD_LOCATIONS",
  LOAD_SIGNED_AGREEMENTS = "COMPANY_LOAD_SIGNED_AGREEMENTS",
}

export enum NotificationActions {
  PUSH = "NOTIFICATION_PUSH",
  REMOVE = "NOTIFICATION_REMOVE",
}
