import React from "react";
import { Field, FormikProps } from "formik";
import {
  FormControlLabel,
  FormLabel,
  Grid,
  Radio
  } from "@material-ui/core";
import { PetCreation } from "../../model/Pet";
import { RadioGroup } from "formik-material-ui";
import { validateRequired } from "./validations";

type Props = FormikProps<Partial<PetCreation>>;

export const NeuterSelect: React.FunctionComponent<Props> = ({ values }) => {
  const name = "neuteredState";
  return (
    <Grid item xs={12} sm={6}>
      <FormLabel component="legend">Spay / Neuter State</FormLabel>
      <Field
        value={values[name]}
        name={name}
        component={RadioGroup}
        validate={validateRequired}
      >
        <FormControlLabel value="Unknown" control={<Radio />} label="Unknown" />
        <FormControlLabel
          value="Not fixed"
          control={<Radio />}
          label="Unaltered"
        />
        <FormControlLabel
          value="Neutered"
          control={<Radio />}
          label="Neutered"
        />
        <FormControlLabel value="Spayed" control={<Radio />} label="Spayed" />
      </Field>
    </Grid>
  );
};
