import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { EMPTY_PET } from "../../model/Pet";
import { EMPTY_PROFILE, Profile } from "../../model/Profile";
import { Link } from "react-router-dom";
import { logout } from "../../store/actions/auth.actions";
import { Pet } from "../../model/Pet";
import { RootState } from "../../store/reducers";
import { RouteComponentProps, withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    welcome: {
      flexGrow: 1
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20
    }
  })
);

interface InjectedStateProps {
  isAuthenticated: boolean;
  petImageUrl: string;
  profile: Profile;
}

type Props = RouteComponentProps;

const MainAppBar: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { isAuthenticated, petImageUrl, profile } = useSelector<
    RootState,
    InjectedStateProps
  >(({ auth, app, entities }) => {
    const defaultPet: Pet = app.defaultPet || EMPTY_PET;
    return {
      isAuthenticated: auth.authenticated,
      petImageUrl: defaultPet.pathtopic || "",
      profile:
        (entities.profile ? entities.profile[auth.userid] : undefined) ||
        EMPTY_PROFILE
    };
  });
  const dispatch = useDispatch();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileNav = () => {
    props.history.push("/profile");
    handleClose();
  };

  const handleLogout = () => {
    dispatch(logout());
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            aria-label="Logo"
            component={Link}
            to="/"
          >
            <Avatar src="/images/bff-logo-w.png" />
          </IconButton>
          {isAuthenticated && (
            <>
              <Typography variant="h6" className={classes.welcome}>
                Welcome {profile.firstname}
              </Typography>
              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <Avatar src={petImageUrl} />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleProfileNav}>Profile</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default withRouter(MainAppBar);
