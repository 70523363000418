import { BFFThunkAction, BFFThunkDispatch } from "./action.types";
import { PackagePayload, Service } from "../../model/Package";
import PackageService from "../../services/PackageService";
import {
  DaycareActions,
  ServicesActions,
  CompanyActions
} from "./action.constants";
import { createAction } from "redux-actions";

function loadPackagesThunk(userid: number): BFFThunkAction<void> {
  return async (dispatch: BFFThunkDispatch) => {
    // load all company services
    dispatch(loadCompanyPackages());
    // load the packages
    if (userid) {
      dispatch(loadDaycarePackages(userid));
      dispatch(loadOtherPackages(userid));
    }
  };
}

export const loadPackages = loadPackagesThunk;

function loadCompanyAction(): Promise<Service[]> {
  const service = new PackageService();
  return service.loadCompanyPackages();
}

const loadCompanyPackages = createAction<Promise<Service[]>>(
  CompanyActions.LOAD_PACKAGES,
  loadCompanyAction
);

function loadDaycareAction(userid: number): Promise<PackagePayload> {
  const service = new PackageService();
  return service.loadDaycare(userid);
}

const loadDaycarePackages = createAction<Promise<PackagePayload>, number>(
  DaycareActions.LOAD_PACKAGES,
  loadDaycareAction
);

function loadOtherAction(userid: number): Promise<PackagePayload> {
  const service = new PackageService();
  return service.loadOther(userid);
}

const loadOtherPackages = createAction<Promise<PackagePayload>, number>(
  ServicesActions.LOAD_PACKAGES,
  loadOtherAction
);
