import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import React, { useState } from "react";
import Switch from "@material-ui/core/Switch";
import { FieldProps } from "formik";

interface ComponentProps {
  label?: string;
  showWeekend?: boolean;
}

interface DaySelection {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export const WeekdayField: React.FunctionComponent<ComponentProps &
  FieldProps> = ({ label, showWeekend = false, field, form, ...other }) => {
  const [days, setDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false
  } as DaySelection);
  const [all, setAll] = useState(false);

  const resetAllDays = (value: boolean): DaySelection => {
    return {
      monday: value,
      tuesday: value,
      wednesday: value,
      thursday: value,
      friday: value,
      saturday: showWeekend && value,
      sunday: showWeekend && value
    };
  };

  const handleChange = (event: any) => {
    let daySelection: DaySelection;
    if (event.target.name === "all") {
      daySelection = resetAllDays(event.target.checked);
      setAll(event.target.checked);
    } else {
      daySelection = { ...days, [event.target.name]: event.target.checked };
      setAll(false);
    }
    setDays(daySelection);
    const dayValues: string[] = Object.keys(daySelection).reduce<string[]>(
      (accumulator, day) => {
        if (daySelection[day] === true) {
          accumulator.push(day);
        }
        return accumulator;
      },
      [] as string[]
    );
    form.setFieldValue(field.name, dayValues, true);
  };

  const currentError = form.errors[field.name];

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={all}
              onChange={handleChange}
              name="all"
              value="all"
            />
          }
          label="All"
        />
        <FormControlLabel
          control={
            <Switch
              checked={days.monday}
              onChange={handleChange}
              name="monday"
              value="monday"
            />
          }
          label="Monday"
        />
        <FormControlLabel
          control={
            <Switch
              checked={days.tuesday}
              onChange={handleChange}
              name="tuesday"
              value="tuesday"
            />
          }
          label="Tuesday"
        />
        <FormControlLabel
          control={
            <Switch
              checked={days.wednesday}
              onChange={handleChange}
              name="wednesday"
              value="wednesday"
            />
          }
          label="Wednesday"
        />
        <FormControlLabel
          control={
            <Switch
              checked={days.thursday}
              onChange={handleChange}
              name="thursday"
              value="thursday"
            />
          }
          label="Thursday"
        />
        <FormControlLabel
          control={
            <Switch
              checked={days.friday}
              onChange={handleChange}
              name="friday"
              value="friday"
            />
          }
          label="Friday"
        />
        {showWeekend && (
          <FormControlLabel
            control={
              <Switch
                checked={days.saturday}
                onChange={handleChange}
                name="saturday"
                value="saturday"
              />
            }
            label="Saturday"
          />
        )}
        {showWeekend && (
          <FormControlLabel
            control={
              <Switch
                checked={days.sunday}
                onChange={handleChange}
                name="sunday"
                value="sunday"
              />
            }
            label="Sunday"
          />
        )}
      </FormGroup>
      <FormHelperText error={Boolean(currentError)}>
        {currentError}
      </FormHelperText>
    </FormControl>
  );
};
