import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CalendarService from "../../services/CalendarService";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import cn from "classnames";
import Collapse from "@material-ui/core/Collapse";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import ReactGA from "react-ga";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { DATE_FRIENDLY_FORMAT } from "../../constants";
import { deleteDaycare } from "../../store/actions/daycare.actions";
import { getEmployeeImage, getEmployeeName } from "../../utils/EmployeeUtils";
import { isScheduledWalk } from "../../utils/CalendarUtils";
import { ServiceItem } from "../../model/Calendar";
import { Shuttle } from "../../model/Company";
import { useBetaUser } from "../../hooks/useBetaUser";
import { useBFFData } from "../../hooks/useBFFData";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
  getAppointmentText,
  getStatusText,
} from "../../utils/ServiceItemUtils";
import {
  renderBoardingItems,
  renderDaycareItems,
  renderListItem,
  renderPowerHourItems,
  renderWalkItems,
} from "./ServiceItemRenderers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      maxWidth: 500,
      margin: "0 auto",
    },
    content: {
      padding: "0 16px",
    },
    media: {
      height: 0,
      paddingTop: "75%",
    },
    list: {
      padding: 0,
    },
    inline: {
      display: "inline",
    },
    showMore: {
      marginLeft: "auto",
    },
    expand: {
      transform: "rotate(0deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
  })
);

interface ServiceItemCardProps {
  service?: ServiceItem;
}

type Props = ServiceItemCardProps;

const ServiceItemCard: React.FunctionComponent<Props> = ({ service }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const shuttle = useBFFData<Shuttle>("shuttle");
  const history = useHistory();
  const isBetaUser = useBetaUser();

  const [expanded, setExpanded] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const renderTitle = (item: ServiceItem) => {
    const petNames = item.pets.map((pet) => pet.petname).join(", ");
    let title = `${getAppointmentText(item)} ${item.serviceType}`;
    if (item.serviceType !== "Private Shopping") {
      title += ` for ${petNames}`;
    }
    return title;
  };

  const renderSubheader = (item: ServiceItem) => {
    const serviceDate = item.date.format(DATE_FRIENDLY_FORMAT);

    return (
      <>
        <Typography variant="body2">{serviceDate}</Typography>
        {renderListItem("Status", getStatusText(item))}
        {renderDaycareItems(item)}
        {renderWalkItems(item)}
        {renderPowerHourItems(item)}
        {renderBoardingItems(item)}
        {renderListItem("Your Professional", getEmployeeName(item))}
      </>
    );
  };

  const renderMessage = (item: ServiceItem) => {
    if (
      CalendarService.isServicesItem(item.schedule) &&
      isScheduledWalk(item.schedule)
    ) {
      if (item.schedule.requestid) {
        return "Multi-day requests will only appear on first day of date range until scheduled.";
      }
    }
    if (CalendarService.isBoardingItem(item.schedule)) {
      if (item.schedule.status === "Pending Deposit") {
        return "This boarding request cannot be confirmed until payment has been received.";
      }
    }
    if (item.schedule.notes) {
      return item.schedule.notes;
    }
    return null;
  };

  const renderEmployee = (item: ServiceItem) => {
    if (item.employee && item.employee.description) {
      return <>{item.employee.description}</>;
    }
    return <></>;
  };

  const renderShuttleFAQ = () => {
    if (shuttle) {
      return (
        <>
          {shuttle.faq.map((faq) => {
            const secondary = (
              <ul>
                {faq.subtitle.map((sub, index) => (
                  <li key={index}>{sub}</li>
                ))}
              </ul>
            );
            return (
              <div key={faq.title}>
                <Typography variant="subtitle2" component="h2">
                  {faq.title}
                </Typography>
                {secondary}
              </div>
            );
          })}
        </>
      );
    }
    return null;
  };

  const renderCardActions = (item: ServiceItem) => {
    const showMore =
      (item.employee !== undefined && item.employee.description) ||
      CalendarService.isBoardingItem(item.schedule);
    return (
      <CardActions disableSpacing>
        {CalendarService.isDaycareItem(item.schedule) && (
          <IconButton onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        )}
        {isBetaUser && CalendarService.isBoardingItem(item.schedule) && (
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        )}
        {showMore && (
          <Button
            className={classes.showMore}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            endIcon={
              <ExpandMoreIcon
                className={cn(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
              />
            }
          >
            {item.employee && <>Meet your professional</>}
            {CalendarService.isBoardingItem(item.schedule) && <>Shuttle FAQ</>}
          </Button>
        )}
      </CardActions>
    );
  };

  const renderCardExpanded = (item: ServiceItem) => {
    const hasBoarding = CalendarService.isBoardingItem(item.schedule);
    return (
      <>
        {item.employee && (
          <CardMedia
            className={classes.media}
            image={getEmployeeImage(item)}
            title={getEmployeeName(item)}
          />
        )}
        <CardContent>
          {item.employee && item.employee.description && (
            <List component="div" dense={true} className={classes.list}>
              <ListItem>
                <ListItemText
                  primary={`About ${item.employee.firstname}`}
                  secondary={renderEmployee(item)}
                />
              </ListItem>
            </List>
          )}

          {hasBoarding && renderShuttleFAQ()}
        </CardContent>
      </>
    );
  };

  if (!service) {
    return null;
  }

  const handleDelete = () => {
    setDeleteDialogOpen(true);
  };

  const handleEdit = () => {
    if (CalendarService.isBoardingItem(service.schedule)) {
      const serviceid = service.schedule.boardingid;
      history.push(`/boarding/edit/${serviceid}`);
    }
  };

  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDialogConfirm = () => {
    if (CalendarService.isDaycareItem(service.schedule)) {
      ReactGA.event({
        category: "Daycare",
        action: "Delete",
      });
      dispatch(deleteDaycare(service.schedule));
    }
    handleDialogClose();
  };

  return (
    <>
      <Card className={classes.card}>
        <CardHeader
          disableTypography={false}
          avatar={<Avatar src={service.pets[0].pathtopic} />}
          title={renderTitle(service)}
          subheader={renderSubheader(service)}
        />
        <CardContent>
          <Typography variant="body2" color="primary">
            {renderMessage(service)}
          </Typography>
        </CardContent>
        <>
          {renderCardActions(service)}
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            {renderCardExpanded(service)}
          </Collapse>
        </>
      </Card>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">Cancel Daycare</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            Are you sure you want to cancel the selected daycare appointment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            No
          </Button>
          <Button onClick={handleDialogConfirm} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ServiceItemCard;
