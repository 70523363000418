import { AppActions, NotificationActions } from "./action.constants";
import { CalendarItem } from "../../model/Calendar";
import { createAction } from "redux-actions";
import { Notification } from "../reducers/app.reducer";

export const APP_NOTIFICATION_KEY = "appMessage";

export const openNav = createAction(AppActions.OPEN_NAV);
export const closeNav = createAction(AppActions.CLOSE_NAV);

export const selectService = createAction<CalendarItem>(
  AppActions.SELECTED_SERVICE
);

export const showNotification = createAction<Notification>(
  NotificationActions.PUSH
);

export const removeNotification = createAction<Notification>(
  NotificationActions.REMOVE
);

export const selectDate = createAction<Date>(AppActions.SELECTED_DATE);
