import FormLabel from "@material-ui/core/FormLabel";
import React from "react";
import { Field } from "formik";
import { FormControlLabel, Radio } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { RadioGroup } from "formik-material-ui";

export const AppointmentTime: React.FunctionComponent = () => {
  return (
    <Grid item xs={4}>
      <FormLabel component="legend">Time</FormLabel>
      <Field name="appointmentWindow" component={RadioGroup}>
        <FormControlLabel value="morning" control={<Radio />} label="Morning" />
        <FormControlLabel
          value="afternoon"
          control={<Radio />}
          label="Afternoon"
        />
        <FormControlLabel value="evening" control={<Radio />} label="Evening" />
      </Field>
    </Grid>
  );
};
