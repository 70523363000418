import { Action } from "redux-actions";
import { ActionType } from "redux-promise-middleware";
import { BoardingActions } from "../actions/action.constants";
import { BoardingAddOn } from "../../model/Boarding";
import { EntityMap } from "./entities.reducer";
import { mapKeys } from "lodash";
import { Reducer } from "react";

export type BoardingAddOnsState = EntityMap<BoardingAddOn> | undefined;

const handleLoadBoardingAddOnsAction: Reducer<
  BoardingAddOnsState,
  Action<BoardingAddOn[]>
> = (state = {}, action): BoardingAddOnsState => {
  switch (action.type) {
    case `${BoardingActions.LOAD_ADDONS}_${ActionType.Fulfilled}`: {
      return {
        ...state,
        ...mapKeys(action.payload, "serviceid"),
      };
    }
    default: {
      return state;
    }
  }
};

export { handleLoadBoardingAddOnsAction as boardingAddOnsReducer };
