import { DateRangeType, ServiceCreation } from "../model/ScheduledService";
import { FormikErrors } from "formik";

export const validateServiceCreation = (
  values: Partial<ServiceCreation> & DateRangeType,
  errors: FormikErrors<ServiceCreation>
): void => {
  if (
    values.startDate &&
    values.endDate &&
    typeof values.endDate !== "string"
  ) {
    if (values.type === "multiple") {
      if (values.endDate.isBefore(values.startDate)) {
        errors.endDate = "End date must be on or after start date.";
      }
    }
  }
};

export const validateDaysOfWeek = (
  values: Partial<ServiceCreation> & DateRangeType,
  errors: FormikErrors<ServiceCreation>
): void => {
  if (values.type === "multiple") {
    if (!values.daysOfWeek || values.daysOfWeek.length === 0) {
      errors.daysOfWeek = "At least one day must be selected";
    }
  }
};

export const validatePets = (
  values: Partial<ServiceCreation> & DateRangeType,
  errors: FormikErrors<ServiceCreation>
): void => {
  if (!values.petids || values.petids.length === 0) {
    errors.petids = "At least one pet must be selected";
  }
};
