import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import DataBox from "../DataBox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import React, { useState } from "react";
import ReactGA from "react-ga";
import RepeatIcon from "@material-ui/icons/Repeat";
import TableView, { Column } from "../TableView";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { buildReloadPackageEmail } from "../../utils/EmailUtils";
import { Package } from "../../model/Package";
import { Profile } from "../../model/Profile";
import { RootState } from "../../store/reducers";
import { sendEmail } from "../../store/actions/company.actions";
import { useDispatch, useSelector } from "react-redux";
import {
  APP_NOTIFICATION_KEY,
  showNotification,
} from "../../store/actions/app.actions";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    packageAvatar: {
      color: "#fff",
      backgroundColor: theme.palette.primary.main,
      margin: "auto",
    },
    tableCell: {
      color: "#fff",
    },
    button: {
      color: "#fff",
    },
  })
);

const PackageMesssage: React.FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const columns: Column[] = [
    { key: "servicename" },
    { key: "numberleft", align: "center" },
    { key: "reload" },
  ];

  const [selectedPackage, setSelectedPackage] = useState(
    undefined as Package | undefined
  );
  const [reloadOpen, setReloadOpen] = useState(false);

  const profile = useSelector<RootState, Profile | undefined>(
    ({ auth, entities }) => {
      if (entities.profile) {
        return entities.profile[auth.userid];
      }
      return undefined;
    }
  );

  const allPackages = useSelector<RootState, Package[]>(
    ({ auth, entities }) => {
      let packages: Package[] = [];
      if (entities.packages.daycare && entities.packages.daycare[auth.userid]) {
        packages = [...packages, ...entities.packages.daycare[auth.userid]];
      }
      if (entities.packages.other && entities.packages.other[auth.userid]) {
        packages = [...packages, ...entities.packages.other[auth.userid]];
      }
      return packages;
    }
  );

  const handleClickOpen = (servicePackage: Package) => {
    setSelectedPackage(servicePackage);
    setReloadOpen(true);
  };

  const handleClose = () => {
    setReloadOpen(false);
  };

  const handleConfirm = () => {
    if (profile && selectedPackage) {
      const message = buildReloadPackageEmail(profile, selectedPackage);
      ReactGA.event({
        category: "Package",
        action: "Reload Request",
      });
      dispatch(sendEmail("Reload Package", message));
    }
    handleClose();
    dispatch(
      showNotification({
        key: APP_NOTIFICATION_KEY,
        message: "Reload request submitted",
        options: { variant: "info" },
      })
    );
  };

  const renderCell = (data: Package, key: string): JSX.Element => {
    let cell: JSX.Element = data[key];
    if (key === "numberleft") {
      cell = (
        <>
          <Avatar className={classes.packageAvatar}>{data[key]}</Avatar>
          remaining
        </>
      );
    }

    if (key === "reload") {
      cell = (
        <>
          {!matches && (
            <Tooltip title="Reload" aria-label="reload">
              <IconButton
                className={classes.button}
                aria-label="reload"
                size="medium"
                onClick={() => handleClickOpen(data)}
              >
                <RepeatIcon />
              </IconButton>
            </Tooltip>
          )}
          {matches && (
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              startIcon={<RepeatIcon />}
              onClick={() => handleClickOpen(data)}
            >
              Reload
            </Button>
          )}
        </>
      );
    }
    return <Box className={classes.tableCell}>{cell}</Box>;
  };

  return (
    <>
      <DataBox title="Packages" color="secondary">
        {allPackages.length === 0 && (
          <Typography component="p">
            You do not have any active packages. Please contact BFF Playcare for
            assistance.
          </Typography>
        )}
        {allPackages.length > 0 && (
          <TableView
            columns={columns}
            data={allPackages}
            renderCell={renderCell}
          />
        )}
      </DataBox>
      <Dialog
        open={reloadOpen}
        onClose={handleClose}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">{"Reload Package?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            Are you sure you want to reload this package using the credit card
            we have on file?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PackageMesssage;
