import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import { Profile } from "../model/Profile";
import OwnerHome from "./OwnerHome";
import EmployeeHome from "./EmployeeHome";

const Home: React.FunctionComponent = () => {
  const profile = useSelector<RootState, Profile | undefined>(
    ({ auth, entities }) => {
      const currentUser = auth.userid;
      if (currentUser && entities && entities.profile) {
        return entities.profile[currentUser];
      }
      return undefined;
    }
  );

  if (profile) {
    return profile.isEmployee ? <EmployeeHome /> : <OwnerHome />;
  }
  return null;
};

export default Home;
