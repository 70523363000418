import AppBar from "@material-ui/core/AppBar";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import ProfileIcon from "@material-ui/icons/Person";
import React, { useEffect, useState } from "react";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { RootState } from "../../store/reducers";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
});
type Props = RouteComponentProps;

const navPaths = [
  "/",
  "/profile",
  "https://www.bffplaycare.com/shop",
  "/contact",
];

const BFFNavigation: React.FunctionComponent<Props> = ({ location }) => {
  const classes = useStyles({});
  const [value, setValue] = useState(0);

  const isAuthenticated = useSelector<RootState, boolean>(({ auth }) => {
    return auth.authenticated;
  });

  const isEmployee = useSelector<RootState, boolean>(({ auth, entities }) => {
    const currentUser = auth.userid;
    if (currentUser && entities && entities.profile) {
      const profile = entities.profile[currentUser];
      if (profile) {
        return profile.isEmployee;
      }
    }
    return false;
  });

  useEffect(() => {
    if (location.pathname === navPaths[0]) {
      setValue(0);
    }
    if (location.pathname.indexOf(navPaths[1]) >= 0) {
      setValue(1);
    }
    if (location.pathname.indexOf(navPaths[3]) >= 0) {
      setValue(3);
    }
  }, [location.pathname]);

  if (!isAuthenticated || isEmployee) {
    return null;
  }

  return (
    <AppBar position="fixed" color="primary" className={classes.appBar}>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={classes.root}
      >
        <BottomNavigationAction
          label="Home"
          icon={<HomeIcon />}
          component={Link}
          to={navPaths[0]}
        />
        <BottomNavigationAction
          label="Profile"
          icon={<ProfileIcon />}
          component={Link}
          to={navPaths[1]}
        />
        <BottomNavigationAction
          label="Shop"
          icon={<ShoppingBasketIcon />}
          href={navPaths[2]}
          target="_blank"
        />
        <BottomNavigationAction
          label="About"
          icon={<InfoIcon />}
          component={Link}
          to={navPaths[3]}
        />
      </BottomNavigation>
    </AppBar>
  );
};

export default withRouter(BFFNavigation);
