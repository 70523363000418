import { combineReducers } from "redux";
import { appReducer, AppState } from "./app.reducer";
import { entitiesReducer, EntityState } from "./entities.reducer";
import { authReducer, AuthState } from "./auth.reducer";

// Top-Level state interface
export interface RootState {
  app: AppState;
  auth: AuthState;
  entities: EntityState;
}

/**
 * The combineReducers will take all reducers and create a object that represents
 * the state of the store's structure.
 */
export default combineReducers<RootState>({
  app: appReducer,
  auth: authReducer,
  entities: entitiesReducer
});
