import { Action } from "redux-actions";
import { ActionType } from "redux-promise-middleware";
import { combineReducers } from "redux";
import { DaycareActions, ServicesActions } from "../actions/action.constants";
import { EntityMap } from "./entities.reducer";
import { Package, PackagePayload } from "../../model/Package";
import { Reducer } from "react";

type PackageTypeState = EntityMap<Package[]> | undefined;

export interface PackageState {
  readonly daycare: PackageTypeState;
  readonly other: PackageTypeState;
}

const filterActive = (packages: Package[]) =>
  packages.filter((p) => p.isactive === 1);

const handleLoadDaycareAction: Reducer<
  PackageTypeState,
  Action<PackagePayload>
> = (state = {}, action): PackageTypeState => {
  switch (action.type) {
    case `${DaycareActions.LOAD_PACKAGES}_${ActionType.Fulfilled}`: {
      return {
        ...state,
        ...{
          [action.payload.userid]: filterActive(action.payload.packages)
        }
      };
    }
    default: {
      return state;
    }
  }
};

const handleLoadServicesAction: Reducer<
  PackageTypeState,
  Action<PackagePayload>
> = (state = {}, action): PackageTypeState => {
  switch (action.type) {
    case `${ServicesActions.LOAD_PACKAGES}_${ActionType.Fulfilled}`: {
      return {
        ...state,
        ...{
          [action.payload.userid]: filterActive(action.payload.packages)
        }
      };
    }
    default: {
      return state;
    }
  }
};

const packagesReducer = combineReducers<PackageState>({
  daycare: handleLoadDaycareAction,
  other: handleLoadServicesAction
});

export { packagesReducer };
