import DOMPurify from "dompurify";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React, { useEffect } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { loadAppMessage } from "../../store/actions/company.actions";
import { RootState } from "../../store/reducers";
import { NullMessage } from "../../store/reducers/message.reducer";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "hidden",
      flexDirection: "column",
      backgroundColor: theme.palette.grey[100],
    },
    message: {
      color: theme.palette.primary.contrastText,
      "& a": {
        textDecoration: "none",
        color: theme.palette.secondary.main,
      },
    },
  })
);

const OwnerMessage: React.FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const appMessage = useSelector<RootState, NullMessage>(({ entities }) => {
    const appMessage = entities.messsges.appmessage;
    if (appMessage !== null) {
      return DOMPurify.sanitize(appMessage);
    }
    return appMessage;
  });

  useEffect(() => {
    dispatch(loadAppMessage());
  }, [dispatch]);

  if (appMessage === "") {
    return null;
  }

  return (
    <Paper className={classes.paper}>
      {appMessage !== null ? (
        <>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography
                component="span"
                color="inherit"
                className={classes.message}
                dangerouslySetInnerHTML={{ __html: appMessage }}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Skeleton animation="wave" width={96} />
          <Skeleton animation="wave" />
          <Skeleton variant="rect" animation="wave" height={96} />
        </>
      )}
    </Paper>
  );
};

export default OwnerMessage;
