import createMuiTheme, {
  ThemeOptions,
} from "@material-ui/core/styles/createMuiTheme";

function createBffTheme(options?: ThemeOptions) {
  return createMuiTheme({
    palette: {
      primary: { main: "#20B7FF", contrastText: "#333333" },
      secondary: { main: "#df5181" },
      contrastThreshold: 25,
    },
    ...options,
  });
}

const theme = createBffTheme();

export default theme;
