import { Action } from "redux-actions";
import { EntityMap } from "./entities.reducer";
import { groupBy } from "lodash";
import { PetActions } from "../actions/action.constants";
import { Reducer } from "redux";
import { Shot } from "../../model/Pet";

export type ShotsState = EntityMap<Shot[]> | undefined;

const initialState: ShotsState = {};

const handleAddAction: Reducer<ShotsState, Action<Shot[]>> = (
  state = initialState,
  action
): ShotsState => {
  switch (action.type) {
    case PetActions.ADD_SHOTS: {
      if (action.payload) {
        const shots = groupBy(action.payload, "ptid");
        return {
          ...state,
          ...shots,
        };
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

export { handleAddAction as shotsReducer };
