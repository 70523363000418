import { Action } from "redux-actions";
import { EntityMap } from "./entities.reducer";
import { Reducer } from "react";
import { ServicesActions } from "../actions/action.constants";
import { ServiceType, ServiceTypePayload } from "../../model/ScheduledService";

export type ServiceTypeState = EntityMap<ServiceType[]> | undefined;

const initialState: ServiceTypeState = {};

const handleLoadAction: Reducer<
  ServiceTypeState,
  Action<ServiceTypePayload>
> = (state = initialState, action): ServiceTypeState => {
  switch (action.type) {
    case ServicesActions.ADD_SERVICES: {
      return {
        ...state,
        ...{ [action.payload.stid]: action.payload.services }
      };
    }
    default: {
      return state;
    }
  }
};

export { handleLoadAction as serviceTypeReducer };
