import BFFBreadcrumb from "../components/app/BFFBreadcrumb";
import moment from "moment";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import SubmitButton from "../components/form/SubmitButton";
import Typography from "@material-ui/core/Typography";
import { addPet, loadPetBreeds, loadVets } from "../store/actions/pet.actions";
import { ComboBoxField } from "../components/form/ComboBoxField";
import { Container, Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { DatePickerField } from "../components/form/DatePickerField";
import { EntityMap } from "../store/reducers/entities.reducer";
import {
  Field,
  Form,
  Formik,
  FormikErrors
  } from "formik";
import { FormBox } from "../components/form/FormBox";
import { GenderSelect } from "../components/form/GenderSelect";
import { isEmpty, values } from "lodash";
import { NeuterSelect } from "../components/form/NeuterSelect";
import {
  PetBreed,
  PetCreation,
  PetType,
  Shot,
  Vet
  } from "../model/Pet";
import { RootState } from "../store/reducers";
import { Select, TextField } from "formik-material-ui";
import { useDispatch, useSelector } from "react-redux";
import { validateRequired } from "../components/form/validations";
import {
  CircularProgress,
  LinearProgress,
  FormLabel,
  MenuItem,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
      margin: theme.spacing(2, 0),
      backgroundColor: theme.palette.grey[100],
    },
    grid: {
      padding: theme.spacing(3, 0),
    },
    select: {
      minWidth: 120,
    },
    fullWidth: {
      width: "100%",
    },
    link: {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  })
);

type PetCreationForm = Partial<PetCreation>;

const PetPage: React.FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedPetType, setSelectedPetType] = useState<number>(2);
  const [breedsForType, setBreedsForType] = useState<PetBreed[]>([]);
  const [shotsForType, setShotsForType] = useState<Shot[]>([]);

  const petTypes = useSelector<RootState, PetType[]>(({ entities }) => {
    if (entities && entities.petTypes) {
      return values(entities.petTypes);
    }
    return [];
  });

  const petBreeds = useSelector<RootState, EntityMap<PetBreed[]>>(
    ({ entities }) => {
      if (entities && entities.petBreeds) {
        return entities.petBreeds;
      }
      return {};
    }
  );

  const petShots = useSelector<RootState, EntityMap<Shot[]>>(({ entities }) => {
    if (entities && entities.shots) {
      return entities.shots;
    }
    return {};
  });

  const vets = useSelector<RootState, Vet[]>(({ entities }) => {
    if (entities && entities.vets) {
      return values(entities.vets);
    }
    return [];
  });

  useEffect(() => {
    dispatch(loadPetBreeds());
    dispatch(loadVets());
  }, [dispatch]);

  useEffect(() => {
    if (petTypes.length > 0 && petBreeds) {
      setBreedsForType(petBreeds[selectedPetType]);
      setShotsForType(petShots[selectedPetType]);
    }
  }, [petTypes, petBreeds, petShots, selectedPetType]);

  if (petTypes.length === 0 || vets.length === 0) {
    return (
      <div className="bff-loading">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <BFFBreadcrumb
        pageName="Add Pet"
        paths={[{ name: "Profile", path: "/profile" }]}
      />
      <Container maxWidth="lg">
        <Paper className={classes.root}>
          <Typography variant="h4" gutterBottom>
            Add Pet
          </Typography>
          <Formik<PetCreationForm>
            enableReinitialize={true}
            initialValues={{
              petName: "",
              birthDate: moment(),
              ptid: selectedPetType,
              gender: "Male",
              neuteredState: "Unknown",
              authorizedToPickup: "",
              shots: {},
            }}
            validate={(values: PetCreationForm) => {
              const errors: FormikErrors<PetCreationForm> = {};
              return errors;
            }}
            onSubmit={(values: PetCreationForm, { setSubmitting }) => {
              ReactGA.event({
                category: "Pet",
                action: "Add pet",
              });
              //dispatch pet creation action
              dispatch(addPet(values));
              setTimeout(() => {
                setSubmitting(false);
              }, 500);
            }}
          >
            {(formProps) => {
              const {
                submitForm,
                isValid,
                isSubmitting,
                setFieldValue,
                values,
              } = formProps;
              if (values && values.ptid) {
                setSelectedPetType(values.ptid);
              }
              return (
                <Form>
                  <FormBox>
                    <Grid container spacing={3} className={classes.grid}>
                      <Grid item xs={12} sm={6}>
                        <FormLabel component="legend">Pet Name</FormLabel>
                        <Field
                          name="petName"
                          type="text"
                          className={classes.fullWidth}
                          component={TextField}
                          validate={validateRequired}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormLabel component="legend">Birth Date</FormLabel>
                        <Field
                          className={classes.fullWidth}
                          name="birthDate"
                          allowWeekend={true}
                          allowPast={true}
                          component={DatePickerField}
                          onChange={(value) => {
                            setFieldValue("birthDate", value, true);
                          }}
                          validate={validateRequired}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormLabel component="legend">Type</FormLabel>
                        <Field
                          name="ptid"
                          value={values.ptid}
                          className={classes.fullWidth}
                          component={Select}
                          validate={validateRequired}
                        >
                          {petTypes.map((type) => (
                            <MenuItem key={type.ptid} value={type.ptid}>
                              {type.pettype}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormLabel component="legend">Breed</FormLabel>
                        <Field
                          name="breedid"
                          value={values.breedid || 0}
                          items={breedsForType}
                          disabled={isEmpty(breedsForType)}
                          labelName="breedname"
                          component={ComboBoxField}
                        />
                      </Grid>
                      <GenderSelect {...formProps} />
                      <NeuterSelect {...formProps} />
                      <Grid item xs={12}>
                        <FormLabel component="legend">
                          Authorized to Pickup
                        </FormLabel>
                        <Field
                          name="authorizedToPickup"
                          type="text"
                          placeholder="Who is authorized to pick up this pet"
                          className={classes.fullWidth}
                          component={TextField}
                          validate={validateRequired}
                        />
                      </Grid>
                    </Grid>
                  </FormBox>
                  <FormBox>
                    <Grid container spacing={3} className={classes.grid}>
                      <Grid item xs={12} sm={6}>
                        <FormLabel component="legend">Veternarian</FormLabel>
                        <Field
                          name="vetid"
                          items={vets}
                          labelName="companyname"
                          component={ComboBoxField}
                        />
                      </Grid>
                      {!isEmpty(shotsForType) && (
                        <>
                          <Grid item xs={12}>
                            <Typography variant="h6">
                              Vaccination Expiry Dates
                            </Typography>
                          </Grid>
                          {shotsForType.map((shot, index) => (
                            <Grid key={shot.shotid} item xs={12}>
                              <FormLabel component="legend">
                                {shot.shotname}
                              </FormLabel>
                              <Field
                                className={classes.fullWidth}
                                name={`shots.${shot.shotid}`}
                                allowWeekend={true}
                                allowPast={true}
                                component={DatePickerField}
                                onChange={(value) => {
                                  setFieldValue(
                                    `shots.${shot.shotid}`,
                                    value,
                                    true
                                  );
                                }}
                                validate={validateRequired}
                              />
                            </Grid>
                          ))}
                        </>
                      )}

                      <Grid item xs={12}>
                        {isSubmitting && <LinearProgress />}
                      </Grid>
                    </Grid>
                  </FormBox>
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    onSubmit={submitForm}
                    cancelPath="/profile"
                  />
                  {!isValid && (
                    <span>
                      Please ensure all required fields are filled before
                      submitting.
                    </span>
                  )}
                </Form>
              );
            }}
          </Formik>
        </Paper>
      </Container>
    </>
  );
};

export default PetPage;
