import BFFBreadcrumb from "../components/app/BFFBreadcrumb";
import DOMPurify from "dompurify";
import moment, { Moment } from "moment";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import SubmitButton from "../components/form/SubmitButton";
import Typography from "@material-ui/core/Typography";
import { AddOnSelect } from "../components/form/AddOnSelect";
import { BoardingAddOn, BoardingCreation } from "../model/Boarding";
import { Container, Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { DateRange } from "../components/form/DateRange";
import { DateRangeType, ServiceType } from "../model/ScheduledService";
import { EntityMap } from "../store/reducers/entities.reducer";
import {
  Field,
  Form,
  Formik,
  FormikErrors
  } from "formik";
import { FormBox } from "../components/form/FormBox";
import { Pet } from "../model/Pet";
import { PetSelect } from "../components/form/PetSelect";
import { RadioGroup, TextField } from "formik-material-ui";
import { RootState } from "../store/reducers";
import { ServiceNotes } from "../components/form/ServiceNotes";
import { useDispatch, useSelector } from "react-redux";
import { validatePets } from "../utils/ValidationUtil";
import { validatePhone } from "../components/form/validations";
import { values } from "lodash";
import {
  loadBoardingServices,
  loadBoardingInstructions,
  scheduleBoarding,
  loadBoardingAddOns,
} from "../store/actions/boarding.actions";
import {
  FormControlLabel,
  CircularProgress,
  LinearProgress,
  Radio,
  FormLabel,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
      margin: theme.spacing(2, 0),
      backgroundColor: theme.palette.grey[100],
    },
    grid: {
      padding: theme.spacing(3, 0),
    },
    select: {
      minWidth: 120,
    },
    fullWidth: {
      width: "100%",
    },
    link: {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  })
);

type BoardingCreationForm = Partial<BoardingCreation> & DateRangeType;

const ScheduleBoardingPage: React.FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [pets, setPets] = useState([] as Pet[]);

  const initialDate = useSelector<RootState, Moment>(({ app }) => {
    // Default to tomorrow unless date was previously selected
    let selectedDate = moment().add(1, "day");
    if (app.selectedDate) {
      const appDate = moment(app.selectedDate);
      if (appDate.isAfter(moment(), "day")) {
        selectedDate = appDate;
      }
    }
    return selectedDate;
  });

  const boardingServices = useSelector<RootState, ServiceType[]>(
    ({ entities }) => {
      if (entities && entities.boardingServices) {
        return values(entities.boardingServices);
      }
      return [];
    }
  );

  const boardingAddOns = useSelector<RootState, BoardingAddOn[]>(
    ({ entities }) => {
      if (entities && entities.boardingAddOns) {
        return values(entities.boardingAddOns);
      }
      return [];
    }
  );

  const boardingInstructions = useSelector<RootState, string>(
    ({ entities }) => {
      if (entities.messsges) {
        return DOMPurify.sanitize(entities.messsges.boardinginstructions);
      }
      return "";
    }
  );

  const petMap = useSelector<RootState, EntityMap<Pet> | null>(
    ({ entities }) => {
      if (entities && entities.pets) {
        return entities.pets;
      }
      return null;
    }
  );

  const [emergencyContact, emergencyNumber] = useSelector<RootState, string[]>(
    ({ auth, entities }) => {
      if (auth && entities && entities.profile) {
        const profile = entities.profile[auth.userid];
        return [profile.emergencycontact || "", profile.emergencyphone || ""];
      }
      return [];
    }
  );

  useEffect(() => {
    dispatch(loadBoardingServices());
    dispatch(loadBoardingInstructions());
    dispatch(loadBoardingAddOns());
  }, [dispatch]);

  useEffect(() => {
    if (petMap) {
      setPets(values(petMap));
    }
  }, [petMap]);

  if (
    boardingServices.length === 0 ||
    pets.length === 0 ||
    boardingAddOns.length === 0
  ) {
    return (
      <div className="bff-loading">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <BFFBreadcrumb pageName="Request Boarding" />
      <Container maxWidth="lg">
        <Paper className={classes.root}>
          <Typography variant="h4" gutterBottom>
            Request Boarding
          </Typography>
          <FormBox>
            {boardingInstructions && (
              <Typography
                component="span"
                color="inherit"
                dangerouslySetInnerHTML={{ __html: boardingInstructions }}
              />
            )}
          </FormBox>
          <Formik<BoardingCreationForm>
            initialValues={{
              type: "multiple",
              startDate: initialDate,
              endDate: initialDate,
              serviceid: boardingServices[0]
                ? boardingServices[0].serviceid.toString()
                : "0",
              petids: pets[0] ? [pets[0].petid] : [],
              emergencyContact,
              emergencyNumber,
            }}
            validate={(values: BoardingCreationForm) => {
              const errors: FormikErrors<BoardingCreationForm> = {};
              validatePets(values, errors);
              return errors;
            }}
            onSubmit={(values: BoardingCreationForm, { setSubmitting }) => {
              ReactGA.event({
                category: "Boarding",
                action: "Submit Request",
              });
              //dispatch boarding creation action
              dispatch(scheduleBoarding(values));
              setTimeout(() => {
                setSubmitting(false);
              }, 500);
            }}
          >
            {(formProps) => {
              const { submitForm, isSubmitting, values } = formProps;
              return (
                <Form>
                  <FormBox>
                    <Grid container spacing={3} className={classes.grid}>
                      <PetSelect {...formProps} pets={pets} />
                      <DateRange {...formProps} allowWeekend={true} />
                      {boardingServices && (
                        <Grid item xs={12}>
                          <FormLabel component="legend">Location</FormLabel>
                          <Field
                            value={values.serviceid}
                            name="serviceid"
                            component={RadioGroup}
                          >
                            {boardingServices.map((type) => (
                              <FormControlLabel
                                key={type.serviceid}
                                value={type.serviceid.toString()}
                                control={<Radio />}
                                label={type.servicename}
                              />
                            ))}
                          </Field>
                        </Grid>
                      )}
                    </Grid>
                  </FormBox>
                  <FormBox>
                    <Grid container spacing={3} className={classes.grid}>
                      <AddOnSelect {...formProps} addons={boardingAddOns} />
                      <ServiceNotes
                        label="Add-on Notes"
                        name="addonNotes"
                        placeholder={" "}
                      />
                    </Grid>
                  </FormBox>
                  <FormBox>
                    <Grid container spacing={3} className={classes.grid}>
                      <Grid item xs={12} sm={6}>
                        <Field
                          name="emergencyContact"
                          type="text"
                          label="Emergency Contact"
                          className={classes.fullWidth}
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          name="emergencyNumber"
                          type="text"
                          label="Emergency Number"
                          className={classes.fullWidth}
                          component={TextField}
                          validate={validatePhone}
                        />
                      </Grid>
                      <ServiceNotes />
                      <Grid item xs={12}>
                        {isSubmitting && <LinearProgress />}
                      </Grid>
                    </Grid>
                  </FormBox>
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    onSubmit={submitForm}
                  />
                </Form>
              );
            }}
          </Formik>
        </Paper>
      </Container>
    </>
  );
};

export default ScheduleBoardingPage;
