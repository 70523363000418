export const CONTENT_TYPE_APPLICATION_JSON = "application/json; charset=utf-8";

export const PetExec = {
  API_URL: "https://secureaws.petexec.net/api",
  APP_NAME: "bff-portal",
  API_DATE_FORMAT: "YYYY-MM-DD",
  APP_SCOPES: [
    "owner_read",
    "owner_update",
    "calendar_read",
    "package_read",
    "pet_read",
    "company_read",
    "company_create",
    "daycare_read",
    "daycare_create",
    "daycare_delete",
    "scheduled_service_read",
    "scheduled_service_create",
    "pet_create",
    "boarding_read",
    "boarding_create",
    "breed_read",
    "vet_read",
    "shot_read",
  ],
};

export const ENVIRONMENTS = {
  development: {
    CLIENT_ID: "af2f0dcb56ac4aed639b9e642ac47b4b",
  },
  production: {
    CLIENT_ID: "cb98ffaf4a22f665db09f6ccea1e1c30",
  },
};

export const DATE_FRIENDLY_FORMAT = "dddd, MMMM Do";
export const TIME_FORMAT = "hh:mm:ss";
export const DATE_LONG_FORMAT = "MMMM DD, YYYY";
export const DATE_SHORT_FORMAT = "MM/DD/YYYY";

export const GA_TRACKING_ID = "UA-83221281-2";

export const BFF_LOCATION = 3914;
export const SERVICETYPEID_SHUTTLE = 5280;
export const PUPPY_POWER_HOUR_ID = 371652;
export const PRIVATE_SHOPPING_ID = 378140;

export const BFF_HREF = {
  shop: "https://www.bffplaycare.com/shop",
};

// Beta Users
const USER_ANTHONY = 2268767;
const USER_NICOLE = 2375896;
export const BETA_USER_IDS = [USER_ANTHONY, USER_NICOLE];
