import { BFFEmployee, Shuttle } from "../model/Company";

export interface BFFDataResponse {
  employees: BFFEmployee[];
  shuttle: Shuttle;
}

export interface BFFDataFactory {
  fetchBFFData(): Promise<BFFDataResponse>;
}

export const bffDataFactory = (endpoint: string): BFFDataFactory => {
  const basepath = "/bff";
  if (!endpoint) {
    throw new Error("A bff ddata endpoint must be specified.");
  }
  return {
    async fetchBFFData(): Promise<BFFDataResponse> {
      const contentPath = `${basepath}.json`;
      const response = await fetch(`${endpoint}${contentPath}`);
      if (response.ok) {
        return (await response.json()) as BFFDataResponse;
      } else {
        throw new Error(`No BFF content found`);
      }
    },
  };
};
