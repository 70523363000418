import PetService from "../../services/PetService";
import { ActionType } from "redux-promise-middleware";
import { BFFThunkAction, BFFThunkDispatch } from "./action.types";
import { createAction } from "redux-actions";
import { isEmpty } from "lodash";
import { PetActions } from "./action.constants";
import { showNotification } from "./app.actions";
import {
  Pet,
  PetCreation,
  PetPayload,
  Vet,
  Shot,
  PetBreed,
} from "../../model/Pet";

const NOTIFICATION_KEY = "petPicUpload";
const ADD_PET_KEY = "petCreation";

function loadPetsAction(userid: number): Promise<PetPayload> {
  const service = new PetService();
  return service.loadPets(userid);
}

export const loadPets = createAction<Promise<PetPayload>, number>(
  PetActions.LOAD_PETS,
  loadPetsAction
);

function loadVetsAction(): Promise<Vet[]> {
  const service = new PetService();
  return service.loadVets();
}

export const loadVets = createAction<Promise<Vet[]>>(
  PetActions.LOAD_VETS,
  loadVetsAction
);

function uploadPetPicThunk(pet: Pet, data: string): BFFThunkAction<void> {
  return async (dispatch: BFFThunkDispatch, getStore) => {
    dispatch({
      type: `${PetActions.UPLOAD_PIC}_${ActionType.Pending}`,
      payload: pet,
    });

    const service = new PetService();
    const response = await service.uploadPic(pet, data);

    if (response.success && response.message) {
      const userid = getStore().auth.userid;
      await dispatch(loadPets(userid));

      dispatch(
        showNotification({
          key: NOTIFICATION_KEY,
          message: response.message,
        })
      );
    }

    if (!response.success && response.errors && response.errors.length > 0) {
      dispatch(
        showNotification({
          key: NOTIFICATION_KEY,
          message: response.errors[0],
          options: { variant: "error" },
        })
      );
    }

    // get updated pet
    let updatedPet = pet;
    const entities = getStore().entities;
    if (entities.pets && entities.pets[pet.petid]) {
      updatedPet = entities.pets[pet.petid];
    }
    dispatch({
      type: `${PetActions.UPLOAD_PIC}_${ActionType.Fulfilled}`,
      payload: updatedPet,
    });
  };
}

export const uploadPetPic = uploadPetPicThunk;

function loadPetBreedsThunk(): BFFThunkAction<void> {
  return async (dispatch: BFFThunkDispatch, getStore) => {
    const service = new PetService();
    const petTypes = await service.loadPetTypes();

    dispatch({
      type: `${PetActions.ADD_TYPES}`,
      payload: petTypes,
    });

    type PetTypePromise = Promise<Array<PetBreed | Shot>>;

    const allPromises = petTypes.reduce<PetTypePromise[]>((chain, type) => {
      chain.push(service.loadPetBreeds(type.ptid));
      chain.push(service.loadShots(type.ptid));
      return chain;
    }, []);

    const responses = await Promise.all(allPromises);

    responses.forEach((payload) => {
      if (!isEmpty(payload)) {
        const breeds: PetBreed[] = payload
          .filter((value) => "breedid" in value)
          .map((value) => value as PetBreed);

        const shots: Shot[] = payload
          .filter((value) => "shotid" in value)
          .map((value) => value as Shot);

        if (!isEmpty(breeds)) {
          dispatch({
            type: PetActions.ADD_BREEDS,
            payload,
          });
        }
        if (!isEmpty(shots)) {
          dispatch({
            type: PetActions.ADD_SHOTS,
            payload,
          });
        }
      }
    });
  };
}

export const loadPetBreeds = loadPetBreedsThunk;

function addPetThunk(pet: Partial<PetCreation>): BFFThunkAction<void> {
  return async (dispatch: BFFThunkDispatch, getState) => {
    const completedPet: PetCreation = {
      petName: pet.petName || "",
      birthDate: pet.birthDate || "",
      ptid: pet.ptid || 0,
      breedid: pet.breedid || 0,
      gender: pet.gender || "Male",
      neuteredState: pet.neuteredState || "Unknown",
      authorizedToPickup: pet.authorizedToPickup || "",
      vetid: pet.vetid || 0,
      shots: pet.shots || {},
    };

    //grab logged in user id
    const userid = getState().auth.userid;

    const service = new PetService();
    const response = await service.addPet(userid, completedPet);

    if (response.success && response.message) {
      dispatch(
        showNotification({
          key: ADD_PET_KEY,
          message: response.message,
        })
      );
      dispatch(loadPets(userid));
    }

    if (!response.success && response.errors && response.errors.length > 0) {
      dispatch(
        showNotification({
          key: ADD_PET_KEY,
          message: response.errors[0],
          options: { variant: "error" },
        })
      );
    }
  };
}

export const addPet = addPetThunk;
