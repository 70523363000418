import reduceReducers from "reduce-reducers";
import { Action } from "redux-actions";
import { ActionType } from "redux-promise-middleware";
import { Boarding, BoardingPayload } from "../../model/Boarding";
import { BoardingActions } from "../actions/action.constants";
import { EntityMap } from "./entities.reducer";
import { mapKeys } from "lodash";
import { Reducer } from "react";

export type BoardingState = EntityMap<Boarding> | undefined;

const initialState: BoardingState = {};

const handleLoadAction: Reducer<BoardingState, Action<BoardingPayload>> = (
  state = initialState,
  action
): BoardingState => {
  switch (action.type) {
    case `${BoardingActions.LOAD_CALENDAR}_${ActionType.Fulfilled}`: {
      return {
        ...state,
        ...mapKeys(action.payload.boardings, "boardingid")
      };
    }
    default: {
      return state;
    }
  }
};

const handleLoadSingleAction: Reducer<BoardingState, Action<Boarding>> = (
  state = initialState,
  action
): BoardingState => {
  switch (action.type) {
    case `${BoardingActions.LOAD}_${ActionType.Fulfilled}`: {
      const boarding = action.payload;
      if (boarding) {
        return {
          ...state,
          ...{ [boarding.boardingid]: boarding }
        };
      } else {
        return state;
      }
    }
    default: {
      return state;
    }
  }
};

export const boardingReducer = reduceReducers(
  // @ts-ignore
  handleLoadAction,
  handleLoadSingleAction
);
