import { AuthActions } from "./action.constants";
import { BFFThunkAction, BFFThunkDispatch } from "./action.types";
import { createAction } from "redux-actions";
import {
  AuthenticationService,
  petExecProvider,
} from "../../services/AuthenticationService";

function loginThunk(): BFFThunkAction<void> {
  return (dispatch: BFFThunkDispatch) => {
    // window.location.href = "https://www.bffplaycare.com/temporary-delay";
    window.location.href = AuthenticationService.getAuthorizeUrl(
      petExecProvider
    );
  };
}

function logoutThunk(): BFFThunkAction<void> {
  return (dispatch: BFFThunkDispatch) => {
    AuthenticationService.invalidateSession();
    dispatch(_logoutAction());
    window.location.href = "/";
  };
}

export const _logoutAction = createAction(AuthActions.LOGOUT);

// Public Actions
export const login = loginThunk;
export const logout = logoutThunk;
