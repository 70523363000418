import React from "react";
import SendIcon from "@material-ui/icons/Send";
import { Button } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { RouteComponentProps, withRouter } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      padding: theme.spacing(3, 0),
    },
    submit: {
      paddingTop: 8,
    },
  })
);

interface ComponentProps {
  label?: string;
  isSubmitting: boolean;
  onSubmit: () => void;
  cancelPath?: string;
}

type Props = ComponentProps & RouteComponentProps;

const SubmitButton: React.FunctionComponent<Props> = ({
  label = "Send Request",
  cancelPath = "/",
  isSubmitting,
  onSubmit,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      className={classes.grid}
      spacing={3}
    >
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isSubmitting}
          onClick={onSubmit}
          endIcon={<SendIcon />}
        >
          {label}
        </Button>
      </Grid>
      <Grid item>
        <Button color="secondary" component={RouterLink} to={cancelPath}>
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};

export default withRouter(SubmitButton);
