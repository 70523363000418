import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { sendEmail } from "../../store/actions/company.actions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
      top: "auto",
      right: "20px",
      bottom: "80px",
      left: "auto",
      position: "fixed",
      zIndex: 10000
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "100%"
    }
  })
);

interface Props {
  open: boolean;
  onClose: () => void;
}

const BetaFeedbackDialog: React.FunctionComponent<Props> = ({
  open = false,
  onClose
}) => {
  const classes = useStyles({});
  const dispatch = useDispatch();

  const [feedback, setFeedback] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedback(event.target.value);
  };

  useEffect(() => {
    if (open) {
      ReactGA.event({
        category: "Feedback",
        action: "Open Feedback"
      });
    }
  }, [open]);

  const handleSubmit = () => {
    ReactGA.event({
      category: "Feedback",
      action: "Send Feedback"
    });
    dispatch(sendEmail("Beta Feedback", feedback));
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="feedback-dialog-title"
    >
      <DialogTitle id="feedback-dialog-title">Feedback</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Thank you for visiting your new BFF dashboard. Please add any feedback
          you would like to provide.
        </DialogContentText>
        <TextField
          id="beta-feedback"
          label="Feedback Comments"
          multiline={true}
          rows="5"
          className={classes.textField}
          margin="normal"
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BetaFeedbackDialog;
