import {
  AuthenticationService,
  petExecProvider,
} from "./AuthenticationService";

/**
 * Used to describe the HTTP request that requires a body as part of the request.
 */
export interface BodyRequest {
  method: "PATCH" | "POST" | "PUT";
  body?: string | FormData;
}

export interface PetExecResponse {
  success: boolean;
}

export interface CreationResponse extends PetExecResponse {
  message?: string;
  status?: string;
  errors?: string[];
}

abstract class AbstractPetService {
  protected readonly requestHeader: Headers;

  public static encodeFormData(formData: FormData): string[] {
    var result: string[] = [];
    const encode = (s: string) => {
      return encodeURIComponent(s).replace(/%20/g, "+");
    };
    formData.forEach((value: FormDataEntryValue, key) => {
      if (typeof value === "string") {
        result.push(encode(key) + "=" + encode(value));
      }
    });
    return result;
  }

  constructor() {
    const headers = new Headers();

    headers.append(
      "Authorization",
      `Bearer ${AuthenticationService.getAccessToken(petExecProvider)}`
    );

    this.requestHeader = headers;
  }

  /**
   * Helper method to perform the fetchJson and get the response.
   * @param url Perform the fetchJson
   * @param config - the RequestInit
   * @throws error - may throw an error if the fetch call fails.
   */
  protected async fetch(url: string, config?: RequestInit): Promise<Response> {
    try {
      return await fetch(url, {
        mode: "cors",
        headers: this.requestHeader,
        ...config,
      });
    } catch (error) {
      throw new Error("network error");
    }
  }

  /**
   * Fetch the JSON response from the specified URL.
   *
   * @param url URL to fetch JSON from
   * @param init Additional parameters that should be included with the request
   *
   */
  protected async fetchJson<T>(url: string, init?: RequestInit): Promise<T> {
    const response: Response = await this.fetch(url, {
      ...init,
    });

    if (!response.ok) {
      throw new Error("service error");
    }

    try {
      const rawObj = await response.json();
      return rawObj as T;
    } catch (error) {
      throw new Error("json parse error");
    }
  }
}

export default AbstractPetService;
