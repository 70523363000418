import CalendarService from "../../services/CalendarService";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { DATE_FRIENDLY_FORMAT } from "../../constants";
import { isScheduledPowerHour } from "../../utils/CalendarUtils";
import { ServiceItem } from "../../model/Calendar";
import {
  getLocationText,
  getPackageText,
  isServiceScheduled,
  isShuttleRequired,
} from "../../utils/ServiceItemUtils";

export const renderListItem = (primary: string, secondary: React.ReactChild) =>
  secondary && (
    <Typography variant="body2">{`${primary}: ${secondary}`}</Typography>
  );

export const renderDaycareItems = (item: ServiceItem) => {
  if (CalendarService.isDaycareItem(item.schedule)) {
    return (
      <>
        {renderListItem("Location", getLocationText(item))}
        {renderListItem("Shuttle", isShuttleRequired(item) ? "Yes" : "No")}
      </>
    );
  }
  return null;
};

export const renderWalkItems = (item: ServiceItem) => {
  if (CalendarService.isServicesItem(item.schedule)) {
    return <>{renderListItem("Package", getPackageText(item))}</>;
  }
  return null;
};

export const renderPowerHourItems = (item: ServiceItem) => {
  if (
    CalendarService.isServicesItem(item.schedule) &&
    isScheduledPowerHour(item.schedule)
  ) {
    const timeText = isServiceScheduled(item) ? "7:30 pm - 8:30 pm" : "";
    return (
      <>
        {renderListItem("Location", getLocationText(item))}
        {renderListItem("Time", timeText)}
      </>
    );
  }
  return null;
};

export const renderBoardingItems = (item: ServiceItem) => {
  if (CalendarService.isBoardingItem(item.schedule)) {
    const serviceEndDate = CalendarService.getCalendarItemDate(
      item.schedule,
      true
    );

    return (
      <>
        {renderListItem(
          "End Date",
          serviceEndDate.format(DATE_FRIENDLY_FORMAT)
        )}
        {renderListItem("Location", getLocationText(item))}
        {renderListItem("Status", item.schedule.status)}
      </>
    );
  }
  return null;
};
