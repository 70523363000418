import reduceReducers from "reduce-reducers";
import { Action } from "redux-actions";
import { ActionType } from "redux-promise-middleware";
import { EntityMap } from "./entities.reducer";
import { mapKeys } from "lodash";
import { Pet, PetPayload } from "../../model/Pet";
import { PetActions } from "../actions/action.constants";
import { Reducer } from "react";

export type PetState = EntityMap<Pet> | undefined;

const initialState: PetState = {};

const handleLoadAction: Reducer<PetState, Action<PetPayload>> = (
  state = initialState,
  action
): PetState => {
  switch (action.type) {
    case `${PetActions.LOAD_PETS}_${ActionType.Fulfilled}`: {
      return {
        ...state,
        ...mapKeys(action.payload.pets, "petid")
      };
    }
    default: {
      return state;
    }
  }
};

const handlUploadAction: Reducer<PetState, Action<Pet>> = (
  state = initialState,
  action
): PetState => {
  switch (action.type) {
    case `${PetActions.UPLOAD_PIC}_${ActionType.Pending}`: {
      const pet = { ...action.payload };
      pet.isUploading = true;
      pet.pathtopic = "";
      return {
        ...state,
        ...{ [pet.petid]: pet }
      };
    }
    case `${PetActions.UPLOAD_PIC}_${ActionType.Fulfilled}`: {
      const pet = { ...action.payload };
      pet.isUploading = false;
      return {
        ...state,
        ...{ [pet.petid]: pet }
      };
    }
    default: {
      return state;
    }
  }
};

export const petReducer = reduceReducers(
  // @ts-ignore
  handleLoadAction,
  handlUploadAction
);
