import BetaFeedbackDialog from "./home/BetaFeedbackDialog";
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import EventIcon from "@material-ui/icons/Event";
import FeedbackIcon from "@material-ui/icons/Feedback";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import HotelIcon from "@material-ui/icons/Hotel";
import PetsIcon from "@material-ui/icons/Pets";
import React, { useState } from "react";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { RouteComponentProps, withRouter } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
      top: "auto",
      right: "20px",
      bottom: "80px",
      left: "auto",
      position: "fixed",
      zIndex: 10000,
    },
    tooltip: {
      marginTop: "-.01rem",
      fontSize: "1.5rem",
    },
  })
);

const useTooltipStyles = makeStyles((theme: Theme) =>
  createStyles({
    staticTooltipLabel: {
      width: "125px",
      textAlign: "right",
      color: theme.palette.secondary.main,
    },
  })
);

const QuickActions: React.FunctionComponent<RouteComponentProps> = (props) => {
  const classes = useStyles({});
  const tooltipClasses = useTooltipStyles();

  const [open, setOpen] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleBetaClick = () => {
    setFeedbackOpen(true);
  };

  const handleNavClick = (path) => {
    props.history.push(path);
  };

  return (
    <>
      <SpeedDial
        ariaLabel="SpeedDial actions"
        className={classes.fab}
        icon={<SpeedDialIcon openIcon={<PetsIcon />} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="up"
      >
        <SpeedDialAction
          key="ScheduleDaycare"
          icon={<EventIcon />}
          tooltipTitle="Daycare"
          tooltipOpen={true}
          classes={tooltipClasses}
          onClick={() => handleNavClick("/schedule/daycare")}
        />
        <SpeedDialAction
          key="RequestWalk"
          icon={<DirectionsWalkIcon />}
          tooltipTitle="Walk"
          tooltipOpen={true}
          classes={tooltipClasses}
          onClick={() => handleNavClick("/schedule/walk")}
        />
        <SpeedDialAction
          key="RequestBoarding"
          icon={<HotelIcon />}
          tooltipTitle="Boarding"
          tooltipOpen={true}
          classes={tooltipClasses}
          onClick={() => handleNavClick("/schedule/boarding")}
        />
        <SpeedDialAction
          key="RequestPowerHour"
          icon={<FlashOnIcon />}
          tooltipTitle="Puppy Power Hour"
          tooltipOpen={true}
          classes={tooltipClasses}
          onClick={() => handleNavClick("/schedule/powerhour")}
        />
        <SpeedDialAction
          key="BetaFeedback"
          icon={<FeedbackIcon />}
          tooltipTitle="Feedback"
          tooltipOpen={true}
          classes={tooltipClasses}
          onClick={handleBetaClick}
        />
      </SpeedDial>
      <BetaFeedbackDialog
        open={feedbackOpen}
        onClose={() => setFeedbackOpen(false)}
      />
    </>
  );
};

export default withRouter(QuickActions);
