import errorMiddleware from "../utils/ErrorMiddleware";
import loggerMiddleware from "redux-logger";
import moment from "moment";
import promiseMiddleware from "redux-promise-middleware";
import reducers, { RootState } from "./reducers";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { applyMiddleware, createStore, Store } from "redux";
import { EMPTY_PET } from "../model/Pet";
import {
  AuthenticationService,
  petExecProvider,
} from "../services/AuthenticationService";

let isAuthenticated: boolean = false;
const session = AuthenticationService.restoreSession(petExecProvider);
if (session) {
  isAuthenticated = true;
}

const initialState: RootState = {
  app: {
    title: "BFF",
    navDrawerOpen: false,
    defaultPet: EMPTY_PET,
    notifications: {},
    selectedDate: moment().toISOString(),
    servicesLoading: true,
    petsLoading: true,
    calendarLoading: true,
  },
  auth: { authenticated: isAuthenticated, userid: 0, isBetaUser: false },
  entities: {
    calendar: { daycares: [], scheduledServices: [], boardings: [] },
    pets: [],
    profile: {},
    messsges: {
      appmessage: null,
      ownermessage: null,
      daycareinstructions: "",
      boardinginstructions: "",
    },
    packages: { daycare: {}, other: {} },
    companyServices: {},
    companyLocations: {},
    employees: {},
    daycareServices: {},
    scheduledServiceTypes: {},
    serviceTypes: {},
    signedAgreements: {},
    boardingServices: {},
    boardingAddOns: {},
    petTypes: {},
    petBreeds: {},
    vets: {},
    shots: {},
  },
};

let store: Store<RootState>;

export function configureStore() {
  const allMiddleware = [
    thunk as ThunkMiddleware<RootState>,
    errorMiddleware,
    promiseMiddleware,
  ];

  const NODE_ENV = process.env.NODE_ENV;
  if (NODE_ENV === "development") {
    allMiddleware.push(loggerMiddleware);
  }

  const middleware = applyMiddleware(...allMiddleware);

  store = createStore(reducers, initialState, middleware);

  return store;
}

export function getStore(): Store<RootState> {
  return store;
}
