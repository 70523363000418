import React from "react";
import { DateRangeType, ServiceCreation } from "../../model/ScheduledService";
import { Field, FormikProps } from "formik";
import { Grid } from "@material-ui/core";
import { WeekdayField } from "./WeekdayField";

interface ComponentProps {
  showWeekend?: boolean;
}

type Props = ComponentProps &
  FormikProps<DateRangeType & Partial<ServiceCreation>>;

export const DaysOfWeek: React.FunctionComponent<Props> = ({
  values,
  showWeekend = false
}) => {
  return (
    <>
      {values.type === "multiple" && (
        <Grid item xs={4}>
          <Field
            name="daysOfWeek"
            label="Days"
            component={WeekdayField}
            showWeekend={showWeekend}
          />
        </Grid>
      )}
    </>
  );
};
