import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  copyrightContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  copyright: {
    margin: theme.spacing(4),
  },
  logo: {
    margin: theme.spacing(2),
    width: 85,
    height: 85,
  },
}));

export const CopyrightContainer: React.FunctionComponent = ({ children }) => {
  const classes = useStyles();

  return (
    <Container className={classes.copyrightContainer}>
      {children as React.ReactElement<any, any>}
    </Container>
  );
};

export const Copyright: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      className={classes.copyright}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.bffplaycare.com">
        BFF
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

export const Logo: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <img src="/images/bff-logo-t.png" alt="BFF Logo" className={classes.logo} />
  );
};
