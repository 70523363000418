import CalendarService from "../../services/CalendarService";
import DataBox from "../DataBox";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import ServiceItemCard from "./ServiceItemCard";
import Typography from "@material-ui/core/Typography";
import { buildServiceItem } from "../../utils/CalendarUtils";
import { CalendarItem, ServiceItem } from "../../model/Calendar";
import { connect } from "react-redux";
import { getCalendarItems } from "../../selectors";
import { Profile } from "../../model/Profile";
import { RootState } from "../../store/reducers";
import {
  ServiceItemEntities,
  useServiceItemEntities,
} from "../../hooks/useServiceItem";

interface ServiceDetailsProps {
  date: Date;
}

type Props = ServiceDetailsProps & InjectedStateProps;

const ServiceDetails: React.FunctionComponent<Props> = (props) => {
  const [items, setItems] = useState([] as ServiceItem[]);

  const serviceItemEntities = useServiceItemEntities();

  useEffect(() => {
    const filterCalendarItems = (
      items: ReadonlyArray<CalendarItem>,
      date: Date,
      entities: ServiceItemEntities
    ): ServiceItem[] => {
      if (!date || !items) {
        return [];
      }
      return items
        .filter((item) => CalendarService.matchesCalendarItem(date, item))
        .map((item) => {
          return buildServiceItem(
            item,
            entities.pets,
            entities.employees,
            entities.services,
            entities.locations
          );
        });
    };

    if (serviceItemEntities) {
      const filtered = filterCalendarItems(
        props.calendarItems,
        props.date,
        serviceItemEntities
      );
      setItems(filtered);
    }
  }, [props.date, props.calendarItems, serviceItemEntities]);

  const renderServiceItem = (item: ServiceItem, index: number) => (
    <Grid key={`ServiceItem${index}`} item xs={12}>
      <ServiceItemCard service={item} />
    </Grid>
  );

  return (
    <DataBox title="Services">
      {items && items.length === 0 && (
        <Typography>
          You do not have any services scheduled for the selected date.
        </Typography>
      )}
      {items && items.length > 0 && (
        <Grid container spacing={3}>
          {items.map((item, index) => renderServiceItem(item, index))}
        </Grid>
      )}
    </DataBox>
  );
};

export interface InjectedStateProps {
  profile: Profile | undefined;
  calendarItems: ReadonlyArray<CalendarItem>;
}

const mapStateToProps = (state: RootState): InjectedStateProps => {
  const { auth, entities } = state;
  const stateProps: InjectedStateProps = {
    profile: undefined,
    calendarItems: getCalendarItems(state),
  };
  if (entities) {
    if (entities.profile) {
      stateProps.profile = entities.profile[auth.userid];
    }
  }

  return stateProps;
};

export default connect<InjectedStateProps, null, {}, RootState>(
  mapStateToProps
)(ServiceDetails);
