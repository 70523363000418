import Box from "@material-ui/core/Box";
import cn from "classnames";
import ErrorBoundary from "./ErrorBoundary";
import Paper from "@material-ui/core/Paper";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "hidden",
      flexDirection: "column",
      height: "100%"
    },
    primary: {
      backgroundColor: theme.palette.primary.main
    },
    secondary: {
      backgroundColor: theme.palette.secondary.main,
      color: "#fff"
    },
    default: {
      backgroundColor: theme.palette.grey[100]
    },
    title: {
      paddingBottom: theme.spacing(2)
    }
  })
);

interface Props {
  title?: string;
  color?: "primary" | "secondary" | "default";
}

const DataBox: React.FunctionComponent<Props> = ({
  title = undefined,
  color = "default",
  children
}) => {
  const classes = useStyles();

  return (
    <ErrorBoundary
      errorElement={
        <Typography variant="h3">
          Unable to render card. Please try again later.
        </Typography>
      }
    >
      <Paper className={cn(classes.paper, classes[color])}>
        <Box>
          {title && (
            <Typography variant="h5" className={classes.title}>
              {title}
            </Typography>
          )}
          {children}
        </Box>
      </Paper>
    </ErrorBoundary>
  );
};

export default DataBox;
