import BFFNavigation from "./components/app/BFFNavigation";
import cn from "classnames";
import createStyles from "@material-ui/core/styles/createStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import MainAppBar from "./components/app/MainAppBar";
import React from "react";
import withRoot from "./withRoot";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: "24px"
    },
    content: {
      padding: "8px"
    }
  });

type Props = WithStyles<typeof styles>;

class BFFPage extends React.Component<Props> {
  render() {
    return (
      <div className={cn("BFF-Page", this.props.classes.root)}>
        <CssBaseline />
        <MainAppBar />
        <div className={this.props.classes.content}>{this.props.children}</div>
        <BFFNavigation />
      </div>
    );
  }
}

export default withRoot(withStyles(styles)(BFFPage));
