import BFFDialog from "../BFFDialog";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormLabel from "@material-ui/core/FormLabel";
import React, { useRef } from "react";
import ReactGA from "react-ga";
import Typography from "@material-ui/core/Typography";
import { Box, FormControlLabel, Radio } from "@material-ui/core";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikValues
  } from "formik";
import { RadioGroup, Switch, TextField } from "formik-material-ui";
import { sendEmail } from "../../store/actions/company.actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
  createStyles,
  makeStyles,
  Paper,
  Theme,
  FormHelperText,
} from "@material-ui/core";
import {
  validatePhone,
  validateRequired,
  validateEmail,
  validateAll,
} from "../form/validations";
import {
  showNotification,
  APP_NOTIFICATION_KEY,
} from "../../store/actions/app.actions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.grey[200],
    },
    dialogText: {
      color: theme.palette.grey[200],
      fontWeight: theme.typography.fontWeightLight,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(2),
      width: "100%",
      color: theme.palette.grey[200],
    },
    formPaper: {
      backgroundColor: theme.palette.grey[200],
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
    },
    submitButton: {
      margin: theme.spacing(4, 0),
      padding: theme.spacing(2),
    },
  })
);

interface ReferFriendForm {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  interest_walk: boolean;
  interest_boarding: boolean;
  interest_daycare: boolean;
  reward: string;
}

const ReferFriendDialog: React.FunctionComponent = () => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const history = useHistory();

  let formRef = useRef<FormikValues>();

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const dialogContent: React.ReactElement = (
    <>
      <DialogContentText className={classes.dialogText}>
        There is no greater feeling of success than to know someone we adore has
        told a trusted friend that they would love BFF. It's the compliment of
        compliments. We thank you for the many generous referrals that you have
        provided over the years.
      </DialogContentText>
      <DialogContentText className={classes.dialogText}>
        It's time for us to pay it forward. For any friend that you refer who
        purchases a package or books 3+ nights of boarding in Toronto or Prince
        Edward County, we will add a free service to your account. Speak with
        you BFF professional for details.
      </DialogContentText>
      <Typography variant="subtitle1">
        Tell us about your friend. We'd love to say hi!
      </Typography>
      <Paper className={classes.formPaper}>
        <Formik<ReferFriendForm>
          innerRef={(instance) => {
            formRef.current = instance as FormikValues;
          }}
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            interest_walk: false,
            interest_daycare: false,
            interest_boarding: false,
            reward: "",
          }}
          onSubmit={(values: ReferFriendForm) => {
            const submitValues = { ...values };
            const interests: string[] = [];
            submitValues.interest_walk && interests.push("Walks");
            submitValues.interest_daycare && interests.push("Daycare");
            submitValues.interest_boarding && interests.push("Boarding");

            ReactGA.event({
              category: "Refer",
              action: "Refer Request",
            });
            const referBody = `
          First Name: ${submitValues.firstName}
          Last Name: ${submitValues.lastName}
          Email: ${submitValues.email}
          Phone: ${submitValues.phone}
          ---------------------------------------------------------------

          Interests: ${interests.join(", ")}
          Reward: ${submitValues.reward}
          `;
            dispatch(sendEmail("Refer a Friend", referBody));
            history.push("/");
            dispatch(
              showNotification({
                key: APP_NOTIFICATION_KEY,
                message: "Refer a friend request submitted",
                options: { variant: "info" },
              })
            );
          }}
        >
          {(formProps) => {
            return (
              <>
                <Form>
                  <Field
                    name="firstName"
                    type="text"
                    label="First Name"
                    className={classes.textField}
                    component={TextField}
                    validate={validateRequired}
                  />
                  <Field
                    name="lastName"
                    type="text"
                    label="Last Name"
                    className={classes.textField}
                    component={TextField}
                    validate={validateRequired}
                  />
                  <Field
                    name="email"
                    type="text"
                    label="Email"
                    className={classes.textField}
                    component={TextField}
                    validate={(value) =>
                      validateAll(value, [validateRequired, validateEmail])
                    }
                  />
                  <Field
                    name="phone"
                    type="text"
                    label="Phone"
                    className={classes.textField}
                    component={TextField}
                    validate={(value) =>
                      validateAll(value, [validateRequired, validatePhone])
                    }
                  />
                  <Box margin={1}>
                    <FormLabel component="legend">
                      My friend is interested in:
                    </FormLabel>
                    <FormControlLabel
                      control={
                        <Field
                          component={Switch}
                          type="checkbox"
                          name="interest_walk"
                        />
                      }
                      label="Walks"
                    />
                    <FormControlLabel
                      control={
                        <Field
                          component={Switch}
                          type="checkbox"
                          name="interest_daycare"
                        />
                      }
                      label="Daycare"
                    />
                    <FormControlLabel
                      control={
                        <Field
                          component={Switch}
                          type="checkbox"
                          name="interest_boarding"
                        />
                      }
                      label="Boarding"
                    />
                  </Box>
                  <Box margin={1}>
                    <FormLabel component="legend">
                      Choose your free reward:
                    </FormLabel>
                    <Field
                      name="reward"
                      component={RadioGroup}
                      validate={validateRequired}
                    >
                      <FormControlLabel
                        value="Daycare"
                        control={<Radio />}
                        label="1 Free Daycare Day"
                      />
                      <FormControlLabel
                        value="Walk"
                        control={<Radio />}
                        label="1 Free Group Walk"
                      />
                      <FormControlLabel
                        value="Boarding"
                        control={<Radio />}
                        label="1 Free Night of Boarding in PEC"
                      />
                    </Field>
                    <FormHelperText error={true}>
                      <ErrorMessage name="reward" />
                    </FormHelperText>
                  </Box>
                </Form>
              </>
            );
          }}
        </Formik>
      </Paper>
      <Button
        className={classes.submitButton}
        variant="contained"
        color="primary"
        size="medium"
        fullWidth={true}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </>
  );

  return (
    <BFFDialog
      title="Refer A Friend"
      content={dialogContent}
      actionColor="default"
      PaperProps={{
        className: classes.paper,
      }}
    />
  );
};

export default ReferFriendDialog;
