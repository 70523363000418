import AbstractPetService, { CreationResponse } from "./AbstractPetService";
import { PetExec } from "../constants";
import { Profile } from "../model/Profile";
import { TokenReplacer } from "../utils/TokenReplacer";
import { unformatPhone } from "../utils/NumberUtils";

class ProfileService extends AbstractPetService {
  public async loadProfile(): Promise<Profile> {
    const url = `${PetExec.API_URL}/profile`;
    const profileObj = await this.fetchJson<Profile>(url);
    return profileObj;
  }
  public async loadOwner(userid: number): Promise<Profile> {
    const endpoint = `${PetExec.API_URL}/owner/{userid}`;
    const url = TokenReplacer.replace(endpoint, {
      userid
    });
    const profileObj = await this.fetchJson<Profile>(url);
    return profileObj;
  }

  public async modifyOwner(profile: Profile): Promise<CreationResponse> {
    const endpoint = `${PetExec.API_URL}/owner/{userid}`;
    const url = TokenReplacer.replace(endpoint, {
      userid: profile.userid
    });

    const profileData = new FormData();

    profileData.append("username", profile.username);
    profileData.append("firstName", profile.firstname);
    profileData.append("lastName", profile.lastname);
    profileData.append("lastName", profile.lastname);
    profileData.append("email", profile.email);
    profileData.append("addr1", profile.addr1);
    profileData.append("addr2", profile.addr2);
    profileData.append("city", profile.city);
    profileData.append("state", profile.state);
    profileData.append("zip", profile.zip);
    profileData.append("country", profile.country);

    profileData.append("homePhone", unformatPhone(profile.homephone));
    profileData.append("workPhone", unformatPhone(profile.workphone));
    profileData.append("cellPhone", unformatPhone(profile.cellphone));

    profileData.append("emergencyContact", profile.emergencycontact || "");
    profileData.append("emergencyEmail", profile.emergencyemail || "");
    profileData.append("emergencyPhone", unformatPhone(profile.emergencyphone));

    this.requestHeader.set(
      "Content-type",
      "application/x-www-form-urlencoded; charset=UTF-8"
    );

    const encodedFormData = AbstractPetService.encodeFormData(profileData);

    return await this.fetchJson<CreationResponse>(url, {
      method: "PUT",
      body: encodedFormData.join("&")
    });
  }
}

export default ProfileService;
