import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import React from "react";
import Switch from "@material-ui/core/Switch";
import { BoardingAddOn, BoardingCreation } from "../../model/Boarding";
import { DateRangeType } from "../../model/ScheduledService";
import { Field, FormikProps } from "formik";
import { uniq } from "lodash";

interface ComponentProps {
  addons: BoardingAddOn[];
}

type Props = ComponentProps &
  FormikProps<DateRangeType & Partial<BoardingCreation>>;

export const AddOnSelect: React.FunctionComponent<Props> = ({
  addons,
  values,
  errors,
  setFieldValue,
}) => {
  const handleChange = (event: any) => {
    const value: number = parseInt(event.target.value);
    const selected: boolean = event.target.checked;
    let currentSelection = values.addonids || [];
    if (selected) {
      // add value to addonids
      currentSelection.push(value);
      currentSelection = uniq(currentSelection);
    } else {
      // remove value from addonids
      currentSelection = currentSelection.filter((id) => id !== value);
    }
    setFieldValue("addonids", currentSelection, true);
  };

  const isSelected = (value: number) => {
    return values.addonids ? values.addonids.includes(value) : false;
  };

  const currentError = errors["addonids"];

  return (
    <Grid item xs={12}>
      <FormLabel component="legend">Add-ons</FormLabel>
      <Field value={values.addonids}>
        {() => (
          <FormControl component="fieldset">
            <FormGroup>
              {addons.map((addon) => {
                return (
                  <React.Fragment key={`addonItem${addon.serviceid}`}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isSelected(addon.serviceid)}
                          value={addon.serviceid}
                          onChange={handleChange}
                        />
                      }
                      label={addon.servicename}
                    />
                    <FormHelperText>{addon.description}</FormHelperText>
                  </React.Fragment>
                );
              })}
            </FormGroup>
            <FormHelperText error={Boolean(currentError)}>
              {currentError}
            </FormHelperText>
          </FormControl>
        )}
      </Field>
    </Grid>
  );
};
