import Button from "@material-ui/core/Button";
import DataBox from "../DataBox";
import Grid from "@material-ui/core/Grid";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

export const ReferFriendBox: React.FunctionComponent = () => {
  return (
    <DataBox color="primary">
      <Grid container justify="space-between" alignItems="center" spacing={1}>
        <Grid item xs={12} sm={8}>
          <Typography variant="subtitle2">
            For every friend you refer, you and your friend will each get to
            choose a free service.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            component={Link}
            variant="contained"
            to="/refer"
            color="secondary"
            fullWidth={true}
          >
            Refer a Friend
          </Button>
        </Grid>
      </Grid>
    </DataBox>
  );
};
