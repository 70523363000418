import BoardingService from "../../services/BoardingService";
import { BFFThunkAction, BFFThunkDispatch } from "./action.types";
import { BoardingActions } from "./action.constants";
import { createAction } from "redux-actions";
import { ServiceType } from "../../model/ScheduledService";
import { showNotification } from "./app.actions";
import {
  Boarding,
  BoardingCreation,
  BoardingAddOn,
} from "../../model/Boarding";

const NOTIFICATION_KEY = "boardingCreation";

function loadBoardingAction(boardingid: number): Promise<Boarding> {
  const service = new BoardingService();
  return service.loadBoarding(boardingid);
}

export const loadBoarding = createAction<Promise<Boarding>, number>(
  BoardingActions.LOAD,
  loadBoardingAction
);

function loadBoardingServicesAction(): Promise<ServiceType[]> {
  const service = new BoardingService();
  return service.loadBoardingServices();
}

export const loadBoardingServices = createAction<Promise<ServiceType[]>>(
  BoardingActions.LOAD_SERVICES,
  loadBoardingServicesAction
);

function loadBoardingInstructionsAction(): Promise<string> {
  const service = new BoardingService();
  return service.loadInstructions();
}

export const loadBoardingInstructions = createAction<Promise<string>>(
  BoardingActions.LOAD_INSTRUCTIONS,
  loadBoardingInstructionsAction
);

function loadBoardingAddOnsAction(): Promise<BoardingAddOn[]> {
  const service = new BoardingService();
  return service.loadBoardingAddons();
}

export const loadBoardingAddOns = createAction<Promise<BoardingAddOn[]>>(
  BoardingActions.LOAD_ADDONS,
  loadBoardingAddOnsAction
);

function scheduleBoardingThunk(
  boarding: Partial<BoardingCreation>
): BFFThunkAction<void> {
  return async (dispatch: BFFThunkDispatch) => {
    const completedBoarding: BoardingCreation = {
      petids: boarding.petids || [],
      startDate: boarding.startDate || "",
      endDate: boarding.endDate || "",
      signInTime: "07:00 AM",
      signOutTime: "07:00 PM",
      serviceid: boarding.serviceid || "0",
      notes: boarding.notes || "",
      emergencyContact: boarding.emergencyContact || "",
      emergencyNumber: boarding.emergencyNumber || "",
      addonids: boarding.addonids || [],
      addonNotes: boarding.addonNotes || "",
    };

    const service = new BoardingService();
    const response = await service.addBoarding(completedBoarding);

    if (response.success && response.status) {
      dispatch(
        showNotification({
          key: NOTIFICATION_KEY,
          message: response.status,
        })
      );
    }

    if (!response.success && response.errors && response.errors.length > 0) {
      dispatch(
        showNotification({
          key: NOTIFICATION_KEY,
          message: response.errors[0],
          options: { variant: "error" },
        })
      );
    }
  };
}

export const scheduleBoarding = scheduleBoardingThunk;
