import { ServiceItem } from "../model/Calendar";

export const getEmployeeName = (item: ServiceItem) => {
  return item.employee
    ? `${item.employee.firstname} ${item.employee.lastname}`
    : "";
};

export const getEmployeeImage = (item: ServiceItem, thumb: boolean = false) => {
  if (item.employee) {
    if (thumb) {
      if (item.employee.thumbpic) {
        return item.employee.thumbpic;
      }
    } else {
      if (item.employee.profilepic) {
        return item.employee.profilepic;
      }
    }

    const firstInitial = item.employee.firstname.charAt(0).toLowerCase();
    const lastName = `${item.employee.lastname
      .charAt(0)
      .toUpperCase()}${item.employee.lastname.slice(1).toLowerCase()}`;
    let imageName = `${firstInitial}${lastName}`;
    if (thumb) {
      imageName += "Thumb";
    }
    return `/employees/${imageName}.jpg`;
  }
  return "/images/bff-logo-w.png";
};
